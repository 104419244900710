import { action } from 'satcheljs';
import type { SearchScenarioId } from 'owa-search-store';

export const setForceOfflineSearchTimestamp = action(
    'setForceOfflineSearchTimestamp',
    (searchScenarioId: SearchScenarioId, timestamp: number | undefined) => ({
        searchScenarioId,
        timestamp,
    })
);
