import React from 'react';

import type { MailFolderFromServiceFragment } from 'owa-mail-folder-view-graphql';
import {
    PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID,
    PRIMARY_FOLDERS_TREE_TYPE,
    PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID,
    SHARED_FOLDER_ROOT_DISTINGUISHED_ID,
    INBOX_FOLDER_NAME,
    type LoadMoreFoldersActionSource,
} from 'owa-folders-constants';
import folderStore, {
    FolderTreeLoadStateEnum,
    setLoadingStateForFolderTree,
    lazyFetchMoreFolders,
    getFolderTreeHasMoreData,
    getFolderTreeCurrentLoadedIndex,
    getFolderTreeLoadingState,
    getPrimaryFolderTreeRootFolder,
} from 'owa-folders';
import {
    animationContainer,
    contentContainer,
} from 'owa-expansion-animation/lib/styles/expansionAnimationStyles.scss';
import { transitionStyles } from 'owa-expansion-animation';
import { CSSTransition } from 'react-transition-group';
import { useManagedMutation, useManagedQuery } from 'owa-apollo-hooks';

import { DeleteFolderDocument } from 'owa-folder-deletefolder/lib/graphql/__generated__/DeleteFolderMutation.interface';
import type { FetchPolicy } from '@apollo/client';
import type { FolderHierarchyConfigurationQueryQuery } from '../graphql/__generated__/FolderHierarchyConfigurationQuery.interface';
import { Folders } from './PrimaryMailFolderTreeContainer.locstring.json';
import { MailFolderContextMenu, LazyCustomFolderOrderCallout } from '../lazyFunctions';
import { MailFolderListWrapper } from './MailFolderListWrapper';
import { MailFolderRoot } from 'owa-mail-folder-view';
import { MailFolderTreeContainerDocument } from './__generated__/MailFolderTreeContainerQuery.interface';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { MoveFolderDocument } from 'owa-folder-movefolder/lib/graphql/__generated__/MoveFolderMutation.interface';
import { RenameFolderDocument } from 'owa-folder-rename/lib/graphql/__generated__/RenameFolderMutation.interface';
import { ToggleFolderTreeCollapsedDocument } from '../graphql/__generated__/ToggleFolderTreeCollapsedStateMutation.interface';
import {
    isMonarchMultipleAccountsEnabled,
    isPstFileAccountInfraEnabled,
} from 'owa-account-source-list/lib/flights';
import { lazyMoveFolder } from 'owa-folder-movefolder';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { onDeleteFolder } from '../utils/onDeleteFolder';
import { toggleFolderTreeCollapsed } from '../operations/toggleFolderTreeCollapsed';
import viewStateStore from 'owa-mail-folder-store/lib/store/store';
import { getTryShowCustomOrderCallout } from 'owa-mail-folder-store/lib/selectors/getTryShowCustomOrderCallout';
import doMailboxInfoIndexersMatch from '../utils/doMailboxInfoIndexersMatch';
import { PerformanceDatapoint } from 'owa-analytics';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isSharedCoprincipalAccountEnabled } from 'owa-anchormailbox/lib/isSharedCoprincipalAccountEnabled';
import { isMailboxSharedOrDelegateUserMailbox } from 'owa-anchormailbox/lib/isMailboxSharedOrDelegateUserMailbox';

const lightningId = 'CustomFolderOrderCallout';

function isSharedOrDelegateMailbox(mailboxInfo: MailboxInfo) {
    return isSharedCoprincipalAccountEnabled() && isMailboxSharedOrDelegateUserMailbox(mailboxInfo);
}

function getRootFolderId(mailboxInfo: MailboxInfo): string {
    if (isSharedOrDelegateMailbox(mailboxInfo)) {
        return SHARED_FOLDER_ROOT_DISTINGUISHED_ID;
    }

    return PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID;
}

export interface PrimaryMailFolderTreeContainerProps {
    showRootNode: boolean;
    setSize?: number;
    positionInSet?: number;
    userConfiguration: FolderHierarchyConfigurationQueryQuery['userConfiguration'] | undefined;
    isMailRootFolderTreeCollapsed: boolean | null | undefined;
    userEmailAddress: string | null | undefined;
    isExplicitLogon: boolean | null | undefined;
    isShadowMailbox: boolean;
    mailboxInfo: MailboxInfo;
    isExpanded?: boolean;
    style?: React.CSSProperties;
    ellipsesOnHover?: boolean;
    rootElementRef?: React.RefObject<HTMLDivElement>;
}

/**
 * Primary mailbox container
 */
export default observer(function PrimaryMailFolderTreeContainer(
    props: PrimaryMailFolderTreeContainerProps
) {
    const accountRef = React.createRef<HTMLDivElement>();
    const [moveFolderMutation] = useManagedMutation(MoveFolderDocument);
    const [toggleFolderTreeCollapsedMutation] = useManagedMutation(
        ToggleFolderTreeCollapsedDocument
    );
    const [deleteFolderMutation] = useManagedMutation(DeleteFolderDocument);
    const [renameFolderMutation] = useManagedMutation(RenameFolderDocument);

    const { mailboxInfo, isExpanded, rootElementRef, showRootNode } = props;

    const isMemoEnabled = isFeatureEnabled('fp-memo-folderTree');
    const isMailRootFolderTreeCollapsed = isMemoEnabled
        ? props.isMailRootFolderTreeCollapsed
        : props.userConfiguration?.UserOptions?.IsMailRootFolderTreeCollapsed;

    const isTreeExpanded = React.useMemo(() => {
        return isExpanded ?? !isMailRootFolderTreeCollapsed;
    }, [isExpanded, isMailRootFolderTreeCollapsed]);

    const onRootNodeChevronClicked = React.useCallback(() => {
        toggleFolderTreeCollapsed(toggleFolderTreeCollapsedMutation, !isTreeExpanded, mailboxInfo);
    }, [isTreeExpanded, mailboxInfo]);

    const onRootNodeChevronClickedOld = React.useCallback(() => {
        toggleFolderTreeCollapsed(toggleFolderTreeCollapsedMutation, !isTreeExpanded, mailboxInfo);
    }, [isTreeExpanded, mailboxInfo, toggleFolderTreeCollapsedMutation]);

    const moveFolder = React.useCallback(
        (
            destinationFolderId: string,
            destinationFolderMailboxInfo: MailboxInfo,
            sourceFolderId: string,
            sourceFolderMailboxInfo: MailboxInfo,
            sourceFolderParentFolderId: string,
            sourceFolderDisplayName: string
        ) => {
            lazyMoveFolder.importAndExecute(
                moveFolderMutation,
                destinationFolderId /*destinationFolderId */,
                destinationFolderMailboxInfo /* destinationFolderMailboxInfo */,
                sourceFolderId /* sourceFolderId */,
                sourceFolderMailboxInfo /* sourceFolderMailboxInfo */,
                sourceFolderParentFolderId /* parentFolderId */,
                sourceFolderDisplayName
            );
        },
        []
    );

    const moveFolderOld = React.useCallback(
        (
            destinationFolderId: string,
            destinationFolderMailboxInfo: MailboxInfo,
            sourceFolderId: string,
            sourceFolderMailboxInfo: MailboxInfo,
            sourceFolderParentFolderId: string,
            sourceFolderDisplayName: string
        ) => {
            lazyMoveFolder.importAndExecute(
                moveFolderMutation,
                destinationFolderId /*destinationFolderId */,
                destinationFolderMailboxInfo /* destinationFolderMailboxInfo */,
                sourceFolderId /* sourceFolderId */,
                sourceFolderMailboxInfo /* sourceFolderMailboxInfo */,
                sourceFolderParentFolderId /* parentFolderId */,
                sourceFolderDisplayName
            );
        },
        [moveFolderMutation]
    );

    const deleteFolder = React.useCallback(
        (folderId: string, isSearchFolder?: boolean, distinguishedFolderParentIds?: string[]) => {
            onDeleteFolder(
                folderId,
                PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID,
                mailboxInfo,
                'Keyboard' /* actionSource */,
                deleteFolderMutation,
                renameFolderMutation,
                isSearchFolder,
                distinguishedFolderParentIds
            );
        },
        [mailboxInfo]
    );

    const deleteFolderOld = React.useCallback(
        (folderId: string, isSearchFolder?: boolean, distinguishedFolderParentIds?: string[]) => {
            onDeleteFolder(
                folderId,
                PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID,
                mailboxInfo,
                'Keyboard' /* actionSource */,
                deleteFolderMutation,
                renameFolderMutation,
                isSearchFolder,
                distinguishedFolderParentIds
            );
        },
        [mailboxInfo, deleteFolderMutation, renameFolderMutation]
    );

    const userEmailAddress = isMemoEnabled
        ? props.userEmailAddress
        : props.userConfiguration?.SessionSettings?.UserEmailAddress;
    const rootDisplayName = isMonarchMultipleAccountsEnabled()
        ? userEmailAddress || loc(Folders)
        : loc(Folders);

    const isExplicitLogon = isMemoEnabled
        ? props.isExplicitLogon
        : props.userConfiguration?.SessionSettings?.IsExplicitLogon;
    const isFavoritesSupported =
        // Favorites not supported for explicit logon scenarios
        !isExplicitLogon &&
        // Favorites not supported for local data accounts, e.g. PST files
        (!isPstFileAccountInfraEnabled() || !isLocalDataBackedMailboxInfo(mailboxInfo));

    const isRemoveApolloEnabled = isFeatureEnabled('fp-remove-apollo');
    /**
     * Query FolderHierarchy root folder and meta-data
     */
    const baseFolderDistinguishedIds = isSharedOrDelegateMailbox(props.mailboxInfo)
        ? [SHARED_FOLDER_ROOT_DISTINGUISHED_ID, INBOX_FOLDER_NAME]
        : [PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID];

    const { error, loading, data } = useManagedQuery(MailFolderTreeContainerDocument, {
        variables: {
            baseFolderDistinguishedIds,
            offset: 0,
            mailboxInfo: props.mailboxInfo,
        },
        fetchPolicy: 'cache-only',
        nextFetchPolicy: 'cache-only',
    });

    if (!!error && loading === false) {
        setLoadingStateForFolderTree(
            getRootFolderId(props.mailboxInfo),
            FolderTreeLoadStateEnum.ErrorLoading,
            mailboxInfo
        );
    }

    const rootFolder = isRemoveApolloEnabled
        ? getPrimaryFolderTreeRootFolder(mailboxInfo)
        : data?.folderHierarchy?.RootFolder;
    const shouldShowLoadingSpinner = isRemoveApolloEnabled
        ? getFolderTreeLoadingState(getRootFolderId(props.mailboxInfo), mailboxInfo) ===
          FolderTreeLoadStateEnum.Loading
        : !data && loading;
    const hasMoreData = getFolderTreeHasMoreData(getRootFolderId(props.mailboxInfo), mailboxInfo);
    const offset = getFolderTreeCurrentLoadedIndex(getRootFolderId(props.mailboxInfo), mailboxInfo);

    const fetchMoreData = React.useCallback(
        async (_currentOffset: number, _fetchPolicy: FetchPolicy) => {
            const perfDp = new PerformanceDatapoint('FP_FetchMorePrimaryMailFolders');
            const fetchMoreFolders = await lazyFetchMoreFolders.import();
            await fetchMoreFolders(mailboxInfo, 'PrimaryFolderPane', perfDp);
        },
        [mailboxInfo]
    );

    const indexer = getIndexerValueForMailboxInfo(mailboxInfo);
    const key = 'primarymailboxtree_' + indexer;
    const rootId = 'primaryMailboxRoot_' + indexer;
    const showCustomOrderCallout =
        !isExplicitLogon &&
        isFeatureEnabled('fwk-folders-custom-sorted-on-boot', mailboxInfo) &&
        isTreeExpanded &&
        (rootElementRef || showRootNode) &&
        getTryShowCustomOrderCallout(mailboxInfo);

    return (
        <>
            {/* Render tree root */}
            {showRootNode && (
                <MailFolderRoot
                    elementRef={accountRef}
                    id={rootId}
                    displayName={rootDisplayName}
                    onRootNodeChevronClickedCallback={
                        isMemoEnabled ? onRootNodeChevronClicked : onRootNodeChevronClickedOld
                    }
                    positionInSet={props.positionInSet}
                    rootFolder={rootFolder as MailFolderFromServiceFragment}
                    rootNodeId={getRootFolderId(props.mailboxInfo)}
                    setSize={props.setSize}
                    shouldBeDroppable={true}
                    treeType={PRIMARY_FOLDERS_TREE_TYPE}
                    isRootExpanded={isTreeExpanded}
                    mailboxInfo={mailboxInfo}
                    shouldShowRootNodeContextMenu={true}
                    moveFolder={isMemoEnabled ? moveFolder : moveFolderOld}
                    style={!!isTreeExpanded ? undefined : props.style}
                    ellipsesOnHover={props.ellipsesOnHover}
                />
            )}
            {showCustomOrderCallout && (
                <LazyCustomFolderOrderCallout
                    target={rootElementRef ?? accountRef}
                    id={lightningId}
                    accountMailboxInfo={mailboxInfo}
                    delayInMilliseconds={2000}
                />
            )}
            {/* Render rest of the tree if root is expanded */}
            <CSSTransition
                in={isTreeExpanded}
                timeout={200}
                unmountOnExit={true}
                classNames={transitionStyles}
            >
                <div className={animationContainer}>
                    <div className={contentContainer}>
                        <MailFolderListWrapper
                            key={key}
                            treeType={PRIMARY_FOLDERS_TREE_TYPE}
                            isFavoritesSupported={isFavoritesSupported}
                            hasMoreData={!!hasMoreData}
                            shouldShowLoadingSpinner={shouldShowLoadingSpinner}
                            rootFolder={rootFolder as MailFolderFromServiceFragment}
                            rootNodeId={getRootFolderId(props.mailboxInfo)}
                            mailboxInfo={mailboxInfo}
                            moveFolder={isMemoEnabled ? moveFolder : moveFolderOld}
                            offset={offset}
                            fetchMore={fetchMoreData}
                            deleteFolder={isMemoEnabled ? deleteFolder : deleteFolderOld}
                            style={props.style}
                            ellipsesOnHover={props.ellipsesOnHover}
                            ariaLabelledBy={rootId}
                        />
                    </div>
                </div>
            </CSSTransition>
            {/* Render Context Menu */}
            <PrimaryMailFolderContextMenu
                isExplicitLogon={!!isExplicitLogon}
                isShadowMailbox={props.isShadowMailbox}
                mailboxInfo={mailboxInfo}
                isExpanded={isTreeExpanded}
                isFavoritesSupported={isFavoritesSupported}
            />
        </>
    );
},
'PrimaryMailFolderTreeContainer');

interface PrimaryMailFolderContextMenuProps {
    isExplicitLogon: boolean;
    isShadowMailbox: boolean;
    mailboxInfo: MailboxInfo;
    isExpanded: boolean;
    isFavoritesSupported: boolean;
}

// Componentization of the context menu helps prevent the whole folder list from re-rendering on ctx menu show/hide
const PrimaryMailFolderContextMenu = observer(function (props: PrimaryMailFolderContextMenuProps) {
    const { mailboxInfo, isExpanded, isFavoritesSupported, isShadowMailbox } = props;
    const shouldShowContextMenu = useComputedValue(() => {
        const { contextMenuState } = viewStateStore;
        // Render the context menu if it is set to be visible and for different folder tree, we render a new context menu
        if (
            !contextMenuState?.folderId ||
            contextMenuState.treeType != PRIMARY_FOLDERS_TREE_TYPE ||
            !doMailboxInfoIndexersMatch(
                folderStore.folderTable.get(contextMenuState?.folderId)?.mailboxInfo,
                mailboxInfo
            )
        ) {
            return false;
        }
        return true;
    }, [mailboxInfo]);

    return shouldShowContextMenu ? (
        <MailFolderContextMenu
            shouldHideToggleFavorite={!isFavoritesSupported}
            treeType={PRIMARY_FOLDERS_TREE_TYPE}
            isShadowMailbox={isShadowMailbox}
            mailboxInfo={mailboxInfo}
            deletedItemsDistinguishedFolderId={PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID}
            primaryMailboxInfo={mailboxInfo}
            isTreeExpanded={isExpanded}
        />
    ) : null;
}, 'PrimaryMailFolderContextMenu');
