import getColumnMinWidth from './getColumnMinWidth';
import type MailListColumnHeaderType from '../types/MailListColumnHeaderType';
import { getStore as getMailListLayoutStore } from 'owa-mail-layout';
import type { ListViewColumnHeadersOptions } from 'owa-outlook-service-options';
import {
    getOptionsForFeature,
    OwsOptionsFeatureType,
    getDefaultOptions,
} from 'owa-outlook-service-options';
import type { MailboxInfo } from 'owa-client-types';
import { setItem } from 'owa-local-storage';

export default function calculateColumnWidths(
    isInitialLayout: boolean,
    containerWidth: number,
    prevContainerWidth: React.MutableRefObject<number | null>,
    mailboxInfo: MailboxInfo
) {
    /**
     * We should only do this resize work if the width of the container
     * has changed (or it's the initial layout).
     */
    if (!isInitialLayout && prevContainerWidth?.current === containerWidth) {
        return getCurrentColumnWidths();
    }

    prevContainerWidth.current = containerWidth;

    const preferredColumnWidths = getPreferredColumnWidths();

    const senderColumnMinWidth = getColumnMinWidth(0, mailboxInfo);
    const subjectColumnMinWidth = getColumnMinWidth(1, mailboxInfo);
    const receivedColumnMinWidth = getColumnMinWidth(2, mailboxInfo);

    // We need to make sure the columns are at least as wide as their min width.
    let adjustedSenderColumnWidth = Math.max(
        preferredColumnWidths.senderColumnWidth,
        senderColumnMinWidth
    );
    let adjustedSubjectColumnWidth = Math.max(
        preferredColumnWidths.subjectColumnWidth,
        subjectColumnMinWidth
    );
    let adjustedReceivedColumnWidth = Math.max(
        preferredColumnWidths.receivedColumnWidth,
        receivedColumnMinWidth
    );

    /**
     * Calculates the remaining space in the container after the columns,
     * resize handles, and extra space is accounted for.
     */
    const remainingSpace =
        containerWidth -
        adjustedSenderColumnWidth -
        adjustedSubjectColumnWidth -
        adjustedReceivedColumnWidth -
        40 /* resize handles */ -
        100;

    /**
     * If the value is positive, that means there's extra space we can
     * use to increase the column sizes so we should allocate that equally
     * amongst the columns.
     *
     * NOTE: We only re-allocate the space if the remaining space is greater
     * than 1 to avoid some off-by-one math errors that would cause the
     * columns to keep getting resized.
     */
    if (remainingSpace > 1) {
        const equalSpace = Math.floor(remainingSpace / 3);

        const updatedSenderColumnWidth = adjustedSenderColumnWidth + equalSpace;
        const updatedSubjectColumnWidth = adjustedSubjectColumnWidth + equalSpace;
        const updatedReceivedColumnWidth = adjustedReceivedColumnWidth + equalSpace;

        updateLocalColumnWidths(
            updatedSenderColumnWidth,
            updatedSubjectColumnWidth,
            updatedReceivedColumnWidth
        );

        return {
            senderColumnWidth: updatedSenderColumnWidth,
            subjectColumnWidth: updatedSubjectColumnWidth,
            receivedColumnWidth: updatedReceivedColumnWidth,
        };
    }

    /**
     * If the value is negative, that means we need to reduce the column
     * sizes so they can fit.
     */
    if (remainingSpace < 0) {
        const columns = [
            {
                headerType: 0,
                width: adjustedSenderColumnWidth,
            },
            {
                headerType: 1,
                width: adjustedSubjectColumnWidth,
            },
            {
                headerType: 2,
                width: adjustedReceivedColumnWidth,
            },
        ];

        /**
         * Order the columns from largest to smallest as we'll start
         * shaving width off of the largest column first.
         */
        columns.sort((a, b) => {
            if (a.width < b.width) {
                return 1;
            }
            if (a.width > b.width) {
                return -1;
            }
            return 0;
        });

        let spaceToTrim = Math.abs(remainingSpace);

        for (const column of columns) {
            // Break out of loop if our columns fit.
            if (spaceToTrim <= 0) {
                break;
            }

            /**
             * Assume we'll reduce the column width to that column's min
             * width.
             */
            const minWidth = getColumnMinWidth(column.headerType);
            let newWidth = minWidth;

            /**
             * If removing the space to trim from the column would make
             * the column's width larger than it's min width, then just
             * remove the minimum space required.
             *
             * If we need to remove more space than is allowed (i.e. the
             * column's width would be less than it's min width), then
             * set the column to it's min width and reduce the space to trim
             * and we'll remove it from the next largest column.
             */
            if (column.width - spaceToTrim > minWidth) {
                newWidth = column.width - spaceToTrim;
                spaceToTrim = 0;
            } else {
                spaceToTrim = spaceToTrim - (column.width - minWidth);
            }

            switch (column.headerType) {
                case 0:
                    adjustedSenderColumnWidth = newWidth;
                    break;
                case 1:
                    adjustedSubjectColumnWidth = newWidth;
                    break;
                case 2:
                    adjustedReceivedColumnWidth = newWidth;
                    break;
            }
        }

        updateLocalColumnWidths(
            adjustedSenderColumnWidth,
            adjustedSubjectColumnWidth,
            adjustedReceivedColumnWidth
        );

        return {
            senderColumnWidth: adjustedSenderColumnWidth,
            subjectColumnWidth: adjustedSubjectColumnWidth,
            receivedColumnWidth: adjustedReceivedColumnWidth,
        };
    }

    /**
     * If the preferred column widths can fit within the available space, then
     * use the preferred column widths.
     */
    return preferredColumnWidths;
}

const getPreferredColumnWidths = () => {
    /**
     * Fetch the user preferences from the server. If no preferences are found,
     * fall back to the default values for these settings.
     */
    const listViewColumnHeadersOptions =
        getOptionsForFeature<ListViewColumnHeadersOptions>(
            OwsOptionsFeatureType.ListViewColumnHeaders
        ) ||
        (getDefaultOptions()[
            OwsOptionsFeatureType.ListViewColumnHeaders
        ] as ListViewColumnHeadersOptions);

    return {
        senderColumnWidth: listViewColumnHeadersOptions.senderColumnWidth,
        subjectColumnWidth: listViewColumnHeadersOptions.subjectColumnWidth,
        receivedColumnWidth: listViewColumnHeadersOptions.receivedColumnWidth,
    };
};

const getCurrentColumnWidths = () => {
    const { senderColumnWidth, subjectColumnWidth, receivedColumnWidth } = getMailListLayoutStore();

    return {
        senderColumnWidth,
        subjectColumnWidth,
        receivedColumnWidth,
    };
};

// This function stores the adjusted column widths (i.e. the user's preferred column
// widths + any adjustments made to fit the columns within the available space) in
// local storage so that we can render the columns at the appropriate width the
// next time the user reloads the page.
const updateLocalColumnWidths = (
    senderColumnWidth: number,
    subjectColumnWidth: number,
    receivedColumnWidth: number
) => {
    setItem(
        window,
        `MailListColumnWidths`,
        JSON.stringify({
            senderColumnWidth,
            subjectColumnWidth,
            receivedColumnWidth,
        })
    );
};
