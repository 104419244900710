import getUrlOverrides from '../utils/overrides/getUrlOverrides';
import calculateLogicalRing from '../utils/calculateLogicalRing';
import type { DefaultFlagMap } from '../utils/defaultFlags';
import { setDefaultFlags } from '../utils/defaultFlags';
import store from '../store/Store';
import getFeatureFlagsIndexerForMailboxInfo from '../utils/getFeatureFlagsIndexerForMailboxInfo';
import { checkIfAnonymousFlightingShouldBeDisabled } from '../utils/anonymousFlighting';
import { getGlobalSettingsAccountIndexer } from 'owa-account-source-list-store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';
import type { MailboxInfo } from 'owa-client-types';
import { featureFlagsInitialized } from './featureFlagsInitialized';
import { getEnabledFlights } from '../utils/getEnabledFlights';

export const FEATURES_QUERYSTRING_PARAMETER_NAME = 'features';
export const FLIGHT_DEVTOOLS_NAME = 'fwk-devtools';

const mailboxesWithInitializedFlags: Set<string> = new Set();
let isNoMailboxFlagsInitialized: boolean = false;
export function areFeatureFlagsInitialized(mailboxInfo?: MailboxInfo): boolean {
    // if we are looking for the default case and it was initialized before, then
    // we can return true as a performance optimization
    if (isNoMailboxFlagsInitialized && !mailboxInfo) {
        return true;
    }

    const result =
        mailboxesWithInitializedFlags.size > 0 &&
        mailboxesWithInitializedFlags.has(getFeatureFlagsIndexerForMailboxInfo(mailboxInfo));

    // In the default case, if the value is true, then set isNoMailboxFlagsInitialized
    // so future calls are fast
    if (!mailboxInfo && result) {
        isNoMailboxFlagsInitialized = true;
    }

    return result;
}

// Determines if the feature flags are initialized for a specific indexer
export function areFeatureFlagsInitializedForIndexer(indexer: string): boolean {
    return mailboxesWithInitializedFlags.has(indexer);
}

type FlagsFromServiceObject = {
    [key: string]: boolean;
};

function initializeFeatureFlags(
    flagsFromService: string[],
    localStorageOverrides: Record<string, boolean>,
    mailboxInfo?: MailboxInfo
) {
    const flagsFromServiceObject: FlagsFromServiceObject = flagsFromService.reduce(
        (agg: FlagsFromServiceObject, flagFromService) => {
            agg[flagFromService.toLowerCase()] = true;
            return agg;
        },
        {}
    );

    const logicalRing = calculateLogicalRing(feature => flagsFromServiceObject[feature]);

    // We don't want to initialize a second time and potentially change flags mid-session
    if (areFeatureFlagsInitialized(mailboxInfo)) {
        return logicalRing;
    }

    const isGlobalSettingsAccount = isPersistenceIdIndexerEnabled()
        ? !mailboxInfo ||
          getGlobalSettingsAccountIndexer(/*throwIfNotInitialized*/ false) ===
              getIndexerValueForMailboxInfo(mailboxInfo)
        : !mailboxInfo;

    setDefaultFlags(makeDefaultFlags(flagsFromServiceObject), mailboxInfo || null);

    // honor local storage overrides for non-global accounts if they're stored on a per-account basis
    if (logicalRing == 'Dogfood' && localStorageOverrides) {
        addOverrides(flagsFromServiceObject, localStorageOverrides);
    }

    // url overrides only apply to the global account
    if (flagsFromServiceObject[FLIGHT_DEVTOOLS_NAME] && isGlobalSettingsAccount) {
        addOverrides(flagsFromServiceObject, getUrlOverrides(FEATURES_QUERYSTRING_PARAMETER_NAME));
    }

    store(mailboxInfo).featureFlags = new Map<string, boolean>(
        Object.keys(flagsFromServiceObject).map(key => [key, flagsFromServiceObject[key]])
    );

    mailboxesWithInitializedFlags.add(getFeatureFlagsIndexerForMailboxInfo(mailboxInfo));

    // send satchel action that these were successfully initialized.
    featureFlagsInitialized(getEnabledFlights(mailboxInfo), mailboxInfo);

    checkIfAnonymousFlightingShouldBeDisabled();

    return logicalRing;
}

function addOverrides(
    flags: {
        [feature: string]: boolean;
    },
    overrides: {
        [feature: string]: boolean;
    }
): void {
    for (const key of Object.keys(overrides)) {
        flags[key.toLowerCase()] = overrides[key];
    }
}

function makeDefaultFlags(flags: FlagsFromServiceObject) {
    const defaultFlags: DefaultFlagMap = {};
    for (const key of Object.keys(flags)) {
        defaultFlags[key] = flags[key];
    }
    return defaultFlags;
}

export default initializeFeatureFlags;

// Allow the unit tests to reset the feature flags
export function resetFeatureFlagsInitializedForUnitTests() {
    mailboxesWithInitializedFlags.clear();
}
