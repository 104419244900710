import type { MailListItemCondensedThreeColumnProps } from './MailListItemComponentProps';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import type { TableView } from 'owa-mail-list-store';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import getListViewState from 'owa-mail-list-store/lib/selectors/getListViewState';
import {
    full,
    medium,
    compact,
    condensedItemFirstRowThreeColumnNext,
    isCondensedPinnedRow,
    highTwistyStyles,
    multiSelectNoTwistyDisplayedCondensed,
    firstColumnThreeColumn,
    firstColumnThreeColumnNoTwisty,
    condensedHighTwisty,
    condensedHighTwistyFull,
    condensedHighTwistyFullNoCheckbox,
    condensedHighTwistyMedium,
    condensedHighTwistyMediumNoCheckbox,
    condensedHighTwistyCompact,
    column,
    multiSelectNoCheckbox,
    secondLineTextThreeColumn,
    categoryContainerThreeColumn,
    categoryWellThreeColumn,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';
import {
    condensedRowText,
    condensedItemFirstRow,
    condensedItemFirstRowTextThreeColumn,
} from './MailListItemCondensedThreeColumn.scss';
import { SenderGroupingsActionButton } from 'owa-sender-groupings-button';
import { useMessageListTextStyle } from 'owa-mail-listitem-styles/lib/utils/useMessageListTextStyle';
import mailStore from 'owa-mail-store/lib/store/Store';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import isSenderImageEnabled from 'owa-sender-image-option/lib/utils/isSenderImageEnabled';
import React from 'react';
import {
    MailListItemFirstLineText,
    MailListItemIconBar,
    MailListItemLabelsContainer,
    MailListItemLineText,
    MailListItemTwistyButton,
    MailListItemUnreadCount,
    PersonaCheckbox,
    MailListItemExternalMarker,
    MailListItemUnverifiedMarker,
    MailListItemUnreadBar,
} from 'owa-mail-list-item-components';
import { useMailListItemContainerContext } from 'owa-mail-list-item-shared';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { CopilotInboxPriorityIcon } from 'owa-mail-copilot-inbox-shared';

export default observer(function MailListItemCondensedThreeColumn(
    props: MailListItemCondensedThreeColumnProps
): JSX.Element {
    const {
        canArchive,
        canDelete,
        canPin,
        categories,
        conversationId,
        copilotInboxScore,
        effectiveMentioned,
        firstLineText,
        firstLineTooltipText,
        hasAttachment,
        hasSharepointLink,
        highlightTerms,
        importance,
        isComplete,
        isFirstLevelExpanded,
        isFlagged,
        isInArchiveFolder,
        isInNotesFolder,
        isInOutboxFolder,
        isPinned,
        isSearchTable,
        isSecondLevelExpanded,
        isLoadingSecondLevelExpansion,
        isSelected,
        isSnoozed,
        isTaggedForBigScreen,
        isUnauthenticatedSender,
        itemClassIcon,
        lastDeliveryTimestamp,
        lastSender,
        mailboxGuids,
        parentFolderDisplayName,
        parentFolderId,
        rowId,
        rowKey,
        secondLineText,
        secondLineTooltipText,
        shouldShowExternalLabel,
        shouldShowTwisty,
        shouldShowSenderGroupingsButton,
        showDraftsIndicator,
        showFolderTag,
        showPreviewText,
        smimeType,
        styleSelectorAsPerUserSettings,
        supportsFlagging,
        supportsHoverIcons,
        supportsMove,
        supportsPinning,
        tableViewId,
        unreadCount,
    } = props;

    const densityModeString = getDensityModeCssClass(full, medium, compact);
    // derived properties based on props/flights
    const isUnread = unreadCount > 0;
    const showCategories = categories?.length > 0;
    const showLabelsContainer = showFolderTag || showCategories;

    const { conditionalFormattingModifications } = useMailListItemContainerContext();

    const tableView: TableView | undefined = getListViewStore().tableViews.get(
        tableViewId
    ) as TableView;
    const mailboxInfo = getMailboxInfo(tableView);
    const isConversationView = tableView?.tableQuery.listViewType === 0;
    const showMultiSelectButton = !isSearchTable;
    const senderImageEnabled = isSenderImageEnabled();
    const showCheckbox =
        !showMultiSelectButton ||
        (showMultiSelectButton &&
            (senderImageEnabled || tableView?.isInSelectAllState || tableView?.isInCheckedMode));

    const isLoadingExpansion = useComputedValue((): boolean => {
        const expansionState = getListViewState().expandedConversationViewState;
        const conversationItemParts = mailStore.conversations.get(conversationId);
        return !!(
            (isFirstLevelExpanded || isSecondLevelExpanded) &&
            (expansionState.forks?.length === 0 ||
                (conversationItemParts && conversationItemParts.loadingState.isLoading))
        );
    }, [isFirstLevelExpanded, isSecondLevelExpanded, conversationId]);

    const twistyComponent = (
        <MailListItemTwistyButton
            conditionalFormattingModifications={conditionalFormattingModifications}
            isLoadingExpansion={isLoadingExpansion}
            isFirstLevelExpanded={isFirstLevelExpanded}
            isSecondLevelExpanded={isSecondLevelExpanded}
            isLoadingSecondLevelExpansion={isLoadingSecondLevelExpansion}
            isSelected={isSelected}
            isSingleLine={false}
            isUnread={unreadCount > 0}
            rowKey={rowKey}
            showCondensedView={true}
            styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
            tableViewId={tableViewId}
        />
    );

    const renderSecondLineText = (
        additionalStyles: {
            [key: string]: string;
        },
        text: string,
        tooltipText: string
    ) => {
        return (
            <MailListItemLineText
                additionalStyles={additionalStyles}
                addOverflowStyles={false}
                highlightTerms={highlightTerms}
                text={text}
                tooltipText={tooltipText}
                conditionalFormattingModifications={conditionalFormattingModifications}
            />
        );
    };

    const unreadBar = React.useMemo(() => {
        return (
            <MailListItemUnreadBar
                conditionalFormattingModifications={conditionalFormattingModifications}
                isUnread={isUnread}
                rowKey={rowKey}
                tableViewId={tableViewId}
            />
        );
    }, [isUnread, rowKey, tableViewId, conditionalFormattingModifications]);

    return (
        <>
            {unreadBar}
            <div
                className={classnames(
                    densityModeString,
                    isCondensedPinnedRow,
                    highTwistyStyles,
                    !isConversationView &&
                        showMultiSelectButton &&
                        !senderImageEnabled &&
                        !showCheckbox &&
                        multiSelectNoTwistyDisplayedCondensed
                )}
            >
                {
                    <div
                        className={classnames(
                            firstColumnThreeColumn,
                            isConversationView &&
                                !shouldShowTwisty &&
                                firstColumnThreeColumnNoTwisty,
                            condensedHighTwisty,
                            getDensityModeCssClass(
                                showCheckbox
                                    ? condensedHighTwistyFull
                                    : condensedHighTwistyFullNoCheckbox,
                                showCheckbox
                                    ? condensedHighTwistyMedium
                                    : condensedHighTwistyMediumNoCheckbox,
                                condensedHighTwistyCompact
                            )
                        )}
                    >
                        {shouldShowTwisty && twistyComponent}
                        {showCheckbox && (
                            <PersonaCheckbox
                                isCondensedView={true}
                                isSelected={isSelected}
                                isUnauthenticatedSender={isUnauthenticatedSender}
                                lastDeliveryTimestamp={lastDeliveryTimestamp}
                                lastSender={lastSender}
                                rowKey={rowKey}
                                tableViewId={tableViewId}
                            />
                        )}
                    </div>
                }
                <div className={classnames(column, !showCheckbox && multiSelectNoCheckbox)}>
                    <div
                        className={classnames(
                            condensedItemFirstRowThreeColumnNext,
                            densityModeString
                        )}
                    >
                        <div className={condensedItemFirstRowTextThreeColumn}>
                            <div
                                className={classnames(
                                    condensedRowText,
                                    useMessageListTextStyle('Major')
                                )}
                            >
                                <div className={condensedItemFirstRow}>
                                    {shouldShowExternalLabel && (
                                        <MailListItemExternalMarker isSelected={isSelected} />
                                    )}
                                    {!shouldShowExternalLabel && isUnauthenticatedSender && (
                                        <MailListItemUnverifiedMarker />
                                    )}
                                    {lastSender && shouldShowSenderGroupingsButton && (
                                        <SenderGroupingsActionButton
                                            lastSender={lastSender}
                                            mailboxInfo={mailboxInfo}
                                        />
                                    )}
                                    <MailListItemFirstLineText
                                        highlightTerms={highlightTerms}
                                        showDraftsIndicator={showDraftsIndicator}
                                        isInOutboxFolder={isInOutboxFolder}
                                        unreadCount={unreadCount}
                                        text={firstLineText}
                                        tooltipText={firstLineTooltipText ?? ''}
                                    />
                                </div>
                                <div
                                    className={classnames(
                                        secondLineTextThreeColumn,
                                        densityModeString,
                                        useMessageListTextStyle('Major')
                                    )}
                                >
                                    <CopilotInboxPriorityIcon
                                        copilotInboxScore={copilotInboxScore}
                                        scenario="ML"
                                        mailboxInfo={mailboxInfo}
                                        isUnread={isUnread}
                                    />
                                    {renderSecondLineText(
                                        {},
                                        secondLineText,
                                        secondLineTooltipText ?? ''
                                    )}
                                </div>
                            </div>
                        </div>
                        {!isFirstLevelExpanded && (
                            <MailListItemUnreadCount
                                unreadCount={unreadCount}
                                conditionalFormattingModifications={
                                    conditionalFormattingModifications
                                }
                            />
                        )}
                        <MailListItemIconBar
                            canArchive={canArchive}
                            canDelete={canDelete}
                            canPin={canPin}
                            effectiveMentioned={effectiveMentioned}
                            hasAttachment={hasAttachment}
                            hasSharepointLink={hasSharepointLink}
                            importance={importance}
                            isComplete={isComplete}
                            isFlagged={isFlagged}
                            isInArchiveFolder={isInArchiveFolder}
                            isInNotesFolder={isInNotesFolder}
                            isInOutboxFolder={isInOutboxFolder}
                            isPinned={isPinned}
                            isRowExpanded={isFirstLevelExpanded || isSecondLevelExpanded}
                            isSingleLine={false}
                            isSnoozed={isSnoozed}
                            isTaggedForBigScreen={isTaggedForBigScreen}
                            itemClassIcon={itemClassIcon}
                            lastDeliveryTimestamp={lastDeliveryTimestamp}
                            neverShowHoverIcons={!supportsHoverIcons}
                            neverShowPropertyIcons={false}
                            rowKey={rowKey}
                            smimeType={smimeType}
                            supportsFlagging={supportsFlagging}
                            supportsMove={supportsMove}
                            supportsPinning={supportsPinning}
                            tableViewId={tableViewId}
                            unreadCount={unreadCount}
                        />
                    </div>
                    {showLabelsContainer && (
                        <MailListItemLabelsContainer
                            categories={categories}
                            categoryContainerClass={categoryContainerThreeColumn}
                            categoryWellContainerClass={categoryWellThreeColumn}
                            isSingleLine={false}
                            mailboxGuids={mailboxGuids}
                            parentFolderDisplayName={parentFolderDisplayName}
                            parentFolderId={parentFolderId}
                            rowId={rowId}
                            showCategories={showCategories}
                            showFolderTag={showFolderTag}
                            showPreviewText={showPreviewText}
                            mailboxInfo={mailboxInfo}
                        />
                    )}
                </div>
            </div>
        </>
    );
},
'MailListItemCondensedThreeColumn');
