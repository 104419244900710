import {
    getSubject,
    getSize,
    getItemIds,
    getRowClientItemId,
    getHasIRM,
} from '../selectors/mailRowDataPropertyGetter';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import loc from 'owa-localize';
import { noSubject } from 'owa-locstrings/lib/strings/nosubject.locstring.json';
import getSelectedTableView from './getSelectedTableView';
import type { MailListRowDragData } from 'owa-mail-types/lib/types/MailListRowDragData';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import isConversationView from './isConversationView';
import type { MailboxInfo } from 'owa-client-types';
import getListViewStore from '../store/Store';

export default function getMailListRowDragData(
    tableViewId?: string,
    isCopy?: boolean
): MailListRowDragData {
    // Also rename this to getMailListRowDragData. May be get this information from the mailRowData getter api
    const tableView = tableViewId
        ? getListViewStore().tableViews.get(tableViewId) ?? getSelectedTableView()
        : getSelectedTableView();
    const latestItemIds: string[] = [];
    const subjects: string[] = [];
    const sizes: number[] = [];
    const mailboxInfos: MailboxInfo[] = [];
    const haveIRM: boolean[] = [];
    const selectedRowKeys = [...tableView.selectedRowKeys.keys()];
    const itemType =
        selectedRowKeys.length > 1
            ? isConversationView(tableView)
                ? DraggableItemTypes.MultiMailListConversationRows
                : DraggableItemTypes.MultiMailListMessageRows
            : DraggableItemTypes.MailListRow;

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    selectedRowKeys.forEach(rowKey => {
        const subject = getSubject(rowKey, tableView);
        subjects.push(subject ?? loc(noSubject));

        const size = getSize(rowKey, tableView);
        sizes.push(size ?? 0);

        const itemIds = getItemIds(rowKey, tableView);
        if (itemIds) {
            latestItemIds.push(itemIds[0]);
        }

        const mailboxInfo = getRowClientItemId(rowKey, tableView)?.mailboxInfo;
        mailboxInfos.push(mailboxInfo ?? getModuleContextMailboxInfo());
        const hasIRM = getHasIRM(rowKey, tableView);
        haveIRM.push(hasIRM);
    });

    return {
        itemType,
        tableViewId: tableView.id,
        tableListViewType: tableView.tableQuery.listViewType,
        rowKeys: selectedRowKeys,
        subjects,
        latestItemIds,
        mailboxInfos,
        sizes,
        haveIRM,
        isCopy,
    };
}
