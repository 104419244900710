import type { TableView, MailFolderTableQuery } from 'owa-mail-list-store';
import {
    getIsEverythingSelectedInTable,
    type TableQueryType,
    type SortColumn,
    getListViewState,
} from 'owa-mail-list-store';
import { mutatorAction } from 'satcheljs';
import { now } from 'owa-datetime';
import resetExpansionViewStateInternal from 'owa-mail-list-store/lib/utils/resetExpansionViewStateInternal';
import { getStore as getMessageAdListStore } from 'owa-mail-messagead-list-store';

/**
 * Toggle between select all and clear selection on the table
 * @param tableView where the operation is being performed
 */
export default mutatorAction(
    'toggleSelectAllRowsInternalMutatorAction',
    function toggleSelectAllRowsInternalMutatorAction(tableView: TableView): void {
        // Reset the selection
        // We can enter this mode from
        // 1.Non-checked mode, where single row was selected
        // OR 2. from a checked mode where multiple rows were selected
        // OR 3. this can be a exiting on a select all mode were multiple rows were selected
        // In 1,2,3 cases, we want to deselect the rows that were selected and reset selection will do it
        // 4. this can be a entering in a select all mode were nothing was selected, in which case resetSelection will not change anything

        if (getIsEverythingSelectedInTable(tableView)) {
            tableView.selectedRowKeys.clear();
            tableView.isInCheckedMode = false;
            getListViewState().shouldForceShowMultiSelectRP = false;
            resetExpansionViewStateInternal();
            tableView.isInVirtualSelectAllMode = false;
            tableView.virtualSelectAllExclusionList = [];
            tableView.selectAllModeTimeStamp = null;
            // Reset the message Ad list selection which potentially occupy the right pane
            const messageAdStore = getMessageAdListStore();
            if (messageAdStore) {
                messageAdStore.selectedAdId = '';
            }
            return;
        }

        // VSO 17775: remove checking search table query after fixing 17775
        const mailTableQuery = tableView.tableQuery as MailFolderTableQuery;
        if (
            tableView.rowKeys.length == tableView.totalRowsInView ||
            tableView.tableQuery.type == 1 ||
            mailTableQuery.sortBy?.sortColumn === 16 ||
            mailTableQuery.scenarioType === 'highPriorityViewFilter'
        ) {
            // In case of client has all row keys or table is a search table, add all conversations to the real selection,
            // because we have all conversations in execute search response
            tableView.rowKeys.forEach(rowKey => tableView.selectedRowKeys.set(rowKey, true));
        } else {
            tableView.selectedRowKeys.clear();
            tableView.isInCheckedMode = false;
            getListViewState().shouldForceShowMultiSelectRP = false;
            resetExpansionViewStateInternal();
            tableView.isInVirtualSelectAllMode = false;
            tableView.virtualSelectAllExclusionList = [];
            tableView.selectAllModeTimeStamp = null;
            // Reset the message Ad list selection which potentially occupy the right pane
            const messageAdStore = getMessageAdListStore();
            if (messageAdStore) {
                messageAdStore.selectedAdId = '';
            }
            tableView.selectAllModeTimeStamp = now();
            tableView.isInVirtualSelectAllMode = true;
        }

        // Always set the checked mode to true when we enter the select all mode
        tableView.isInCheckedMode = true;
    }
);
