import { isFeatureEnabled } from 'owa-feature-flags';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { isGulpOrBranching } from 'owa-config';

export function isOfflineSyncAvailable(): boolean {
    return (
        self.BroadcastChannel && // Sync depends on BroadcastChannel for cross-window coordination
        isAnyOfflineModuleEnabled() &&
        isOfflineDataAllowed()
    );
}

function isAnyOfflineModuleEnabled() {
    return (
        isFeatureEnabled('fwk-offline-mail') ||
        isFeatureEnabled('fwk-offline-calendar') ||
        isFeatureEnabled('peo-offline') ||
        isFeatureEnabled('peo-offline-via-pdv') ||
        isFeatureEnabled('peo-offline-shadow-pdv') ||
        isFeatureEnabled('msplaces-offline')
    );
}

function isOfflineDataAllowed(): boolean {
    const policies = getUserConfiguration().PolicySettings;
    /*
    offlineDataAllowed is only enabled for native
    localCacheAllowed is only enabled for web
    */
    return (
        (isHostAppFeatureEnabled('offlineDataAllowed') && !!policies?.OfflineEnabledWin) ||
        (isHostAppFeatureEnabled('localCacheAllowed') &&
            (isGulpOrBranching() ||
                (!!policies?.OfflineEnabledWeb && !!policies?.SignInState?.includes('kmsi'))))
    );
}
