import type { BrandVariants, Theme } from '@fluentui/react-components';
import {
    createDarkTheme,
    createHighContrastTheme,
    createLightTheme,
    /* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
     * This will be cleaned up in the following WI: https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/223900
     *	> Use theme from useV9BrandTheme from owa-react-dom instead */
    webLightTheme,
    /* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
     * This will be cleaned up in the following WI: https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/223900
     *	> Use theme from useV9BrandTheme from owa-react-dom instead */
    webDarkTheme,
} from '@fluentui/react-components';
import type { OwaPalette } from 'owa-theme-shared';
import { getIsMsHighContrast } from 'owa-high-contrast';
import { getIsDarkBaseTheme } from 'owa-theme';
import { getIsDarkTheme } from 'owa-fabric-theme';

export function createFluentTheme(theme: OwaPalette): Theme {
    const brandVariants = getBrandVariants(theme);
    if (getIsMsHighContrast()) {
        return {
            ...createHighContrastTheme(),
            colorNeutralForeground3BrandSelected: 'HighlightText',
            colorNeutralForeground3: 'CanvasText',
        };
    } else if (getIsDarkBaseTheme() || getIsDarkTheme()) {
        return brandVariants ? createDarkTheme(brandVariants) : webDarkTheme;
    } else {
        return brandVariants ? createLightTheme(brandVariants) : webLightTheme;
    }
}

function getBrandVariants(theme: OwaPalette): BrandVariants | undefined {
    if (
        !theme.themeDarker ||
        !theme.themeDark ||
        !theme.themeDarkAlt ||
        !theme.themePrimary ||
        !theme.themeSecondary ||
        !theme.themeTertiary ||
        !theme.themeLight ||
        !theme.themeLighter
    ) {
        return undefined;
    }
    return {
        10: theme.themeDarker,
        20: theme.themeDarker,
        30: theme.themeDark,
        40: theme.themeDarker,
        50: theme.themeDarkAlt,
        60: theme.themeDark,
        70: theme.themeDarkAlt,
        80: theme.themePrimary,
        90: theme.themePrimary,
        100: theme.themeSecondary,
        110: theme.themeTertiary,
        120: theme.themeTertiary,
        130: theme.themeLight,
        140: theme.themeLighter,
        150: theme.themeLighter,
        160: theme.themeLighter,
    };
}
