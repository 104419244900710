import type {
    ResolverContext,
    GraphQLResolveInfo,
    MutationUpdateSampleArgs,
    SampleResult,
} from 'owa-graph-schema';

let counter = 0;

export const updateSampleMutation = async (
    _parent: any,
    args: MutationUpdateSampleArgs,
    _context: ResolverContext,
    _info: GraphQLResolveInfo
): Promise<SampleResult> => {
    return {
        id: 1,
        svalue: `<3 from mutation ${counter++}; argument ${args.input}`,
        inSample: { inValue: 'inValue' },
        __typename: 'SampleResult',
    };
};
