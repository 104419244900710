import { updateAddinOnNavigationToEmptyNullReadingPane } from 'owa-mail-addins';
import React from 'react';

/* eslint-disable-next-line owa-custom-rules/prefer-react-functional-components -- (https://aka.ms/OWALintWiki)
 * This react class component should be re-factored as functional component, if is not possible, write a justification.
 *	> When implementing a simple component, write it as a functional component rather than as a class component */
export default class NullReadingPane extends React.Component<{}, {}> {
    componentDidMount() {
        updateAddinOnNavigationToEmptyNullReadingPane();
    }

    render() {
        return null;
    }
}
