import { createLazyResolver } from 'owa-lazy-resolver';
import { LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FavoritesWeb" */ './lazyIndex')
);

export const lazyRemoveFavoriteWeb = createLazyResolver(
    'REMOVE_FAVORITE_WEB',
    lazyModule,
    m => m.removeFavoriteWeb
);

export const lazyAddFavoriteWeb = createLazyResolver(
    'ADD_FAVORITE_WEB',
    lazyModule,
    m => m.addFavoriteWeb
);
