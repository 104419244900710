import type { MailboxInfo } from 'owa-client-types';
import type {
    M365UserMailboxAccountSource,
    M365GroupMailboxAccountSource,
} from 'owa-account-source-list-store';
import { isRemovedAccount } from 'owa-account-source-list-types';
import { getAccountBySourceId, setGroupAccounts } from 'owa-account-source-list-store';
import { buildM365GroupMailboxAccountSource } from 'owa-account-source-list/lib/utils/accountSourceBuilder';
import getUserAccount from './utils/getUserAccount';
import { MailboxInfoError } from './MailboxInfoError';

/**
 * Add the Group mailbox to the account source
 * @param account Specifies the account to which the shared mailbox is to be added
 * @param groupMailbox Specifies the group mailbox to be added
 */
function addGroupMailboxToAccount(
    account: M365UserMailboxAccountSource,
    groupMailbox: M365GroupMailboxAccountSource
) {
    setGroupAccounts(account.sourceId, {
        loadState: account.groupSources.loadState,
        sources: [...account.groupSources.sources, groupMailbox],
        coprincipalSourceId: account.sourceId,
    });
}

/**
 * Returns the MailboxInfo for the group mailbox
 * @param mailboxInfoInAccount Used to determine the coprincipal account, see getIndexerForMailboxInfo for more details
 * @param smtpAddress SMTP address of the group mailbox
 * @param displayName Optionally specifies the display name of the group mailbox
 * @param mailboxGuid Optionally specifies the mailbox guid of the group mailbox
 * @returns MailboxInfo for the group mailbox
 */
export function getMailboxInfoForGroup(
    mailboxInfoInAccount: MailboxInfo,
    smtpAddress: string,
    displayName?: string,
    mailboxGuid?: string
): MailboxInfo {
    const account = getUserAccount(mailboxInfoInAccount);

    // We build the group mailbox account source that would need to be added, this will get us the sourceId
    // for the group mailbox which we can use to find if it already exists or needs to be added
    const groupMailbox = buildM365GroupMailboxAccountSource(
        account.mailboxInfo,
        displayName || smtpAddress,
        smtpAddress,
        account.persistenceId,
        { auxiliaryMailboxGuid: mailboxGuid }
    );

    if (isRemovedAccount(account)) {
        // Mark the MailboxInfo as coming from a removed account
        return { ...groupMailbox.mailboxInfo, isRemoved: true, mailboxRank: 'Resource' };
    }

    let existing = getAccountBySourceId(groupMailbox.sourceId);
    if (!existing) {
        addGroupMailboxToAccount(account, groupMailbox);
        existing = getAccountBySourceId(groupMailbox.sourceId);
        if (!existing) {
            throw new MailboxInfoError('Failed to find the added shared mailbox');
        }
    }

    return existing.mailboxInfo;
}
