import type { Capability } from 'owa-capabilities';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * NOTE: Please see the README for more information on how the
 * capability functions must be implemented and used.
 */
export const copilotThemeCapability: Capability = {
    isEnabled: (_mailboxInfo?: MailboxInfo) => {
        return true;
    },
    isSupported: (_mailboxInfo?: MailboxInfo) => {
        return isFeatureEnabled('fwk-copilot-themes'); //TODO: check for the correct copilot license
    },
};
