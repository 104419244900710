import type { HoverActionKey } from 'owa-outlook-service-options';
import loc from 'owa-localize';
import { archive } from 'owa-locstrings/lib/strings/archive.locstring.json';
import { deleteItem } from 'owa-locstrings/lib/strings/deleteitem.locstring.json';
import { markAsRead } from 'owa-locstrings/lib/strings/markasread.locstring.json';
import { markAsUnread } from 'owa-locstrings/lib/strings/markasunread.locstring.json';
import { moveTo } from 'owa-locstrings/lib/strings/moveto.locstring.json';
import { unpin } from 'owa-locstrings/lib/strings/unpin.locstring.json';
import { pinIcon } from '../components/MailListItemIconBar.scss';
import { default as Archive } from 'owa-fluent-icons-svg/lib/icons/ArchiveRegular';
import { default as Checkmark } from 'owa-fluent-icons-svg/lib/icons/CheckmarkRegular';
import { default as Delete } from 'owa-fluent-icons-svg/lib/icons/DeleteRegular';
import FlagFilled from 'owa-fluent-icons-svg/lib/icons/FlagFilled';
import FlagRegular from 'owa-fluent-icons-svg/lib/icons/FlagRegular';
import { default as FolderArrowRight } from 'owa-fluent-icons-svg/lib/icons/FolderArrowRightRegular';
import { default as MailRead } from 'owa-fluent-icons-svg/lib/icons/MailReadRegular';
import { default as Mail } from 'owa-fluent-icons-svg/lib/icons/MailRegular';
import { default as PinFilled } from 'owa-fluent-icons-svg/lib/icons/PinFilled';
import { default as Pin } from 'owa-fluent-icons-svg/lib/icons/PinRegular';
import { flagIcon } from 'owa-mail-listitem-styles/lib/scss/FlagIconStyles.scss';
import { pinTitle } from '../components/MailListItemIconBar.locstring.json';
import {
    flagTitle,
    unflagTitle,
} from 'owa-mail-list-item-shared/lib/getFlagCompleteHelper.locstring.json';
import classnames from 'owa-classnames';

export default function getHoverActionIcon(
    action: HoverActionKey,
    unreadCount?: number,
    isPinned?: boolean,
    isFlagged?: boolean,
    isComplete?: boolean
): {
    title: string;
    key: string;
    iconName: string;
    iconClassName?: string;
} {
    switch (action) {
        case 'Archive':
            return {
                title: loc(archive),
                key: 'archiveIcon',
                iconName: Archive,
            };
        case 'Move':
            return {
                title: loc(moveTo),
                key: 'moveIcon',
                iconName: FolderArrowRight,
            };
        case 'Delete':
            return {
                title: loc(deleteItem),
                key: 'deleteIcon',
                iconName: Delete,
            };
        case 'ReadUnread':
            const hasUnread = unreadCount && unreadCount > 0;
            return {
                title: hasUnread ? loc(markAsRead) : loc(markAsUnread),
                key: 'markReadIcon',
                iconName: hasUnread ? MailRead : Mail,
            };
        case 'FlagUnflag':
            return {
                title: isComplete ? loc(flagTitle) : isFlagged ? loc(unflagTitle) : loc(flagTitle),
                key: isComplete ? 'completeIcon' : 'flagIcon',
                iconClassName: classnames(isFlagged && flagIcon),
                iconName: isComplete ? Checkmark : isFlagged ? FlagFilled : FlagRegular,
            };
        case 'PinUnpin': {
            const pinClasses = classnames(isPinned && pinIcon);
            return {
                title: isPinned ? loc(unpin) : loc(pinTitle),
                key: 'pinIcon',
                iconClassName: classnames(pinClasses),
                iconName: isPinned ? PinFilled : Pin,
            };
        }
        case 'None':
        default:
            return { title: '', key: '', iconName: '' };
    }
}
