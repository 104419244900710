import { mutatorAction } from 'satcheljs';
import getStore from '../store/store';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

export default mutatorAction(
    'onCopilotPrioritizeSettingsSaved',
    (mailboxInfo: MailboxInfo, response: any) => {
        if (response.version === 1) {
            const key = getIndexerValueForMailboxInfo(mailboxInfo);
            const store = getStore().copilotPrioritizeSettingsStoreMap.get(key);

            if (store) {
                store.settings = response;
            } else {
                getStore().copilotPrioritizeSettingsStoreMap.set(
                    getIndexerValueForMailboxInfo(mailboxInfo),
                    {
                        settings: response,
                        isInitialized: true,
                    }
                );
            }
        }
    }
);
