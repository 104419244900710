//@ts-nocheck TS6133
/* eslint-disable @typescript-eslint/no-duplicate-imports */
import * as Types from 'owa-graph-schema';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type FolderCountsFragment = {
    __typename?: 'MailFolder';
    UnreadCount: number;
    totalMessageCount: number;
};
export const FolderCountsFragmentDoc = {
    kind: 'Document',
    definitions: [
        {
            kind: 'FragmentDefinition',
            name: { kind: 'Name', value: 'FolderCounts' },
            typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'MailFolder' } },
            selectionSet: {
                kind: 'SelectionSet',
                selections: [
                    { kind: 'Field', name: { kind: 'Name', value: 'UnreadCount' } },
                    { kind: 'Field', name: { kind: 'Name', value: 'totalMessageCount' } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FolderCountsFragment, unknown>;
