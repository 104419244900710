import onLoadInitialRowsSucceeded from '../onLoadInitialRowsSucceeded';
import type { PagingInfoInput as GqlPagingInfo } from 'owa-graph-schema';
import type { TableView, MailFolderTableQuery, MailListRowDataType } from 'owa-mail-list-store';
import {
    getBaseServerFolderId,
    MailSortHelper,
    type SortColumn,
    getGQLPagingInfoForQueryRows,
} from 'owa-mail-list-store';
import { getMailboxInfoFromTableQuery } from 'owa-mail-mailboxinfo';
import { INITIAL_LOAD_ROW_COUNT, shouldTableSortByRenewTime } from 'owa-mail-triage-common';
import type { OnInitialTableLoadComplete } from 'owa-mail-loading-action-types';
import { getConversationRows } from 'owa-mail-find-rows';
import folderId from 'owa-service/lib/factory/folderId';
import type FolderId from 'owa-service/lib/contract/FolderId';
import { apolloErrorToResponseCode } from 'owa-apollo-errors/lib/apolloErrorToResponseCode';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { type PerformanceDatapoint, returnTopExecutingActionDatapoint } from 'owa-analytics';

/**
 * Load the initial conversations from the server
 * @param tableView to load conversations in
 * @param OnInitialTableLoadComplete is a callback that is called when we receive the response
 * The callback is handled by table loading
 * @param isTablePrefetched indicates if the table is prefetched
 * @return a promise that resolves when the load table from server has completed
 */
export default function loadInitialConversationsFromServer(
    tableView: TableView,
    isTablePrefetched: boolean,
    onInitialTableLoadComplete: OnInitialTableLoadComplete,
    initialSessionData?: SessionData,
    skipGqlTimings?: boolean,
    customPagingInfo?: GqlPagingInfo
): Promise<void> {
    const mailTableQuery = tableView.tableQuery as MailFolderTableQuery;
    const lastSwitchMailFolderDp = returnTopExecutingActionDatapoint((dp: PerformanceDatapoint) => {
        return dp.getEventName() == 'SwitchMailFolder';
    });
    lastSwitchMailFolderDp?.addCustomProperty('isConversationMode', true);
    const serverBaseFolderId = getBaseServerFolderId(
        tableView,
        true /* returnNullIfSameAsFolderId */
    );
    const serverFolderId = (serverBaseFolderId as FolderId)?.Id;

    const isFilter = mailTableQuery.viewFilter && mailTableQuery.viewFilter !== 'All';
    const { sortColumn, sortDirection } = MailSortHelper.getDefaultSortBy();
    const isSort =
        mailTableQuery.sortBy &&
        (mailTableQuery.sortBy.sortColumn !== sortColumn ||
            mailTableQuery.sortBy.sortDirection !== sortDirection);

    return getConversationRows(
        mailTableQuery.folderId,
        mailTableQuery.viewFilter,
        mailTableQuery.sortBy,
        shouldTableSortByRenewTime(mailTableQuery),
        mailTableQuery.focusedViewFilter,
        mailTableQuery.requestShapeName,
        getMailboxInfoFromTableQuery(mailTableQuery),
        customPagingInfo ??
            getGQLPagingInfoForQueryRows(
                !isTablePrefetched &&
                    (mailTableQuery.sortBy?.sortColumn === 16 ||
                        mailTableQuery.scenarioType === 'highPriorityViewFilter')
                    ? 200
                    : INITIAL_LOAD_ROW_COUNT, // rowsToLoad
                true /* ConversationView */
            ),
        mailTableQuery.scenarioType === 'mailFolderSortBySize',
        mailTableQuery.categoryName,
        serverFolderId,
        {
            datapoint: {
                customData: {
                    scenarioType: mailTableQuery.scenarioType,
                    isPrefetch: isTablePrefetched,
                    isFilter,
                    isSort,
                },
            },
        }, // requestOptions
        initialSessionData,
        isTablePrefetched,
        undefined, // isReload
        skipGqlTimings,
        mailTableQuery.messageSenderScreeningFilter
    )
        .then(responseMessage => {
            let conversationTypes: MailListRowDataType[] = [];
            if (responseMessage?.edges) {
                conversationTypes = responseMessage.edges.map(
                    edge => edge.node as MailListRowDataType
                );
            }

            onLoadInitialRowsSucceeded(
                tableView,
                conversationTypes,
                responseMessage?.totalConversationRowsInView ?? 0,
                responseMessage?.indexedOffset ?? 0,
                responseMessage?.searchFolderId
                    ? folderId({ Id: responseMessage.searchFolderId })
                    : undefined,
                responseMessage?.folderId ? folderId({ Id: responseMessage.folderId }) : undefined
            );

            onInitialTableLoadComplete(
                tableView,
                true, // isSuccessResponseClass
                '200',
                isTablePrefetched
            );
            lastSwitchMailFolderDp?.addToCustomWaterfall(3, 'LoadInitialItemsFromServerComplete');
        })
        .catch((error: any) => {
            const responseCode = apolloErrorToResponseCode(error);
            onInitialTableLoadComplete(tableView, false, responseCode, isTablePrefetched);
            lastSwitchMailFolderDp?.addToCustomWaterfall(3, 'LoadInitialItemsFromServerComplete');
            return Promise.reject(error);
        });
}
