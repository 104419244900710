import type { MailFolderTableQuery, TableView } from 'owa-mail-list-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { type SortColumn } from 'owa-mail-list-store';

const SUPPORTED_JUMPTO_SORT_COLUMNS = [3, 8, 5, 1, 14, 7];

export default function shouldShowJumpTo(tableView: TableView | undefined): boolean {
    const tableQuery = tableView?.tableQuery as MailFolderTableQuery;
    const sortColumn = tableQuery?.sortBy?.sortColumn;

    return (
        !!tableView &&
        isFeatureEnabled('tri-jumpToInMl-dev') &&
        SUPPORTED_JUMPTO_SORT_COLUMNS.includes(sortColumn)
    );
}
