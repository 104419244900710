import type { FolderPermissionsStore } from './schema/FolderPermissionsStore';
import { createStore } from 'satcheljs';

/**
 * Selector to get UI store for folder permissions package
 */
const store = createStore<FolderPermissionsStore>('folderPermissionsStore', {
    folderId: null,
    permissions: [],
    selectedPermissionIndex: 0,
    showAddUserDialog: false,
    showFolderPermissionsDialog: false,
    showLoader: false,
    shouldDisableDefaultPermissions: false,
})();
export default store;
export const getStore = () => store;
