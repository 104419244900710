import { type MailComposeTabViewState, type TabState } from 'owa-tab-store';
import { composeStore } from 'owa-mail-compose-store';
import getProjection from 'owa-popout-v2/lib/utils/getProjection';
import { getComposeHostItemIndex } from 'owa-addins-core';
import {
    lazyInitializeAddinsForItem,
    lazyDeinitializeAddinsForItem,
} from 'owa-addins-boot/lib/lazyFunctions';
import { lazyOnSendAddinItemSwitch } from 'owa-addins-view/lib/lazyFunctions';
import isPopout from 'owa-popout-v2/lib/utils/isPopout';
import { DialogResponse } from 'owa-confirm-dialog/lib/actions/DialogResponse';
import setIsOnSendEventTriggered from 'owa-addins-editor-plugin/lib/actions/setIsOnSendEventTriggered';
import { lazyCreateMailComposeAdapter } from 'owa-mail-addins';
import {
    lazyTrySaveAndCloseCompose,
    lazyTrySendMessageFromOnSendItemSwitchDialog,
    lazyPopoutComposeAndWaitForProjection,
} from 'owa-mail-compose-actions';
import activateTab from 'owa-tab-store/lib/actions/activateTab';
import { trace } from 'owa-trace';

export default async function handleComposeTab(activeComposeTab: MailComposeTabViewState) {
    const composeId = activeComposeTab.data;
    const composeViewState = composeStore.viewStates.get(composeId);
    if (
        composeViewState?.isComposeRendered &&
        composeViewState?.addin.isOnSendEventTriggered &&
        activeComposeTab.state !== 3
    ) {
        let targetWindow = getProjection(activeComposeTab?.id)?.window || window;
        const hostItemIndex = getComposeHostItemIndex(composeViewState?.composeId);
        lazyDeinitializeAddinsForItem.import().then(deinitializeAddinsForItem => {
            deinitializeAddinsForItem(hostItemIndex, window, composeViewState?.mailboxInfo);
        });
        if (!isPopout(targetWindow)) {
            activateTab(activeComposeTab);
            lazyOnSendAddinItemSwitch
                .importAndExecute(composeViewState.mailboxInfo)
                .then(async (response: DialogResponse) => {
                    switch (response) {
                        case DialogResponse.ok:
                            setIsOnSendEventTriggered(composeViewState.addin, false);
                            const popoutComposeAndWaitForProjection =
                                await lazyPopoutComposeAndWaitForProjection.import();
                            try {
                                await popoutComposeAndWaitForProjection(
                                    composeViewState,
                                    targetWindow
                                );
                            } catch (error) {
                                trace.warn(error.message, 'addins');
                            }
                            targetWindow =
                                (activeComposeTab && getProjection(activeComposeTab?.id)?.window) ||
                                window;

                            Promise.all([
                                lazyCreateMailComposeAdapter.import(),
                                lazyInitializeAddinsForItem.import(),
                            ]).then(([createMailComposeAdapter, initializeAddinsForItem]) => {
                                const adapter = createMailComposeAdapter(
                                    composeViewState,
                                    targetWindow ?? window
                                );

                                initializeAddinsForItem(
                                    hostItemIndex,
                                    adapter,
                                    true /* renderTaskPaneInPanel */,
                                    targetWindow,
                                    composeViewState.mailboxInfo,
                                    composeViewState?.itemId?.Id
                                );
                            });
                            await lazyTrySendMessageFromOnSendItemSwitchDialog.importAndExecute(
                                composeViewState,
                                targetWindow ?? window
                            );
                            break;
                        case DialogResponse.continue:
                            targetWindow =
                                (activeComposeTab && getProjection(activeComposeTab?.id)?.window) ||
                                window;

                            // When send anyway is clicked, we should not trigger onSend event again
                            setIsOnSendEventTriggered(composeViewState.addin, true);
                            await lazyTrySendMessageFromOnSendItemSwitchDialog.importAndExecute(
                                composeViewState,
                                targetWindow ?? window
                            );
                            break;
                        case DialogResponse.cancel:
                            await lazyTrySaveAndCloseCompose.importAndExecute(composeViewState);
                            break;
                        case DialogResponse.dismiss:
                            setIsOnSendEventTriggered(composeViewState.addin, false);
                            break;
                        default:
                            // Handle any other cases or do nothing
                            break;
                    }
                });
        }
    }
}
