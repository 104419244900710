import { type LabelPreferenceId } from './labelPreferencesIds';
import type { MailRibbonConfigStore } from '../types/mailRibbonConfigStore';
import type { MailRibbonGroup } from '../types/mailRibbonGroup';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import {
    type MailRibbonGroupId,
    type MailRibbonControlId,
    type MailRibbonTabId,
} from 'owa-ribbon-ids/lib/mailRibbonId';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import { type HelpRibbonGroupId, type HelpRibbonControlId } from 'owa-ribbon-ids/lib/helpControlId';
import { ObservableMap } from 'mobx';

// This variable should always match the length of `mailRibbonVersionManager` in owa-mail-ribbon-versioning,
// but we can't import that length due to circular dependencies. Be sure to increment when updating versioning.
const CURRENT_RIBBON_VERSION: number = 35;

function getRibbonSectionContents(
    groupId: MailRibbonGroupId | HelpRibbonGroupId,
    controlIds: RibbonControlId[],
    hasOwnOverflow: boolean = false
): MailRibbonGroup {
    return {
        groupId,
        groupName: undefined,
        controlIds,
        hasOwnOverflow,
    };
}

/**
 * This is the default configuration for OWA/Monarch's ribbon. Should there be no user
 * customization, this ribbon will be used as is.
 *
 * Important: If you make changes to defaultConfig, you will likely want to make changes
 * to Ribbon Versioning too such that users with a customized ribbon will grab your changes.
 * @see {mailRibbonVersionManager} and please add what you've changed there.
 */
export const defaultConfig: MailRibbonConfigStore = {
    version: CURRENT_RIBBON_VERSION,
    multiline: {
        homeTab: {
            layout: [
                getRibbonSectionContents(116, [587]),
                getRibbonSectionContents(104, [531, 658, 519, 683, 684, 505]),
                getRibbonSectionContents(197, [657]),
                getRibbonSectionContents(192, []),
                getRibbonSectionContents(121, [556, 557, 555, 678, 553, 696, 686, 712]),
                getRibbonSectionContents(198, [702, 709]),
                getRibbonSectionContents(195, [697]),
                getRibbonSectionContents(113, [572, 540, 559]),
                getRibbonSectionContents(120, [550]),
                getRibbonSectionContents(128, [552, 509, 527, 548, 571, 616]),
                getRibbonSectionContents(118, [549]),
                getRibbonSectionContents(101, [529, 7016, 7022]),
                getRibbonSectionContents(108, [663, 508]),
                getRibbonSectionContents(130, [580]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [
                116, 104, 197, 192, 121, 198, 195, 113, 120, 128, 118, 101, 108, 130,
            ],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        viewTab: {
            layout: [
                getRibbonSectionContents(152, [643]),
                getRibbonSectionContents(153, [644, 645, 687, 688, 701]),
                getRibbonSectionContents(154, [691, 647, 648, 706, 649]),
                getRibbonSectionContents(110, [532]),
                getRibbonSectionContents(194, [690]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [152, 153, 154, 110, 194],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        insertTab: {
            layout: [
                getRibbonSectionContents(111, [620, 7025, 506, 7026, 638, 639, 637]),
                getRibbonSectionContents(165, [529, 7016, 7021]),
                getRibbonSectionContents(169, [7000]),
                getRibbonSectionContents(166, [7011]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [111, 165, 169, 166],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        formatTab: {
            layout: [
                getRibbonSectionContents(102, [6013, 6011, 6012, 6009, 6010, 6029]),
                getRibbonSectionContents(
                    134,
                    [6027, 6028, 6024, 6030, 6001, 6002, 6003, 6004, 6023, 6022, 6026, 6025, 6073]
                ),
                getRibbonSectionContents(
                    135,
                    [6005, 6006, 6008, 6007, 6017, 6018, 6019, 6110, 6075, 6020, 6021, 6016]
                ),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [102, 134, 135],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        messageTab: {
            layout: [
                getRibbonSectionContents(163, [6012, 6013, 6029, 6010, 6009]),
                getRibbonSectionContents(
                    157,
                    [
                        6027, 6028, 6005, 6006, 6024, 6030, 6016, 6001, 6002, 6003, 6004, 6026,
                        6025, 6008, 6007, 6071, 6075, 6020, 6021, 6022, 6023,
                    ]
                ),
                getRibbonSectionContents(179, [6073]),
                getRibbonSectionContents(177, [7003]),
                getRibbonSectionContents(161, [620, 7025, 506, 7026, 638, 639, 637]),
                getRibbonSectionContents(180, [7023]),
                getRibbonSectionContents(159, [529, 7016, 7021]),
                getRibbonSectionContents(173, [7000]),
                getRibbonSectionContents(187, [7029]),
                getRibbonSectionContents(160, [7005, 7006, 564]),
                getRibbonSectionContents(174, [7002]),
                getRibbonSectionContents(199, [7036]),
                getRibbonSectionContents(170, [560, 7035]),
                getRibbonSectionContents(171, [7011]),
                getRibbonSectionContents(162, [7001, 7004, 6072, 7010]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [
                163, 157, 179, 161, 159, 173, 180, 187, 160, 174, 170, 171, 162,
            ],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        notesTab: {
            layout: [
                getRibbonSectionContents(144, [587]),
                getRibbonSectionContents(145, [640, 641]),
                getRibbonSectionContents(146, [642]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [144, 145, 146],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        tableFormatTab: {
            layout: [
                getRibbonSectionContents(147, [6037, 6042, 6087]),
                getRibbonSectionContents(148, [6046, 6047]),
                getRibbonSectionContents(196, [6098, 6097, 6100]),
                getRibbonSectionContents(149, [6049, 6054]),
                getRibbonSectionContents(150, [6058, 6059, 6060, 6061]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [147, 148, 196, 149, 150],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        readTab: {
            layout: [
                getRibbonSectionContents(104, [658, 519, 505]),
                getRibbonSectionContents(197, [657]),
                getRibbonSectionContents(121, [556, 557, 555, 678, 553, 696, 686, 712]),
                getRibbonSectionContents(198, [702, 709]),
                getRibbonSectionContents(195, [697]),
                getRibbonSectionContents(193, [688]),
                getRibbonSectionContents(192, []),
                getRibbonSectionContents(113, [572, 559, 705]),
                getRibbonSectionContents(128, [552, 509, 527, 616]),
                getRibbonSectionContents(118, [549]),
                getRibbonSectionContents(101, [7016]),
                getRibbonSectionContents(110, [532]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [104, 197, 192, 121, 198, 195, 113, 128, 118, 110],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        optionsTab: {
            layout: [
                getRibbonSectionContents(172, [7001, 7004]),
                getRibbonSectionContents(124, [7013]),
                getRibbonSectionContents(175, [7034, 7020, 7019, 7009]),
                getRibbonSectionContents(176, [7015, 7014, 7031]),
                getRibbonSectionContents(167, [7005, 7006, 564, 7012]),
                getRibbonSectionContents(199, [7036]),
                getRibbonSectionContents(174, [7002]),
                getRibbonSectionContents(123, [560, 7035]),
                getRibbonSectionContents(162, [6072, 7010]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [172, 124, 167, 123, 162],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        helpTab: {
            layout: [
                getRibbonSectionContents(11001, [11201, 11202, 11203, 11208]),
                getRibbonSectionContents(11002, [11204, 11205, 11209]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [11001, 11002],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        drawTab: {
            layout: [
                getRibbonSectionContents(181, [12001, 12002]),

                getRibbonSectionContents(182, [12003, 12004]),

                getRibbonSectionContents(183, [12005, 12012, 12006, 12007, 12008, 12009]),

                getRibbonSectionContents(184, [12010]),

                getRibbonSectionContents(185, [12011]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [181, 182, 183, 184, 185],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        pictureTab: {
            layout: [
                getRibbonSectionContents(188, [6079, 6080]),
                getRibbonSectionContents(189, [6085]),
                getRibbonSectionContents(190, [6081, 6082, 6083]),
                getRibbonSectionContents(191, [6084]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
    },
    singleline: {
        homeTab: {
            layout: [
                getRibbonSectionContents(114, [587, 519, 683, 684, 505, 657, 658, 572, 540, 559]),
                getRibbonSectionContents(192, []),
                getRibbonSectionContents(121, [556, 557, 555, 696, 678, 553, 686, 712]),
                getRibbonSectionContents(198, [702, 709]),
                getRibbonSectionContents(195, [697]),
                getRibbonSectionContents(128, [550, 552, 509, 527, 548, 571, 616]),
                getRibbonSectionContents(118, [549]),
                getRibbonSectionContents(101, [529, 7022, 7016]),
                getRibbonSectionContents(108, [663, 508]),
                getRibbonSectionContents(130, [580]),
                getRibbonSectionContents(122, [558]),
            ],
            controlsInOverflow: [658, 508, 553, 686, 697, 559, 712],
            staticGroupIdOrdering: [114, 192, 121, 198, 195, 128, 130, 118, 101, 108],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        viewTab: {
            layout: [
                getRibbonSectionContents(152, [643]),
                getRibbonSectionContents(153, [654, 687, 688, 701]),
                getRibbonSectionContents(154, [646, 649]),
                getRibbonSectionContents(110, [532]),
                getRibbonSectionContents(194, [690]),
                getRibbonSectionContents(122, [558]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [152, 153, 154, 110, 194, 122],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        insertTab: {
            layout: [
                getRibbonSectionContents(111, [620, 7025, 506, 7026, 638, 639, 637]),
                getRibbonSectionContents(165, [529, 7016, 7021]),
                getRibbonSectionContents(169, [7000]),
                getRibbonSectionContents(166, [7011]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [111, 165, 169, 166],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        formatTab: {
            layout: [
                getRibbonSectionContents(102, [6013, 6012, 6029]),
                getRibbonSectionContents(
                    134,
                    [6027, 6028, 6001, 6002, 6003, 6026, 6025, 6030, 6024, 6004, 6023, 6022, 6073]
                ),
                getRibbonSectionContents(
                    135,
                    [6005, 6006, 6008, 6007, 6017, 6018, 6019, 6110, 6075, 6020, 6021, 6016]
                ),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [102, 134, 135],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        messageTab: {
            layout: [
                getRibbonSectionContents(163, [6013, 6012, 6029]),
                getRibbonSectionContents(
                    157,
                    [
                        6027, 6028, 6001, 6002, 6003, 6004, 6026, 6025, 6030, 6005, 6006, 6008,
                        6007, 6071, 6075, 6020, 6021, 6016, 6024, 6022, 6023,
                    ],
                    true /* hasOwnOverflow*/
                ),
                getRibbonSectionContents(179, [6073]),
                getRibbonSectionContents(177, [7003]),
                getRibbonSectionContents(158, [620, 7025, 506, 7026]),
                getRibbonSectionContents(180, [7023]),
                getRibbonSectionContents(159, [529, 7016, 7021]),
                getRibbonSectionContents(169, [7000]),
                getRibbonSectionContents(187, [7029]),
                getRibbonSectionContents(160, [7005, 7006, 564]),
                getRibbonSectionContents(161, [638, 639, 637]),
                getRibbonSectionContents(
                    162,
                    [7004, 7002, 7036, 560, 7035, 7011, 7001, 6072, 7010]
                ),
            ],
            controlsInOverflow: [638, 639, 637, 7004, 7002, 560, 7035, 7011, 7001, 6072, 7010],
            staticGroupIdOrdering: [163, 157, 179, 158, 159, 187, 160, 161, 162],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        notesTab: {
            layout: [
                getRibbonSectionContents(145, [587, 640, 641]),
                getRibbonSectionContents(146, [642]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [145, 146],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        tableFormatTab: {
            layout: [
                getRibbonSectionContents(147, [6037, 6042, 6087]),
                getRibbonSectionContents(148, [6046, 6047]),
                getRibbonSectionContents(196, [6098, 6097, 6100]),
                getRibbonSectionContents(149, [6049, 6054]),
                getRibbonSectionContents(150, [6058, 6059, 6060, 6061]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [147, 148, 196, 149, 150],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        readTab: {
            layout: [
                getRibbonSectionContents(114, [519, 505, 657, 658, 572, 559, 705]),
                getRibbonSectionContents(192, []),
                getRibbonSectionContents(121, [556, 557, 555, 678, 553, 696, 686, 712]),
                getRibbonSectionContents(198, [702, 709]),
                getRibbonSectionContents(195, [697]),
                getRibbonSectionContents(193, [688]),
                getRibbonSectionContents(128, [552, 509, 527, 616]),
                getRibbonSectionContents(118, [549]),
                getRibbonSectionContents(101, [7016]),
                getRibbonSectionContents(110, [532]),
            ],
            controlsInOverflow: [658, 553, 696, 686, 697, 572, 559, 616, 532, 712],
            staticGroupIdOrdering: [114, 192, 121, 198, 195, 128, 118, 101, 110],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        optionsTab: {
            layout: [
                getRibbonSectionContents(172, [7001, 7004]),
                getRibbonSectionContents(124, [7013]),
                getRibbonSectionContents(175, [7034, 7020, 7019, 7009]),
                getRibbonSectionContents(167, [7005, 7006, 564, 7012]),
                getRibbonSectionContents(199, [7036]),
                getRibbonSectionContents(176, [7015, 7014, 7031]),
                getRibbonSectionContents(168, [7002, 560, 7035, 6072, 7010]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [172, 124, 167, 168],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        helpTab: {
            layout: [
                getRibbonSectionContents(11001, [11201, 11202, 11203]),
                getRibbonSectionContents(11002, [11204, 11205, 11209]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [11001, 11002],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        drawTab: {
            layout: [
                getRibbonSectionContents(181, [12001, 12002]),

                getRibbonSectionContents(182, [12003, 12004]),

                getRibbonSectionContents(183, [12005, 12012, 12006, 12007, 12008, 12009]),

                getRibbonSectionContents(184, [12010]),

                getRibbonSectionContents(185, [12011]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [181, 182, 183, 184, 185],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
        pictureTab: {
            layout: [
                getRibbonSectionContents(188, [6079, 6080]),
                getRibbonSectionContents(189, [6085]),
                getRibbonSectionContents(190, [6081, 6082, 6083]),
                getRibbonSectionContents(191, [6084]),
            ],
            controlsInOverflow: [],
            staticGroupIdOrdering: [188, 189, 190, 191],
            showLabelsPreference: 1,
            removedDefaultGroups: [],
        },
    },
    contextualTabs: [],
    /* the key here is the editorId, which is the id of the editorViewState to differenciate them in multiple editors */
    contextualTabsMultiWindows: new ObservableMap<string, MailRibbonTabId[]>(),
};
