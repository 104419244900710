import {
    TagRegular,
    TagFilled,
    bundleIcon,
    iconFilledClassName,
    iconRegularClassName,
} from '@fluentui/react-icons';
import { createIconModule } from 'owa-fluent-icons-svg';
import type { CategoryColorValue } from '../store/schema/CategoryColor';
import TagDuoToneChecked from './TagDuoToneChecked';
import type { IIconStyles } from '@fluentui/react/lib/Icon';

const bundledIconChildStyle = {
    display: 'inline-flex !important',
    justifyContent: 'center',
    position: 'absolute',
};

const TagIconBundle = bundleIcon(TagFilled, TagRegular);
export const TagDuoToneIcon = createIconModule('TagIconBundle', TagIconBundle);

export const getDuoToneIconStyles = (categoryColor: CategoryColorValue): IIconStyles => {
    return {
        root: {
            [`& .${iconFilledClassName}`]: {
                ...bundledIconChildStyle,
                color: `${categoryColor.primaryColor} !important`,
            },
            [`& .${iconRegularClassName}`]: {
                ...bundledIconChildStyle,
                color: `${categoryColor.iconColor} !important`,
            },
        },
    };
};

export const getCategoryMenuItemStyles = (
    getIconStyles: IIconStyles,
    isChecked: boolean,
    maxWidth: number
) => {
    return {
        iconName: isChecked ? TagDuoToneChecked : TagDuoToneIcon,
        styles: {
            root: {
                ...getIconStyles,
                maxWidth,
                paddingLeft: isChecked ? '2px' : '16px',
                borderRadius: '4px',
            },
            label: {
                paddingLeft: isChecked ? '22px' : '7px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    };
};
