import {
    LazyImport,
    LazyModule,
    LazyAction,
    createLazyComponent,
    registerLazyOrchestrator,
} from 'owa-bundling';
import { getSuggestions } from 'owa-search-actions/lib/actions/getSuggestions';
import { searchSessionStarted } from 'owa-search-actions/lib/actions/searchSessionStarted';
import { startSearch } from 'owa-search-actions/lib/actions/startSearch';
import { startAnswersSearchSession } from './actions/startAnswersSearchSession';
import getSelectedSearchScopeDisplayName from './utils/getSelectedSearchScopeDisplayName';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailSearch"*/ './lazyIndex'),
    { name: 'MailSearch' }
);
export const SearchHeaderFirstRowContent = createLazyComponent(
    lazyModule,
    m => m.SearchHeaderFirstRowContent
);

// Export lazy loaded components
export const FolderScopePicker = createLazyComponent(
    lazyModule,
    m => m.FolderScopePicker,
    undefined,
    undefined,
    {
        onlyImportIf: () => !!getSelectedSearchScopeDisplayName(),
    }
);
export const lazySetStaticSearchScopeData = new LazyImport(
    lazyModule,
    m => m.setStaticSearchScopeData
);

export const lazyStartSearchWithCategory = new LazyImport(
    lazyModule,
    m => m.startSearchWithCategory
);
export const lazyFindEmailFromSender = new LazyAction(lazyModule, m => m.findEmailFromSender);

export const lazyFindEmailFromConversation = new LazyAction(
    lazyModule,
    m => m.findEmailFromConversation
);

export const lazyStoreTopResults = new LazyAction(lazyModule, m => m.storeTopResults);
export const lazySetNumberOfTopResultsShown = new LazyAction(
    lazyModule,
    m => m.setNumberOfTopResultsShown
);

export const lazyPersonaControlStore = new LazyImport(lazyModule, m => m.personaControlStore);
export const lazyCreateFallbackPersonaSearchTableQuery = new LazyImport(
    lazyModule,
    m => m.createFallbackPersonaSearchTableQuery
);

export const lazyCreateFallbackCategorySearchTableQuery = new LazyImport(
    lazyModule,
    m => m.createFallbackCategorySearchTableQuery
);

export const lazyInitializeSearch = new LazyAction(lazyModule, m => m.initializeSearch);

export const lazyPrimeMailSearchZeroQueryCache = new LazyAction(
    lazyModule,
    m => m.primeMailSearchZeroQueryCache
);

// Register lazy orchestrator
registerLazyOrchestrator(getSuggestions, lazyModule, m => m.getSuggestionsOrchestrator);
registerLazyOrchestrator(searchSessionStarted, lazyModule, m => m.searchSessionStartedOrchestrator);
registerLazyOrchestrator(startSearch, lazyModule, m => m.startSearchOrchestrator, {
    captureBundleTime: true,
});
registerLazyOrchestrator(startSearch, lazyModule, m => m.startGqlSearchOrchestrator);
registerLazyOrchestrator(
    startAnswersSearchSession,
    lazyModule,
    m => m.startAnswerSearchSessionOrchestrator
);
