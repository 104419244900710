import { MAX_CACHED_ROWS_TO_SHOW_TABLE_LOAD } from 'owa-mail-triage-common';
import { mergeRowResponseFromTop } from 'owa-mail-list-response-processor';
import type { MailListRowDataType, TableView } from 'owa-mail-list-store';
import { type TableQueryType, canTableBeOutOfSyncWithServer } from 'owa-mail-list-store';
import type FolderId from 'owa-service/lib/contract/FolderId';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import { isFeatureEnabled } from 'owa-feature-flags';
import { lazyFetchReactionDataForMessageList } from 'owa-listview-reactions';

/**
 * Callback when load initial rows request succeeds
 * @param tableView to load
 * @param newRows to be merged
 * @param newTotalRowsInView the number of new total rows
 * @param indexOfLastRowInView the index of the last row in view
 * @param searchFolderId the searchFolderId contained in the response message
 */
export default action('onLoadInitialRowsSucceeded')(function onLoadInitialRowsSucceeded(
    tableView: TableView,
    newRows: MailListRowDataType[],
    newTotalRowsInView: number,
    indexOfLastRowInView: number,
    searchFolderId?: FolderId,
    folderId?: FolderId
) {
    // In case of groups, we don't initially have the actual folder id when the tableView is created (similar to search folders in inbox),
    // So we get it in the response for findConversation call and update the serverFolderId.
    if (
        (tableView.tableQuery.type == 2 ||
            (isFeatureEnabled('grp-loadFolders') && tableView.tableQuery.type == 3)) &&
        folderId
    ) {
        tableView.serverFolderId = folderId.Id;
    }

    // If SearchFolderId is returned in response, set it on the tableView
    if (searchFolderId) {
        tableView.serverFolderId = searchFolderId.Id;
    }

    // Invalidate the remaining rows if the client table is out of sync with the server
    const shouldRemoveRemainingRowsAfterMerge = canTableBeOutOfSyncWithServer(tableView);

    // Update rows in listview store
    // also log isDataUptodate only if the table is not being loaded for first time
    mergeRowResponseFromTop(
        newRows,
        tableView,
        newTotalRowsInView,
        shouldRemoveRemainingRowsAfterMerge /* removeRemainingRowsAfterMerge */,
        tableView.isInitialLoadComplete /* shouldLogIsDataUptodate */,
        'MergeOnInitialRowsFetch'
    );

    // Set the loaded index to max allowed on initial table load
    tableView.currentLoadedIndex = Math.min(
        MAX_CACHED_ROWS_TO_SHOW_TABLE_LOAD,
        tableView.rowKeys.length
    );

    // Set the loaded start index to be the last element index - the number of rows
    // The number of rows is the same as tableView.rowKeys.length since it is the initial load
    tableView.loadedStartIndex = Math.max(0, indexOfLastRowInView - newRows.length);

    if (isFeatureEnabled('tri-message-list-reactions')) {
        // Iterate over new rows and determine if any of them have a non-zero
        // ReactionsCount property. If so, add them to the targetRowKeys array
        // for the fetchReactionDataForMessageList orchestrator to process.
        const targetRowKeys: string[] = [];

        newRows.forEach((row: MailListRowDataType) => {
            if ((row.ReactionsCount ?? 0) > 0 && row.InstanceKey) {
                targetRowKeys.push(row.InstanceKey);
            }
        });

        if (targetRowKeys.length > 0) {
            lazyFetchReactionDataForMessageList.importAndExecute(tableView, targetRowKeys);
        }
    }
});
