import type {
    PrimaryMailFolderHierarchyQuery,
    PrimaryMailFolderHierarchyQueryVariables,
} from './graphql/__generated__/PrimaryMailFolderHierarchyQuery.interface';

import { PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { PrimaryMailFolderHierarchyDocument } from './graphql/__generated__/PrimaryMailFolderHierarchyQuery.interface';
import { errorThatWillCauseAlert } from 'owa-trace';
import { extractFolderHierarchy } from 'owa-folders/lib/util/extractFolderHierarchy';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { shouldShowFolder } from 'owa-folders-data-utils';
import { writeQuery } from 'owa-apollo';
import { updateFoldersChildIds } from './updateFoldersChildIds';

// this can be removed when fp-remove-apollo has shipped
export function bootstrapFolderHierarchyCache(sessionData: SessionData | undefined) {
    try {
        const globalAccountMailboxInfo = getGlobalSettingsAccountMailboxInfo();
        const folderHierarchy = extractFolderHierarchy(sessionData, globalAccountMailboxInfo);
        if (folderHierarchy) {
            if (folderHierarchy.Folders) {
                updateFoldersChildIds(folderHierarchy.Folders, folderHierarchy.RootFolder);
            }
            const data: PrimaryMailFolderHierarchyQuery = {
                folderHierarchy,
            };

            const variables: PrimaryMailFolderHierarchyQueryVariables = {
                baseFolderDistinguishedIds: [PRIMARY_FOLDER_ROOT_DISTINGUISHED_ID],
                offset: 0,
                mailboxInfo: globalAccountMailboxInfo,
                shouldShowNotesFolder: shouldShowFolder('notes', globalAccountMailboxInfo),
                shouldShowConversationHistoryFolder: shouldShowFolder(
                    'conversationhistory',
                    globalAccountMailboxInfo
                ),
                shouldShowOutboxFolder: shouldShowFolder('outbox', globalAccountMailboxInfo),
                doCustomSort: folderHierarchy.CustomSorted,
            };

            writeQuery(PrimaryMailFolderHierarchyDocument, {
                variables,
                data,
            });
        }
    } catch (e) {
        /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
         * The error name (message) must be a string literal (no variables in it).
         *	> Error names can only be a string literals. Use the diagnosticInfo to add custom data. */
        errorThatWillCauseAlert(e);
    }
}
