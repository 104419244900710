import { LazyModule } from 'owa-bundling-light';
import type { OneJs_PersonSourcesViewDataResolvers } from 'owa-graph-schema';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PersonSourcesViewSchemaResolvers" */ './lazyIndex')
);

const lazyPersonSourceCardActionsModel = createLazyResolver(
    'PERSON_SOURCE_CARD_ACTIONS_MODEL',
    lazyModule,
    m => m.personCardActionsModel
);

const lazyPersonSourcesModel = createLazyResolver(
    'PERSON_SOURCES_MODEL',
    lazyModule,
    m => m.personSourcesModel
);

const lazyPersonSourceCardPreviewModel = createLazyResolver(
    'PERSON_SOURCE_CARD_PREVIEW_MODEL',
    lazyModule,
    m => m.personCardPreviewModel
);

const lazyPersonSourcesConfiguration = createLazyResolver(
    'PERSON_SOURCES_CONFIGURATION',
    lazyModule,
    m => m.configuration
);

const lazyPersonHeaderModel = createLazyResolver(
    'PERSON_CARD_HEADER_MODEL',
    lazyModule,
    m => m.personCardHeaderModel
);

export const OneJS_PersonSourcesViewData: OneJs_PersonSourcesViewDataResolvers = {
    personCardActionsModel: lazyPersonSourceCardActionsModel,
    personSourcesModel: lazyPersonSourcesModel,
    personCardPreviewModel: lazyPersonSourceCardPreviewModel,
    configuration: lazyPersonSourcesConfiguration,
    personCardHeaderModel: lazyPersonHeaderModel,
};
