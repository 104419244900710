import type { CoprincipalAccountSource } from 'owa-account-source-list-store';
import { setVersionedCacheValue, tryGetVersionedCacheValue } from './versionedCache';
import {
    type ApplicationSettingsConfig,
    initializeApplicationSettings,
} from 'owa-application-settings';

// Update the version if we change the format of what we store in the cache
export const APPLICATION_SETTINGS_VERSION = 1;
export const APPLICATION_SETTINGS_LOCALSTORAGE_KEY = 'ApplicationSettings';

// Try to initialize feature flags for the account from the cache.
// Return value indicates if we were successful in initializing.
export function tryBootstrapApplicationSettingsForAccount(account: CoprincipalAccountSource) {
    const applicationSettings = tryGetVersionedCacheValue<ApplicationSettingsConfig>(
        account.persistenceId,
        APPLICATION_SETTINGS_LOCALSTORAGE_KEY,
        APPLICATION_SETTINGS_VERSION
    );
    if (applicationSettings) {
        initializeApplicationSettings(applicationSettings, account.mailboxInfo);
        return true;
    }

    return false;
}

export function cacheApplicationSettingsForAccount(
    account: CoprincipalAccountSource,
    applicationSettings: ApplicationSettingsConfig
) {
    setVersionedCacheValue<ApplicationSettingsConfig>(
        account.persistenceId,
        APPLICATION_SETTINGS_LOCALSTORAGE_KEY,
        APPLICATION_SETTINGS_VERSION,
        applicationSettings
    );
}
