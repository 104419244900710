import { logCoreUsage } from 'owa-analytics';

/**
 * List of sources that open Copilot Chat.
 * These are Static strings as they are logged in telemetry and cannot be dynamic due to cardinality.
 * Please add your new entrypoint to this type list.
 */
export type CopilotChatSources =
    | 'SuiteHeaderButton'
    | 'TeachingCallout'
    | 'SearchEmptyMessageListState'
    | 'CopilotSearchSuggestions'
    | 'CopilotSearchContainer'
    | 'SummarizeButtonEntrypoint'
    | 'CopilotCommandCenter'
    | 'UserHighlightingEntrypoint'
    | 'AutoOpenFRE'
    | 'StickyOpen';

export function logCopilotChatEntrypoint(source: CopilotChatSources) {
    logCoreUsage('OutlookCopilotChat_Entrypoints', { source });
}
