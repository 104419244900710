import { type OneGql_MeControlModelResolvers } from 'owa-graph-schema';
import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MeControlViewSchemaResolvers" */ './lazyIndex')
);

const lazyId = createLazyResolver('ME_CONTROL_MODEL_ID', lazyModule, m => m.id);

const lazySignInText = createLazyResolver(
    'ME_CONTROL_MODEL_SIGNINTEXT',
    lazyModule,
    m => m.signInText
);

const lazyDisableTriggerButton = createLazyResolver(
    'ME_CONTROL_MODEL_DISABLETRIGGERBUTTON',
    lazyModule,
    m => m.disableTriggerButton
);

const lazyGenericErrorExistsAriaLabel = createLazyResolver(
    'ME_CONTROL_MODEL_GENERICERROREXISTSARIALABEL',
    lazyModule,
    m => m.genericErrorExistsAriaLabel
);

const lazyTriggerTooltipText = createLazyResolver(
    'ME_CONTROL_MODEL_TRIGGERTOOLTIPTEXT',
    lazyModule,
    m => m.triggerTooltipText
);

const lazyTriggerAriaLabel = createLazyResolver(
    'ME_CONTROL_MODEL_TRIGGERARIALABEL',
    lazyModule,
    m => m.triggerAriaLabel
);

const lazyTriggerWithAvailabilityMtmaAriaLabel = createLazyResolver(
    'ME_CONTROL_MODEL_TRIGGERWITHAVAILABILITYMTMAARIALABEL',
    lazyModule,
    m => m.triggerWithAvailabilityMtmaAriaLabel
);

const lazyTriggerWithAvailabilitySingleAriaLabel = createLazyResolver(
    'ME_CONTROL_MODEL_TRIGGERWITHAVAILABILITYSINGLEARIALABEL',
    lazyModule,
    m => m.triggerWithAvailabilitySingleAriaLabel
);

const lazyHideAllPresence = createLazyResolver(
    'ME_CONTROL_MODEL_HIDEALLPRESENCE',
    lazyModule,
    m => m.hideAllPresence
);

const lazyDropdownRootModel = createLazyResolver(
    'ME_CONTROL_MODEL_DROPDOWNROOT',
    lazyModule,
    m => m.dropdownRootModel
);

const lazyDropdownMenuModel = createLazyResolver(
    'ME_CONTROL_MODEL_DROPDOWNMENU',
    lazyModule,
    m => m.dropdownMenuModel
);

const lazyAccountCardModel = createLazyResolver(
    'ME_CONTROL_MODEL_ACCOUNTCARD',
    lazyModule,
    m => m.accountCardModel
);

const lazyMenuControlsModel = createLazyResolver(
    'ME_CONTROL_MODEL_MENUCONTROLS',
    lazyModule,
    m => m.menuControlsModel
);

const lazyMultiTenantModel = createLazyResolver(
    'ME_CONTROL_MODEL_MULTITENANT',
    lazyModule,
    m => m.multiTenantModel
);

const lazyMultiAccountModel = createLazyResolver(
    'ME_CONTROL_MODEL_MULTIACCOUNT',
    lazyModule,
    m => m.multiAccountModel
);

const lazySetAvailabilityModel = createLazyResolver(
    'ME_CONTROL_MODEL_SETAVAILABILITY',
    lazyModule,
    m => m.setAvailabilityModel
);

const lazySetStatusMessageModel = createLazyResolver(
    'ME_CONTROL_MODEL_SETSTATUSMESSAGE',
    lazyModule,
    m => m.setStatusMessageModel
);

export const OneGQL_MeControlModel: OneGql_MeControlModelResolvers = {
    id: lazyId,
    signInText: lazySignInText,
    disableTriggerButton: lazyDisableTriggerButton,
    genericErrorExistsAriaLabel: lazyGenericErrorExistsAriaLabel,
    triggerTooltipText: lazyTriggerTooltipText,
    triggerAriaLabel: lazyTriggerAriaLabel,
    triggerWithAvailabilityMtmaAriaLabel: lazyTriggerWithAvailabilityMtmaAriaLabel,
    triggerWithAvailabilitySingleAriaLabel: lazyTriggerWithAvailabilitySingleAriaLabel,
    hideAllPresence: lazyHideAllPresence,
    dropdownRoot: lazyDropdownRootModel,
    dropdownMenu: lazyDropdownMenuModel,
    accountCard: lazyAccountCardModel,
    menuControls: lazyMenuControlsModel,
    multiTenant: lazyMultiTenantModel,
    multiAccount: lazyMultiAccountModel,
    setAvailability: lazySetAvailabilityModel,
    setStatusMessage: lazySetStatusMessageModel,
};
