import type { AccountSource } from 'owa-account-source-list-store';
import { AccountSourceType } from 'owa-account-source-list-types';

/**
 * Returns true if the account source is a first party account
 * @param account Account to check
 * @returns True if it is a first party account, false otherwise
 */
export default function isFirstParty(account: AccountSource): boolean {
    return isFirstPartyAccountType(account.sourceType);
}

/**
 * Returns true if the account source type is a first party account
 * @param accountType Account type to check
 * @returns True if it is a first party account, false otherwise
 */
export function isFirstPartyAccountType(accountType: AccountSourceType): boolean {
    switch (accountType) {
        case AccountSourceType.Office365:
        case AccountSourceType.OutlookDotCom:
            return true;

        default:
            return false;
    }
}
