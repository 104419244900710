import type { MailboxInfo } from 'owa-client-types';
import fetchCopilotPrioritizeSettingsFromService from '../services/fetchCopilotPrioritizeSettingsFromService';
import onCopilotPrioritizeSettingsFetched from '../mutators/onCopilotPrioritizeSettingsFetched';
import isCopilotPrioritizeSettingsInitialized from '../selectors/isCopilotPrioritizeSettingsInitialized';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';

export default async function fetchCopilotPrioritizeSettings(
    mailboxInfo: MailboxInfo,
    forceRefresh: boolean = false
): Promise<boolean> {
    if (!isCopilotFeatureEnabled('Inbox', mailboxInfo, true /* skipLanguageCheck */)) {
        return Promise.resolve(true);
    }

    if (isCopilotPrioritizeSettingsInitialized(mailboxInfo) && !forceRefresh) {
        return Promise.resolve(true);
    }

    const response = await fetchCopilotPrioritizeSettingsFromService(mailboxInfo);
    if (response) {
        onCopilotPrioritizeSettingsFetched(mailboxInfo, response);
    }

    return Promise.resolve(true);
}
