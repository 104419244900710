import type { ResolverContext, GraphQLResolveInfo, SampleResult } from 'owa-graph-schema';

let counter = 0;

/**
 * Sample web resolver
 */
export const sampleWeb = async (
    _parent: {},
    _args: {},
    _context: ResolverContext,
    _info: GraphQLResolveInfo
): Promise<SampleResult> => {
    return {
        id: 1,
        svalue: `<3 from web ${counter++}`,
        __typename: 'SampleResult',
        inSample: { inValue: 'inValue' },
    };
};
