import type {
    RecurrencePatternType,
    RecurrencePatternInput,
    RecurrenceRangeType,
    RecurrenceRangeInput,
    RecurrenceType,
    RecurrenceInput,
} from 'owa-graph-schema';

function recurrencePattern(pattern: RecurrencePatternType): RecurrencePatternInput {
    switch (pattern.__typename) {
        case 'AbsoluteYearlyRecurrence': {
            return {
                RecurrencePatternTypename: 'AbsoluteYearlyRecurrence',
                DayOfMonth: pattern.DayOfMonth,
                Month: pattern.Month,
            };
        }
        case 'AbsoluteMonthlyRecurrence': {
            return {
                RecurrencePatternTypename: 'AbsoluteMonthlyRecurrence',
                DayOfMonth: pattern.DayOfMonth,
                Interval: pattern.Interval,
            };
        }
        case 'WeeklyRecurrence': {
            return {
                RecurrencePatternTypename: 'WeeklyRecurrence',
                Interval: pattern.Interval,
                DaysOfWeek: pattern.DaysOfWeek,
                FirstDayOfWeek: pattern.FirstDayOfWeek,
            };
        }
        case 'DailyRecurrence': {
            return {
                RecurrencePatternTypename: 'DailyRecurrence',
                Interval: pattern.Interval,
            };
        }
        case 'RegeneratingPatternBaseType': {
            return {
                RecurrencePatternTypename: 'RegeneratingPatternBaseType',
                Interval: pattern.Interval,
            };
        }
        case 'RelativeYearlyRecurrence': {
            return {
                RecurrencePatternTypename: 'RelativeYearlyRecurrence',
                DaysOfWeek: pattern.DaysOfWeek,
                DayOfWeekIndex: pattern.DayOfWeekIndex,
                Month: pattern.Month,
            };
        }
        case 'RelativeMonthlyRecurrence': {
            return {
                RecurrencePatternTypename: 'RelativeMonthlyRecurrence',
                Interval: pattern.Interval,
                DaysOfWeek: pattern.DaysOfWeek,
                DayOfWeekIndex: pattern.DayOfWeekIndex,
            };
        }
        default:
            throw new Error('unhandled recurrence pattern');
    }
}

/**
 * Maps from the union of supported subtypes from the Schema to the known implementers of the
 * OWS base class RecurrenceRangeBaseType
 *
 * See the docstring on recurrencePattern() for details
 */
function recurrenceRange(range: RecurrenceRangeType): RecurrenceRangeInput {
    switch (range.__typename) {
        case 'EndDateRecurrence':
            return {
                RecurrenceRangeTypename: 'EndDateRecurrence',
                StartDate: range.StartDate,
                EndDate: range.EndDate,
            };
        case 'NoEndRecurrence':
            return {
                RecurrenceRangeTypename: 'NoEndRecurrence',
                StartDate: range.StartDate,
            };
        case 'NumberedRecurrence':
            return {
                RecurrenceRangeTypename: 'NumberedRecurrence',
                StartDate: range.StartDate,
                NumberOfOccurrences: range.NumberOfOccurrences,
            };
        default:
            throw new Error('unhandled recurrence range');
    }
}

export function convertRecurrenceGqlTypeToGqlInput(
    recurrenceType: RecurrenceType
): RecurrenceInput {
    return {
        RecurrencePattern: recurrencePattern(recurrenceType.RecurrencePattern),
        RecurrenceRange: recurrenceRange(recurrenceType.RecurrenceRange),
    };
}
