import type { ModernImageTheme, ModernColorTheme, CopilotTheme } from 'owa-theme-shared';
import { ThemeConstants } from 'owa-theme-shared';

const modernImageThemes: {
    [key in ModernImageTheme]: string;
} = {
    alpineglow: 'alpineglow',
    arcticsolitude: ThemeConstants.MODERN_ARCTIC_SOLITUDE_THEME_ID,
    natureundefined: 'natureundefined',
    readyconfetti: 'readyconfetti',
    magneticmood: 'magneticmood',
    futureplus: 'futureplus',
    ribbonrelease: 'ribbonrelease',
    rubyhills: 'rubyhills',
    marigoldhills: 'marigoldhills',
    summersummit: 'summersummit',
};

const modernColorThemes: {
    [key in ModernColorTheme]: string;
} = {
    modern_officeneutral: ThemeConstants.MODERN_OFFICE_NEUTRAL_THEME_ID,
    neworange: 'neworange',
    red: 'red',
    green: 'green',
    purple: 'purple',
    newpink: 'newpink',
};

const copilotThemes: {
    [key in CopilotTheme]: string;
} = {
    copilotcustom: ThemeConstants.COPILOT_CUSTOM_THEME_ID,
};

export const getModernThemes = () => [...getModernImageThemes(), ...getModernColorThemes()];

export const getModernImageThemes = (): string[] => Object.values(modernImageThemes);

export const getModernColorThemes = (): string[] => Object.values(modernColorThemes);

export const getCopilotThemes = (): string[] => Object.values(copilotThemes);
