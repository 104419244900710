import onRemoveProjection from '../actions/onRemoveProjection';
import { getStore } from '../store/parentStore';
import { mutatorAction } from 'satcheljs';

export default function removeProjection(window: Window) {
    const store = getStore();

    for (let i = 0; i < store.projections.length; i++) {
        if (store.projections[i].window == window) {
            onRemoveProjection(store.projections[i].tabId);
            removeProjectionFromStore(i);
            break;
        }
    }
}

const removeProjectionFromStore = mutatorAction(
    'removeProjectionFromStore',
    (projectionIndexToRemove: number) => {
        const store = getStore();
        store.projections.splice(projectionIndexToRemove, 1);
    }
);
