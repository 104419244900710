import { LazyAction, LazyBootModule } from 'owa-bundling-light';

export const lazyModule = new LazyBootModule(
    () => import(/* webpackChunkName: "PlatformAppBoot"*/ './lazyIndex')
);

export const lazyGetOwaAuthTokenFromMetaOsHub = new LazyAction(
    lazyModule,
    m => m.getOwaAuthTokenFromMetaOsHub
);

export const lazyGetOwaDecodedAuthTokenFromMetaOsHub = new LazyAction(
    lazyModule,
    m => m.getOwaDecodedAuthTokenFromMetaOsHub
);

export const lazyGetAuthTokenFromMetaOsHub = new LazyAction(
    lazyModule,
    m => m.getAuthTokenFromMetaOsHub
);

export const lazyOpenCalendarItem = new LazyAction(lazyModule, m => m.openCalendarItem);

export const lazyComposeMeeting = new LazyAction(lazyModule, m => m.composeMeeting);

export const lazyOpenProfileItem = new LazyAction(lazyModule, m => m.openProfileItem);

export const lazyOpenMailItem = new LazyAction(lazyModule, m => m.openMailItem);

export const lazyComposeMail = new LazyAction(lazyModule, m => m.composeMail);
export const lazyReplyMail = new LazyAction(lazyModule, m => m.replyMail);
export const lazyReplyAllMail = new LazyAction(lazyModule, m => m.replyAllMail);
export const lazyForwardMail = new LazyAction(lazyModule, m => m.forwardMail);

export const lazyExecuteDeepLink = new LazyAction(lazyModule, m => m.executeDeepLink);

export const lazyGetContext = new LazyAction(lazyModule, m => m.getContext);

export const lazyCheckCalendarCapability = new LazyAction(
    lazyModule,
    m => m.checkCalendarCapability
);

export const lazyCheckMailCapability = new LazyAction(lazyModule, m => m.checkMailCapability);

export const lazyCheckDialogCapability = new LazyAction(lazyModule, m => m.checkDialogCapability);

export const lazyIsDarkTheme = new LazyAction(lazyModule, m => m.isDarkTheme);

export const lazyJoinTeamsMeeting = new LazyAction(lazyModule, m => m.joinTeamsMeeting);

export const lazyModalOpened = new LazyAction(lazyModule, m => m.modalOpened);

export const lazySubmitTask = new LazyAction(lazyModule, m => m.submitTask);

export const lazyNotifySuccess = new LazyAction(lazyModule, m => m.notifySuccess);

export const lazyNotifyFailure = new LazyAction(lazyModule, m => m.notifyFailure);
export const lazyNotifyOtherFailure = new LazyAction(lazyModule, m => m.notifyOtherFailure);

export const lazyNotifyAppLoaded = new LazyAction(lazyModule, m => m.notifyAppLoaded);

export const lazyRegisterIsDarkThemeOnChangeHandler = new LazyAction(
    lazyModule,
    m => m.registerIsDarkThemeOnChangeHandler
);

export const lazyInitializeMetaOsCapabilities = new LazyAction(
    lazyModule,
    m => m.initializeMetaOsCapabilities
);

export const lazyHandleThemeChange = new LazyAction(lazyModule, m => m.handleThemeChange);

export const lazyIsMetaOsAppSdkInitialized = new LazyAction(
    lazyModule,
    m => m.isMetaOsAppSdkInitialized
);

export const lazyInitializeMetaOsAppSdk = new LazyAction(lazyModule, m => m.initializeMetaOsAppSdk);

export const lazyRegisterOnLoadHandler = new LazyAction(lazyModule, m => m.registerOnLoadHandler);
export const lazyRegisterOnCachedLoadCompleted = new LazyAction(
    lazyModule,
    m => m.registerOnCachedLoadCompleted
);
export const lazyRegisterBeforeUnloadHandler = new LazyAction(
    lazyModule,
    m => m.registerBeforeUnloadHandler
);

export const lazyStartCall = new LazyAction(lazyModule, m => m.startCall);
export const lazyOpenChat = new LazyAction(lazyModule, m => m.openChat);
export const lazyOpenGroupChat = new LazyAction(lazyModule, m => m.openGroupChat);
export const lazyOpenDialog = new LazyAction(lazyModule, m => m.open);
export const lazyCloseDialog = new LazyAction(lazyModule, m => m.close);
export const lazyPreInitOwaAccountSourceListStore = new LazyAction(
    lazyModule,
    m => m.preInitOwaAccountSourceListStore
);
export const lazyGetAccountServiceConfig = new LazyAction(
    lazyModule,
    m => m.getAccountServiceConfig
);
export const lazyNavigateToApp = new LazyAction(lazyModule, m => m.navigateToApp);
export const lazyJoinMeeting = new LazyAction(lazyModule, m => m.joinMeeting);
export const lazyGetCachedContext = new LazyAction(lazyModule, m => m.getCachedContext);
export const lazyIsNAAChannelRecommended = new LazyAction(
    lazyModule,
    m => m.isNAAChannelRecommended
);
export const lazyOpenStageView = new LazyAction(lazyModule, m => m.openStageView);
export const lazyCloseStageView = new LazyAction(lazyModule, m => m.closeStageView);
export const lazyCheckStageViewCapability = new LazyAction(
    lazyModule,
    m => m.checkStageViewCapability
);

export const lazyRemoveTab = new LazyAction(lazyModule, m => m.removeTab);
export const lazyOpenCreateTabFlyout = new LazyAction(lazyModule, m => m.openCreateTabFlyout);
