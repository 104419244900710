import type CalendarEvent from 'owa-calendar-types/lib/types/CalendarEvent';
import getCoordinatesForMeetingOptions from './getCoordinatesForMeetingOptions';

const extractCoordinatesForMeetingOptions = (event: CalendarEvent | null) => {
    const result = getCoordinatesForMeetingOptions(event);
    if (!result) {
        return null;
    }

    const searchParams = new URLSearchParams(result);
    const tenantId = searchParams.get('tenantId') || '';
    const organizerId = searchParams.get('organizerId') || '';
    const threadId = searchParams.get('threadId') || '';
    const messageId = searchParams.get('messageId') || '';
    const additionalMessageId = searchParams.get('additionalMessageId') || '';

    return { tenantId, organizerId, threadId, messageId, additionalMessageId };
};

export default extractCoordinatesForMeetingOptions;
