import type { CopilotThemeOptions } from 'owa-copilot-themes-types';
import setThemeOptionsToOcs from '../utils/setThemeOptionsToOcs';
import getThemeOptionsFromOcs from '../utils/getThemeOptionsFromOcs';
import updateThemeOptions from '../mutators/updateThemeOptions';

export default async function updateCopilotThemeOptions(copilotThemeData: CopilotThemeOptions) {
    await setThemeOptionsToOcs(copilotThemeData);

    const themeOptionsSetting = await getThemeOptionsFromOcs();
    const timestamp = themeOptionsSetting?.Timestamp || '';

    updateThemeOptions(copilotThemeData, timestamp);
}
