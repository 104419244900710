import CategoryWell from 'owa-categories/lib/components/CategoryWell';
import type { MailListItemFullThreeColumnProps } from './MailListItemComponentProps';
import onCategoryClicked from '../utils/onCategoryClicked';
import renderReactions from '../utils/renderReactions';
import isValidEmailAddress from '../utils/isValidEmailAddress';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { isFeatureEnabled } from 'owa-feature-flags';
import loc from 'owa-localize';
import {
    onMailListItemClickHandler,
    useMailListItemContainerContext,
} from 'owa-mail-list-item-shared';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import getListViewState from 'owa-mail-list-store/lib/selectors/getListViewState';
import {
    full,
    medium,
    compact,
    reactionsContainer,
    containerForTwistyAndCheckboxSCL,
    containerPaddingCompact,
    containerPaddingNoCheckboxFull,
    containerPaddingNoCheckboxMedium,
    multiSelectNoTwistyDisplayed,
    secondRowThreeColumnUnread,
    secondRowThreeColumn,
    secondRowThreeColumnPaddingNext,
    conversationHeaderContent,
    categoryContainerThreeColumn,
    categoryContainerThreeColumnFull,
    categoryContainerThreeColumnMediumOrCompact,
    sclContainer,
    firstColumnThreeColumn,
    firstColumnThreeColumnNoTwisty,
    column,
    multiSelectNoCheckbox,
    secondRowThreeColumnRead,
    secondLineTextThreeColumn,
    categoryWellThreeColumn,
    previewDisplayThreeColumn,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';
import {
    firstRowThreeColumnUnreadNext,
    firstRowThreeColumnNext,
} from 'owa-mail-listitem-styles/lib/scss/ListViewItemCommonStyles.scss';
import {
    previewDisplayThreeColumnCategories,
    threeColFolderTag,
} from './MailListItemFullThreeColumn.scss';
import { SenderGroupingsActionButton } from 'owa-sender-groupings-button';
import { useMessageListTextStyle } from 'owa-mail-listitem-styles/lib/utils/useMessageListTextStyle';
import mailStore from 'owa-mail-store/lib/store/Store';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import type { TableView, MailFolderTableQuery } from 'owa-mail-list-store';
import { type SortColumn } from 'owa-mail-list-store/lib/store/schema/SortBy';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type SingleRecipientType from 'owa-service/lib/contract/SingleRecipientType';
import isSenderImageEnabled from 'owa-sender-image-option/lib/utils/isSenderImageEnabled';
import React from 'react';
import {
    MailListItemFirstLineText,
    MailListItemIconBar,
    MailListItemLabelsContainer,
    MailListItemLineText,
    MailListItemRichPreviewWell,
    MailListItemTimestamp,
    MailListItemTwistyButton,
    MailListItemUnreadCount,
    PersonaCheckbox,
    FolderTag,
    MailListItemMeetingInfo,
    MailListItemPreviewDisplay,
    MailListItemExternalMarker,
    MailListItemUnverifiedMarker,
    MailListItemUnreadBar,
} from 'owa-mail-list-item-components';
import { senderEmailNotFoundLabel } from './MailListItem.locstring.json';
import { SuggestedUnsubscribe } from 'owa-suggested-unsubscribe-peek';
import { getDensityModeCssClass, getDensityModeString } from 'owa-fabric-theme';
import { CopilotInboxPriorityIcon } from 'owa-mail-copilot-inbox-shared';

export default observer(function MailListItemFullThreeColumn(
    props: MailListItemFullThreeColumnProps
): JSX.Element {
    const {
        canArchive,
        canDelete,
        canPin,
        categories,
        conversationId,
        copilotInboxScore,
        effectiveMentioned,
        firstLineText,
        firstLineTooltipText,
        hasAttachment,
        hasSharepointLink,
        highlightTerms,
        importance,
        isComplete,
        isFirstLevelExpanded,
        isFlagged,
        isInArchiveFolder,
        isInNotesFolder,
        isInOutboxFolder,
        isInJunkFolder,
        isLoadingSecondLevelExpansion,
        isPinned,
        isSearchTable,
        isSecondLevelExpanded,
        isSelected,
        isSnoozed,
        isTaggedForBigScreen,
        isUnauthenticatedSender,
        itemClassIcon,
        lastDeliveryTimestamp,
        lastSender,
        latestItemId,
        listViewType,
        mailboxGuids,
        parentFolderDisplayName,
        parentFolderId,
        returnTime,
        rowId,
        rowKey,
        secondLineText,
        secondLineTooltipText,
        shouldShowExternalLabel,
        shouldShowRSVP,
        shouldShowTwisty,
        shouldShowSenderGroupingsButton,
        shouldSuggestUnsubscribe,
        showAttachmentPreview,
        showDraftsIndicator,
        showFolderTag,
        showPreviewText,
        smimeType,
        styleSelectorAsPerUserSettings,
        supportsFlagging,
        supportsHoverIcons,
        supportsMove,
        supportsPinning,
        tableViewId,
        thirdLineText,
        unreadCount,
        isScheduledSend,
    } = props;

    const tableView: TableView = getListViewStore().tableViews.get(tableViewId) as TableView;

    const mailTableQuery = tableView?.tableQuery as MailFolderTableQuery;
    const isCategorySort = mailTableQuery?.sortBy?.sortColumn === 14;
    const isPrioritySort = mailTableQuery?.sortBy?.sortColumn === 16;

    const densityModeString = getDensityModeCssClass(full, medium, compact);
    // derived properties based on props/flights
    const isUnread = unreadCount > 0;
    const showCategories = categories?.length > 0 && !isCategorySort;
    const showCategoriesInline =
        shouldShowUnstackedReadingPane() && isFirstLevelExpanded && !isCategorySort;
    const notExpanded =
        !isFirstLevelExpanded && !isSecondLevelExpanded && !isLoadingSecondLevelExpansion;
    const showPreviews = !shouldShowUnstackedReadingPane() || notExpanded;
    const showMultiSelectButton = !isSearchTable;
    const senderImageEnabled = isSenderImageEnabled();

    const showCheckbox =
        !showMultiSelectButton ||
        (showMultiSelectButton &&
            (senderImageEnabled || tableView?.isInSelectAllState || tableView?.isInCheckedMode));
    const mailboxInfo = getMailboxInfo(tableView);
    const isConversationView = tableView?.tableQuery.listViewType === 0;

    const { conditionalFormattingModifications } = useMailListItemContainerContext();

    const isLoadingExpansion = useComputedValue((): boolean => {
        const expansionState = getListViewState().expandedConversationViewState;
        const conversationItemParts = mailStore.conversations.get(conversationId);
        return !!(
            (isFirstLevelExpanded || isSecondLevelExpanded) &&
            (expansionState.forks?.length === 0 ||
                (conversationItemParts && conversationItemParts.loadingState.isLoading))
        );
    }, [isFirstLevelExpanded, isSecondLevelExpanded, conversationId]);

    /**
     * Categories and folder tags are shown on the same line as preview text. When
     * preview text is not shown, additional container is used to display categories and folder tags.
     */
    const showLabelsContainer = !showPreviewText && (showCategories || showFolderTag);

    // To ensure that preview text is not displayed for Newest at the top/bottom setting with conversation expansion
    const showPreviewTextConditionally = showPreviewText && notExpanded;

    const personaCheckboxComponent = (
        <PersonaCheckbox
            tableViewId={tableViewId}
            isSelected={isSelected}
            lastSender={lastSender}
            rowKey={rowKey}
            lastDeliveryTimestamp={lastDeliveryTimestamp}
            isUnauthenticatedSender={isUnauthenticatedSender}
        />
    );

    const twistyComponent = (
        <MailListItemTwistyButton
            conditionalFormattingModifications={conditionalFormattingModifications}
            isLoadingExpansion={isLoadingExpansion}
            isFirstLevelExpanded={isFirstLevelExpanded}
            isSecondLevelExpanded={isSecondLevelExpanded}
            isLoadingSecondLevelExpansion={isLoadingSecondLevelExpansion}
            isSelected={isSelected}
            isSingleLine={false}
            isUnread={unreadCount > 0}
            rowKey={rowKey}
            showCondensedView={false}
            styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
            tableViewId={tableViewId}
        />
    );

    const renderFirstLineTextComponent = (text: string, tooltipText: string) => {
        return (
            <MailListItemFirstLineText
                highlightTerms={highlightTerms}
                showDraftsIndicator={showDraftsIndicator}
                isInOutboxFolder={isInOutboxFolder}
                unreadCount={unreadCount}
                text={text}
                tooltipText={tooltipText}
                highlightAccuracy={tableView?.highlightAccuracy}
                isScheduledSend={isScheduledSend}
            />
        );
    };

    const renderSenderEmailLineTextComponent = (sender: SingleRecipientType) => {
        const senderText: string =
            !sender.Mailbox?.EmailAddress ||
            !isValidEmailAddress(sender.Mailbox?.EmailAddress ?? '')
                ? `<${loc(senderEmailNotFoundLabel)}>`
                : `<${sender.Mailbox.EmailAddress}>`;

        return (
            <MailListItemFirstLineText
                highlightTerms={highlightTerms}
                showDraftsIndicator={showDraftsIndicator}
                isInOutboxFolder={isInOutboxFolder}
                unreadCount={unreadCount}
                text={senderText}
                tooltipText={sender.Mailbox?.EmailAddress ?? ''}
                highlightAccuracy={tableView?.highlightAccuracy}
                isScheduledSend={isScheduledSend}
            />
        );
    };

    const renderCategoryWellComponent = (
        categoryWellContainerClass: string,
        categoryContainerClass: string
    ): JSX.Element => {
        return (
            <CategoryWell
                key={rowId}
                categoryWellContainerClass={categoryWellContainerClass}
                categoryContainerClass={categoryContainerClass}
                categories={categories}
                onCategoryClicked={onCategoryClicked}
                mailboxInfo={mailboxInfo}
            />
        );
    };

    const iconBarComponent = (
        <MailListItemIconBar
            canArchive={canArchive}
            canDelete={canDelete}
            canPin={canPin}
            effectiveMentioned={effectiveMentioned}
            hasAttachment={hasAttachment}
            hasSharepointLink={hasSharepointLink}
            importance={importance}
            isComplete={isComplete}
            isFlagged={isFlagged}
            isInArchiveFolder={isInArchiveFolder}
            isInNotesFolder={isInNotesFolder}
            isInOutboxFolder={isInOutboxFolder}
            isPinned={isPinned}
            isRowExpanded={isFirstLevelExpanded || isSecondLevelExpanded}
            isSingleLine={false}
            isSnoozed={isSnoozed}
            isTaggedForBigScreen={isTaggedForBigScreen}
            itemClassIcon={itemClassIcon}
            lastDeliveryTimestamp={lastDeliveryTimestamp}
            neverShowHoverIcons={!supportsHoverIcons}
            neverShowPropertyIcons={false}
            rowKey={rowKey}
            smimeType={smimeType}
            supportsFlagging={supportsFlagging}
            supportsMove={supportsMove}
            supportsPinning={supportsPinning}
            tableViewId={tableViewId}
            unreadCount={unreadCount}
        />
    );

    const _renderReactions = () => {
        if (!notExpanded) {
            return null;
        }

        return renderReactions({
            latestItemId,
            reactionsContainerClassName: reactionsContainer,
            rowId,
            tableViewId,
        });
    };

    const onPreviewClick = React.useCallback(
        (evt: React.MouseEvent<unknown>) => {
            onMailListItemClickHandler(
                evt,
                MailListItemSelectionSource.MailListItemRichPreview,
                rowKey,
                tableViewId
            );
        },
        [rowKey, tableViewId]
    );

    const unreadBar = React.useMemo(() => {
        return (
            <MailListItemUnreadBar
                conditionalFormattingModifications={conditionalFormattingModifications}
                isExpanded={isFirstLevelExpanded || isSecondLevelExpanded}
                isUnread={isUnread}
                rowKey={rowKey}
                tableViewId={tableViewId}
            />
        );
    }, [
        isFirstLevelExpanded,
        isSecondLevelExpanded,
        isUnread,
        rowKey,
        tableViewId,
        conditionalFormattingModifications,
    ]);

    let firstRowStyle;
    if (isUnread) {
        firstRowStyle = firstRowThreeColumnUnreadNext;
    } else {
        firstRowStyle = firstRowThreeColumnNext;
    }

    return (
        <>
            {unreadBar}
            <div className={classnames(densityModeString, sclContainer)}>
                <div
                    className={classnames(
                        firstColumnThreeColumn,
                        isConversationView && !shouldShowTwisty && firstColumnThreeColumnNoTwisty,
                        containerForTwistyAndCheckboxSCL,
                        getDensityModeString() === 'compact' && containerPaddingCompact,
                        !showCheckbox &&
                            getDensityModeCssClass(
                                containerPaddingNoCheckboxFull,
                                containerPaddingNoCheckboxMedium,
                                undefined
                            ),
                        !shouldShowTwisty &&
                            showMultiSelectButton &&
                            !senderImageEnabled &&
                            !showCheckbox &&
                            multiSelectNoTwistyDisplayed
                    )}
                >
                    {shouldShowTwisty && twistyComponent}
                    {showCheckbox && personaCheckboxComponent}
                </div>
                <div className={classnames(column, !showCheckbox && multiSelectNoCheckbox)}>
                    <div className={classnames(firstRowStyle, densityModeString)}>
                        {lastSender && shouldShowSenderGroupingsButton && (
                            <SenderGroupingsActionButton
                                lastSender={lastSender}
                                mailboxInfo={mailboxInfo}
                            />
                        )}
                        {shouldShowExternalLabel && (
                            <MailListItemExternalMarker isSelected={isSelected} />
                        )}
                        {!shouldShowExternalLabel && isUnauthenticatedSender && (
                            <MailListItemUnverifiedMarker />
                        )}
                        {renderFirstLineTextComponent(firstLineText, firstLineTooltipText ?? '')}
                        {iconBarComponent}
                    </div>
                    {isInJunkFolder && lastSender && !isValidEmailAddress(firstLineText) ? (
                        <div>{renderSenderEmailLineTextComponent(lastSender)}</div>
                    ) : null}
                    <div
                        className={classnames(
                            isUnread ? secondRowThreeColumnUnread : secondRowThreeColumn,
                            !isUnread && secondRowThreeColumnRead,
                            secondRowThreeColumnPaddingNext
                        )}
                    >
                        <div
                            className={classnames(
                                secondLineTextThreeColumn,
                                densityModeString,
                                useMessageListTextStyle('Major'),
                                conversationHeaderContent
                            )}
                        >
                            {!showPreviewText && (
                                <CopilotInboxPriorityIcon
                                    copilotInboxScore={copilotInboxScore}
                                    scenario={isPrioritySort ? 'PrioritySort' : 'ML'}
                                    mailboxInfo={mailboxInfo}
                                    isUnread={isUnread}
                                />
                            )}
                            {showCategories &&
                                showCategoriesInline &&
                                renderCategoryWellComponent(
                                    categoryWellThreeColumn,
                                    categoryContainerThreeColumn
                                )}
                            <MailListItemLineText
                                addOverflowStyles={true}
                                highlightTerms={highlightTerms}
                                highlightAccuracy={tableView?.highlightAccuracy}
                                text={secondLineText}
                                tooltipText={secondLineTooltipText ?? ''}
                                conditionalFormattingModifications={
                                    conditionalFormattingModifications
                                }
                            />
                        </div>
                        <MailListItemUnreadCount
                            unreadCount={unreadCount}
                            conditionalFormattingModifications={conditionalFormattingModifications}
                        />
                        {isFeatureEnabled('tri-message-list-reactions') &&
                            !showPreviewText &&
                            _renderReactions()}
                        {isTaggedForBigScreen ? (
                            <></> // TODO: Update this after get the proper message to display.
                        ) : returnTime ? (
                            <MailListItemTimestamp
                                conditionalFormattingModifications={
                                    conditionalFormattingModifications
                                }
                                isPinned={isPinned}
                                isSingleLine={false}
                                isSnoozed={true}
                                returnTime={returnTime}
                                supportsPinning={supportsPinning}
                            />
                        ) : (
                            !isFirstLevelExpanded &&
                            !isSecondLevelExpanded && (
                                <MailListItemTimestamp
                                    conditionalFormattingModifications={
                                        conditionalFormattingModifications
                                    }
                                    isPinned={isPinned}
                                    isSingleLine={false}
                                    isUnread={isUnread}
                                    lastDeliveryTimestamp={lastDeliveryTimestamp}
                                    returnTime={returnTime ?? ''}
                                    supportsPinning={supportsPinning}
                                />
                            )
                        )}
                    </div>
                    {showPreviewTextConditionally && (
                        <div className={classnames(previewDisplayThreeColumn, densityModeString)}>
                            <div className={classnames(previewDisplayThreeColumnCategories)}>
                                {showPreviewText && (
                                    <CopilotInboxPriorityIcon
                                        copilotInboxScore={copilotInboxScore}
                                        scenario={isPrioritySort ? 'PrioritySort' : 'ML'}
                                        mailboxInfo={mailboxInfo}
                                        isUnread={isUnread}
                                    />
                                )}
                                {showCategories &&
                                    !showCategoriesInline &&
                                    renderCategoryWellComponent(
                                        categoryWellThreeColumn,
                                        getDensityModeCssClass(
                                            categoryContainerThreeColumnFull,
                                            categoryContainerThreeColumnMediumOrCompact,
                                            categoryContainerThreeColumnMediumOrCompact
                                        )
                                    )}
                                {showPreviews && (
                                    <MailListItemPreviewDisplay
                                        highlightTerms={highlightTerms}
                                        highlightAccuracy={tableView?.highlightAccuracy}
                                        previewDisplay={thirdLineText}
                                    />
                                )}
                            </div>
                            {showFolderTag && (
                                <div className={threeColFolderTag}>
                                    <FolderTag
                                        folderId={parentFolderId}
                                        parentFolderDisplayName={parentFolderDisplayName}
                                        isPreviewTextOn={showPreviewText}
                                        isSingleLine={false}
                                        mailboxGuids={mailboxGuids}
                                        shouldBeInTabOrder={isSelected}
                                    />
                                </div>
                            )}
                            {isFeatureEnabled('tri-message-list-reactions') && _renderReactions()}
                        </div>
                    )}
                    {showLabelsContainer && (
                        <MailListItemLabelsContainer
                            categories={categories}
                            categoryContainerClass={categoryContainerThreeColumn}
                            categoryWellContainerClass={categoryWellThreeColumn}
                            isSingleLine={false}
                            mailboxGuids={mailboxGuids}
                            parentFolderDisplayName={parentFolderDisplayName}
                            parentFolderId={parentFolderId}
                            rowId={rowId}
                            showCategories={showCategories && notExpanded}
                            showFolderTag={showFolderTag}
                            showPreviewText={showPreviewText}
                            mailboxInfo={mailboxInfo}
                        />
                    )}
                    {showPreviews && showAttachmentPreview && (
                        <MailListItemRichPreviewWell
                            isWideView={false}
                            onPreviewClick={onPreviewClick}
                            rowId={rowId}
                            tableViewId={tableViewId}
                        />
                    )}
                    {shouldShowRSVP && (
                        <MailListItemMeetingInfo
                            conversationId={conversationId}
                            isSelected={isSelected}
                            isSingleLineView={false}
                            itemClassIcon={itemClassIcon}
                            latestItemId={latestItemId}
                            listViewType={listViewType}
                            tableViewId={tableViewId}
                        />
                    )}
                    {shouldSuggestUnsubscribe && (
                        <SuggestedUnsubscribe
                            isSingleLineView={false}
                            isSelected={isSelected}
                            rowId={rowId}
                            tableView={tableView}
                        />
                    )}
                </div>
            </div>
        </>
    );
},
'MailListItemFullThreeColumn');
