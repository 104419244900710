import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PersonSourcesDeleteMutation" */ './lazyIndex')
);

export const lazyPersonSourcesDeleteMutationIdb = createLazyResolver(
    'PERSON_SOURCES_DELETE_MUTATION_IDB',
    lazyModule,
    m => m.deletePersonSourcesIdbResolver,
    'NoFallback' /* fallbackBehavior */
);

export const lazyPersonSourcesDeleteMutationWeb = createLazyResolver(
    'PERSON_SOURCES_DELETE_MUTATION_WEB',
    lazyModule,
    m => m.deletePersonSourcesWebResolver
);
