import type { LivePersonaEditorEvent as LivePersonaEditorInternalEvent } from '@1js/lpe-hostapp-loader';
import type { PersonSourceEvent as PersonSourcesInternalEvent } from '@1js/person-sources-hostapp-loader';
import type { LinkedEntity, NovaEvent } from '@nova/types';
import { AppBarEventTypes } from '@office-start/appbar-events';
import type {
    AppAcquisitionLinkClickEvent,
    LaunchTileEventOriginator,
    TileUninstallEvent,
    TileLaunchEvent,
    TileLaunchEventData,
    TileMoreInfoEvent,
    FlyoutButtonHoverEvent,
    FlyoutOpenedEvent,
    AppBarImpressionEvent,
    AppBarRenderErrorEvent,
    ContentDroppedEvent,
} from '@office-start/appbar-events';
import type {
    StartCallViaSipProtocolEvent,
    StartChatViaSipProtocolEvent,
    ImportContactsEvent,
    ExportContactsEvent,
    ComposeEmailEvent,
    OpenLPCEvent,
    OpenLivePersonaEditorEvent,
    OpenPersonSourcesEvent,
    InitializeLPCEvent,
    ShowToastEvent,
    RefreshPersonInfoEvent,
} from 'owa-app-host-events';
import { AppHostServiceEventTypes } from 'owa-app-host-events';
import type { MailboxInfo } from 'owa-client-types';
import type { PXNovaTelemetryEvent } from '@1js/midgard-types';

/**
 * Extend OWA-specific Nova event types.
 */
// MessageExtensions apps  launch tile event
type TileMessageExtensionLaunchEventData = TileLaunchEventData & {
    launchContext: LinkedEntity;
};
export interface TileMessageExtensionLaunchEvent
    extends NovaEvent<TileMessageExtensionLaunchEventData> {
    originator: typeof LaunchTileEventOriginator;
    type: typeof AppBarEventTypes.launch;
    data?: () => TileMessageExtensionLaunchEventData;
}

// SettingsPage context menu item open.
// Can either hold serialized (from Nova -> owa-nova-actions) or de-serialized data (from owa-nova-actions -> OWA)
type SettingsContextMenuEventData = {
    appId: string;
    mailboxInfo?: MailboxInfo;
};
export interface SettingsContextMenuEvent extends NovaEvent<SettingsContextMenuEventData> {
    originator: 'ContextMenu';
    type: 'settings';
    data?: () => SettingsContextMenuEventData;
}

//
type PinTaskbarShortcutEventData = {
    appId: string;
};
export interface PinTaskbarShortcutEvent extends NovaEvent<PinTaskbarShortcutEventData> {
    originator: 'ContextMenu';
    type: 'pinTaskbarShortcut';
    data?: () => PinTaskbarShortcutEventData;
}

export type PinMessageExtensionEventData = {
    appId: string;
};
export interface PinMessageExtensionEvent extends NovaEvent<PinMessageExtensionEventData> {
    originator: 'ContextMenu';
    type: 'pinMessageExtension';
    data?: () => PinMessageExtensionEventData;
}

/**
 * Union of all Nova's Events.
 */
type NovaEventUnion =
    | AppAcquisitionLinkClickEvent
    | SettingsContextMenuEvent
    | PinTaskbarShortcutEvent
    | TileLaunchEvent
    | TileMoreInfoEvent
    | TileUninstallEvent
    | AppBarRenderErrorEvent
    | AppBarImpressionEvent
    | FlyoutButtonHoverEvent
    | FlyoutOpenedEvent
    | OpenLPCEvent
    | InitializeLPCEvent
    | PinMessageExtensionEvent
    | PXNovaTelemetryEvent
    | LivePersonaEditorInternalEvent
    | OpenLivePersonaEditorEvent
    | PersonSourcesInternalEvent
    | OpenPersonSourcesEvent
    | ComposeEmailEvent
    | ImportContactsEvent
    | ExportContactsEvent
    | StartCallViaSipProtocolEvent
    | StartChatViaSipProtocolEvent
    | ShowToastEvent
    | ContentDroppedEvent
    | RefreshPersonInfoEvent;

/**
 * Maps OWA scenarios to a Nova's Event object type.
 * OWA scenarios tags satchel actions that gets dispatched internally.
 */
type OwaNovaEventMap = {
    aboutDialog: TileMoreInfoEvent;
    addIn: TileMessageExtensionLaunchEvent;
    appBar: TileLaunchEvent;
    appStore: AppAcquisitionLinkClickEvent;
    messageExtensions: TileMessageExtensionLaunchEvent;
    settings: SettingsContextMenuEvent;
    uninstall: TileUninstallEvent;
    flyoutButtonHover: FlyoutButtonHoverEvent;
    flyoutOpened: FlyoutOpenedEvent;
    appbarImpression: AppBarImpressionEvent;
    renderError: AppBarRenderErrorEvent;
    pinTaskbarShortcut: PinTaskbarShortcutEvent;
    openLPC: OpenLPCEvent;
    initializeLPC: InitializeLPCEvent;
    pinMessageExtension: PinMessageExtensionEvent;
    openLivePersonaEditor: OpenLivePersonaEditorEvent;
    livePersonaEditorInternal: LivePersonaEditorInternalEvent;
    openPersonSources: OpenPersonSourcesEvent;
    personSourcesInternal: PersonSourcesInternalEvent;
    composeEmail: ComposeEmailEvent;
    importContacts: ImportContactsEvent;
    exportContacts: ExportContactsEvent;
    startCallViaSipProtocol: StartCallViaSipProtocolEvent;
    startChatViaSipProtocol: StartChatViaSipProtocolEvent;
    showToast: ShowToastEvent;
    contentDropped: ContentDroppedEvent;
    refreshPersonInfo: RefreshPersonInfoEvent;
};
type OwaNovaTypes = keyof OwaNovaEventMap;

// Helper that defines a generic OWA Nova Action.
// Tag each action with `owaType` so we can discriminate a single action in a union of actions
// by manually declarating e.g., OwaNovaAction<'appBar'>.
type OwaNovaAction<Types extends OwaNovaTypes, Events extends OwaNovaEventMap[OwaNovaTypes]> = (
    event: Events
) => {
    event: OwaNovaEventMap[Types];
    owaType: Types;
};

// Union all possible OWA Nova Action types.
type OwaNovaActionUnion<
    Types extends OwaNovaTypes = OwaNovaTypes,
    Events extends OwaNovaEventMap[OwaNovaTypes] = OwaNovaEventMap[OwaNovaTypes]
> = {
    [Type in Types]: OwaNovaAction<Type, Events>;
}[Types];

const NovaEventTypes = {
    ...AppBarEventTypes,
    ...AppHostServiceEventTypes,
    settings: 'settings',
    pinTaskbarShortcut: 'pinTaskbarShortcut',
    pinMessageExtension: 'pinMessageExtension',
} as const;

export { type NovaEventUnion, type OwaNovaActionUnion, NovaEventTypes };
