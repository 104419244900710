import { isSuccessStatusCode } from 'owa-http-status-codes';
import { makePatchRequest } from 'owa-ows-gateway';
import type { MailboxInfo } from 'owa-client-types';
import type CopilotPrioritizeSettings from '../types/CopilotPrioritizeSettings';
import { SETTINGS_URL } from './constants';
import onCopilotPrioritizeSettingsSaved from '../mutators/onCopilotPrioritizeSettingsSaved';
import getStore from '../store/store';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';

export default async function saveCopilotPrioritizeSettingsToService(
    settings: CopilotPrioritizeSettings,
    mailboxInfo: MailboxInfo
): Promise<boolean> {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);
    const store = getStore().copilotPrioritizeSettingsStoreMap.get(key);
    const previousSettings = store?.settings;

    // Update the store with the new settings (optimistically)
    onCopilotPrioritizeSettingsSaved(mailboxInfo, settings);

    const settingsObjectForService = {
        inboxPrioritizationPreferences: settings,
    };

    let response: Response;
    try {
        response = await makePatchRequest(
            SETTINGS_URL /* requestUrl */,
            settingsObjectForService /* requestObject */,
            undefined /* correlationId */,
            true /* returnFullResponse */,
            undefined /* customHeaders */,
            true /* throwServiceError */,
            false /* includecredentials */,
            'saveCopilotPrioritizeSettings' /* actionName */,
            mailboxInfo /* mailboxInfo */
        );
    } catch (error) {
        if (previousSettings) {
            // Revert the store to the previous settings
            onCopilotPrioritizeSettingsSaved(mailboxInfo, previousSettings);
        }

        return false;
    }

    if (!!response && isSuccessStatusCode(response.status)) {
        return true;
    } else {
        if (previousSettings) {
            // Revert the store to the previous settings
            onCopilotPrioritizeSettingsSaved(mailboxInfo, previousSettings);
        }

        return false;
    }
}
