import { type CopilotThemeRefreshRate } from 'owa-copilot-themes-types';
import { owaDate, addDays, addWeeks, addMonths } from 'owa-datetime';
import { getStore } from '../store/store';

export default function getCopilotThemeRequiresUpdate(): boolean {
    const lastRefreshDate = Date.parse(getStore().options.lastRefreshDate);
    const refreshRate = getStore().options.refreshRate as CopilotThemeRefreshRate;
    const currentDate = owaDate();

    switch (refreshRate) {
        case 'Daily':
            return (
                currentDate >= addDays(owaDate('UTC', new Date(lastRefreshDate).toISOString()), 1)
            );
        case 'Weekly':
            return (
                currentDate >= addWeeks(owaDate('UTC', new Date(lastRefreshDate).toISOString()), 1)
            );
        case 'Monthly':
            return (
                currentDate >= addMonths(owaDate('UTC', new Date(lastRefreshDate).toISOString()), 1)
            );
        default:
            return false;
    }
}
