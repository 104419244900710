import { getCoprincipalAccounts } from 'owa-account-source-list-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';

// Limit the number of accounts we would be willing to cache to avoid running out of space in local storage.
const MAX_SUPPORTED_ACCOUNTS = 10;

export function isCachingEnabled() {
    if (!isFeatureEnabled('fwk-secondary-account-cache')) {
        return false;
    }

    if (getCoprincipalAccounts().length > MAX_SUPPORTED_ACCOUNTS) {
        return false;
    }

    // Only cache data if we have a strong expectation that the user will return to this client repeatedly.
    const policies = getUserConfiguration().PolicySettings;
    return (
        isHostAppFeatureEnabled('offlineDataAllowed') ||
        (isHostAppFeatureEnabled('localCacheAllowed') && !!policies?.SignInState?.includes('kmsi'))
    );
}
