import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OneGqlViewSchema" */ './oneGql_viewData')
);

export const lazyOneGqlViewSchema = createLazyResolver(
    'ONEGQL_VIEW_SCHEMA',
    lazyModule,
    m => m.oneGql_viewData
);

export const lazyUpdateColumnPreferencesMutation = createLazyResolver(
    'UPDATE_COLUMN_PREFERENCES_MUTATION',
    lazyModule,
    m => m.updateColumnsPreferences
);

export { OneJS_PeopleAppViewData } from './resolvers/people-app';
export { OneJS_LivePersonaEditorViewData } from './resolvers/live-persona-editor';
export { OneJS_MeetingOptionsViewData } from './resolvers/meeting-options';
export { OneJS_PersonSourcesViewData } from './resolvers/person-sources';
export { OneGQL_MeControlModel } from './resolvers/me-control';
