import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OcsServices"*/ './lazyIndex'),
    { name: 'OcsServices' }
);

export const lazyReadOcsSettings = new LazyAction(lazyModule, m => m.readOcsSettings);
export const lazyWriteOcsSettings = new LazyAction(lazyModule, m => m.writeOcsSettings);
export const lazyIsWriteOcsSettingsQueuedAction = new LazyAction(
    lazyModule,
    m => m.isWriteOcsSettingsQueuedAction
);
