import React from 'react';
import { GovernPriority, lazyGovern } from 'owa-tti';
import { lazyGetDefaultRibbonControlInMenuRendererFunction } from '../lazyFunctions';
import { appIcons, getAppStrings } from 'owa-acui-theme';
import type { ControlInMenuRendererFunction } from '@1js/acui-menu';

/**
 * For the in-menu renderer function, we return an empty array to alleviate needing to load this in during boot.
 * This will eventually be populated with the passed in, lazy-loaded `lazyGetDefaultRibbonControlInMenuRendererFunction`, inside of a lazyGovern.
 */
const getEmptyControlInMenuRendererFunction: () => ControlInMenuRendererFunction = () => () => [];

/**
 * Custom hook to lazy load the in-menu renderer function.
 */
export const useControlInMenuRendererFunction = (
    isProjectionPopout: boolean
): ControlInMenuRendererFunction => {
    const [controlInMenuRendererFunction, setControlInMenuRendererFunction] =
        React.useState<ControlInMenuRendererFunction>(getEmptyControlInMenuRendererFunction);

    const getInMenuRenderer = () => {
        lazyGetDefaultRibbonControlInMenuRendererFunction
            .import()
            .then(getDefaultRibbonControlInMenuRendererFunction => {
                setControlInMenuRendererFunction(() =>
                    getDefaultRibbonControlInMenuRendererFunction(
                        appIcons,
                        getAppStrings(),
                        isProjectionPopout /* skipResultMemoization */
                    )
                );
            });
    };

    React.useEffect(() => {
        lazyGovern.importAndExecute({
            priority: GovernPriority.Ribbon,
            task: getInMenuRenderer,
            name: 'getInMenuRenderer',
        });
    }, []);

    return controlInMenuRendererFunction;
};
