import { LazyAction, LazyModule } from 'owa-bundling-light';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CopilotThemes" */ './lazyIndex')
);

export const lazyGetCopilotThemeImage = new LazyAction(lazyModule, m => m.getCopilotThemeImage);

export const lazySaveCopilotTheme = new LazyAction(lazyModule, m => m.saveCopilotTheme);

export const lazyUpdateCopilotTheme = new LazyAction(lazyModule, m => m.updateCopilotTheme);
