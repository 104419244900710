import { FolderCountsFragmentDoc } from '../graphql/__generated__/FolderCountFragments.interface';
import updateFolderCounts from 'owa-mail-folder-actions/lib/updateFolderCounts';
import { readFragment, writeFragment } from 'owa-apollo';
import { orchestrator } from 'satcheljs';
import { getFolderTypeId } from '../util/getFolderTypeId';
import { logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';

// this orchestrator can be removed when fp-remove-apollo has shipped
// action can also be converted to a mutatorAction
orchestrator(updateFolderCounts, async actionMessage => {
    if (isFeatureEnabled('fp-remove-apollo')) {
        return;
    }
    const { folderId, unreadCount, totalCount, isDeltaChange } = actionMessage;
    const folderCacheId = getFolderTypeId(folderId);
    const folderFragmentToUpdate = readFragment({
        id: folderCacheId,
        fragment: FolderCountsFragmentDoc,
    });

    // FolderId couldn't be translated into a cache id, likely because it's nullish.
    // In that case, folderFragmentToUpdate will be returned as an empty object, not
    // a nullish value, so it will pass the check below but not actually be in the cache.
    if (!folderCacheId) {
        return;
    }

    // Folder is not present in the local cache
    if (!folderFragmentToUpdate) {
        return;
    }

    let folderUnreadCount = folderFragmentToUpdate.UnreadCount;
    let folderTotalCount = folderFragmentToUpdate.totalMessageCount;

    if (isDeltaChange) {
        folderUnreadCount += unreadCount;
        folderTotalCount += totalCount;
    } else {
        folderUnreadCount = unreadCount;
        folderTotalCount = totalCount;
    }

    // Do not let counts fall below 0 in the store.
    // This state could happen due to the timing of when
    // folder tree is loaded versus listview.
    folderUnreadCount = Math.max(folderUnreadCount, 0);
    folderTotalCount = Math.max(folderTotalCount, 0);

    // If the new counts are the same as the old, don't do anything
    // this can happen during reload operations, where we run this operation on all folders
    if (
        folderUnreadCount === folderFragmentToUpdate.UnreadCount &&
        folderTotalCount === folderFragmentToUpdate.totalMessageCount
    ) {
        return;
    }

    try {
        writeFragment({
            id: folderCacheId,
            fragment: FolderCountsFragmentDoc,
            data: {
                UnreadCount: folderUnreadCount,
                totalMessageCount: folderTotalCount,
            },
        });
    } catch (e) {
        try {
            if (e.name == 'Invariant Violation') {
                const after = readFragment({
                    id: folderCacheId,
                    fragment: FolderCountsFragmentDoc,
                });
                logUsage('apo_folder_inv_v_a', {
                    id: folderCacheId,
                    before: JSON.stringify(Object.keys(folderFragmentToUpdate)),
                    after: after
                        ? JSON.stringify(Object.keys(after))
                        : after === null
                        ? 'null'
                        : 'undefined',
                    ucount: folderUnreadCount,
                    tcount: folderTotalCount,
                });
            }
        } catch {}

        throw e;
    }
});
