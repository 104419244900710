import './initialization/patchConsole';
import { bootstrap as sharedBootstrap } from 'owa-shared-bootstrap';
import type { BootstrapOptions } from './types/BootstrapOptions';
// Import setHtmlLangOrchestrator so that it gets run.
import './orchestrators/setHtmlLangOrchestrator';
import 'owa-fabric-theme'; // Need to initialize orchestrators that listen for theme and locale change
import { DesktopErrorComponentAsync } from 'owa-desktop-error-component';
import { isHostAppFeatureEnabled } from 'owa-hostapp-feature-flags';
import { getWindowTitle } from './initialization/getWindowTitle';
import { initializeOwaLocalization } from 'owa-localization-bootstrap';
import { initializeManifest } from './initialization/initializeManifest';
import initializeTheme from './initialization/initializeTheme';
import initializeDensity from './initialization/initializeDensity';
import { updateServiceConfig } from 'owa-service/lib/config';
import { updateWorkerConfig } from 'owa-worker-shared/lib/utils/workerConfig';
import {
    getMailboxSpecificRequestOptions,
    prepareConnectedAccountRequestOptions,
} from 'owa-request-options';
import { lazyGetMailboxSpecificRequestOptionsV2 } from 'owa-request-options/lib/utils/lazyGetMailboxSpecificRequestOptionsV2';
import { initializeApolloClient } from 'owa-apollo';
import { wrapInApolloProvider } from 'owa-apollo/lib/react';
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { createBootPolicies, unblockBootResolvers } from 'boot-type-policies';
import { initializeRelayEnvironment } from 'owa-relay/lib/environment';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { PromiseWithKey } from 'owa-performance';
import { trackBottleneck } from 'owa-performance';
import { setConfiguration } from 'owa-userconfiguration';
import type { SessionData } from 'owa-service/lib/types/SessionData';
import { stashSessionData } from 'owa-worker-shared/lib/features/sessionDataStash';
import { createMainLinks } from 'owa-apollo-links';
import { createDataWorkerLink } from 'owa-data-worker-link';
import { webResolvers } from 'outlook-resolvers-web';
import { bootstrapDataWorker, type BootstrapType } from './initialization/bootstrapDataWorker';
import { isBootFeatureEnabled } from 'owa-metatags';
import { isGetMailboxSpecificRequestOptionsV2Enabled } from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';
import { getTimeZoneOverrides } from 'owa-datetime-bootstrap';
import { setAccountConfigurationCallback } from 'owa-account-source-list';
import type { MailboxInfo } from 'owa-client-types';
import type OwaUserConfiguration from 'owa-service/lib/contract/OwaUserConfiguration';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { lazyGetStartAccountDataViaGql } from 'owa-account-start-data-gql';
import type { BootstrapApolloCacheFunc } from './initialization/bootstrapApolloCache';
import type { InitializeNestedAppAuthExecutorFunc } from './initialization/initializeNestedAppAuthExecutor';
import type { GetBposPromiseFunc } from 'owa-bpos-store/lib/bposNavBarData';
import disableEdgeCopilotLLMExtraction from './utils/disableEdgeCopilotLLMExtraction';

export interface GetCommonBootstrapOptions {
    bootstrapApolloCache?: BootstrapApolloCacheFunc;
    getBposPromise?: GetBposPromiseFunc;
    getNestedAppAuthExecutorPromise?: InitializeNestedAppAuthExecutorFunc;
    onComponentError?: () => void;
}

export function getCommonBootstrap({
    bootstrapApolloCache: getBootstrapApolloCachePromise,
    getBposPromise,
    getNestedAppAuthExecutorPromise,
    onComponentError,
}: GetCommonBootstrapOptions): (options: BootstrapOptions) => Promise<any> {
    return options => {
        const prerenderPromises: PromiseWithKey<unknown>[] = [];

        const bposPromise =
            !options.disableFeature?.bposPreRenderPromise && getBposPromise
                ? getBposPromise()
                : undefined;
        if (bposPromise) {
            prerenderPromises.push({ promise: bposPromise, key: 'bpos' });
        }

        const { relay: isRelayDisabled, apollo: isApolloDisabled } = options.disableFeature || {};
        let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

        if (!isRelayDisabled || !isApolloDisabled) {
            const context = { isFeatureEnabled };
            const links = createMainLinks(createDataWorkerLink, context, {
                web: webResolvers,
            });

            // Initialize the Relay environment
            if (!isRelayDisabled) {
                initializeRelayEnvironment({ links, context });
            }

            // Initialize the Apollo client
            if (!isApolloDisabled) {
                const bootTypePolicies = createBootPolicies();
                apolloClient = initializeApolloClient({
                    links,
                    context,
                    bootTypePolicies,
                });
            }
        }

        const getMailboxSpecificRequestOptionsV2 =
            lazyGetMailboxSpecificRequestOptionsV2.importAndExecute;

        updateServiceConfig({
            // This is used in owa-service to get Request Options with appropriate headers
            // needed for multi-account support. Parameters: UserIdentity, MailboxSmtpAddress and MailboxType
            // passed within getMailboxSpecificRequestOptions are used to decide which headers to be added for the same.
            prepareRequestOptions: isGetMailboxSpecificRequestOptionsV2Enabled()
                ? getMailboxSpecificRequestOptionsV2
                : getMailboxSpecificRequestOptions,
        });

        // provide the worker config with a way to get connected account request options
        updateWorkerConfig({ prepareConnectedAccountRequestOptions });

        try {
            bootstrapDataWorker(0, options.disableFeature?.bootstrapDataWorker);
        } catch {}

        return sharedBootstrap({
            ...options,
            renderMainComponent: options.disableFeature?.apollo
                ? options.renderMainComponent
                : () => wrapInApolloProvider(options.renderMainComponent?.() ?? null),
            initializeState: (sessionData?: SessionData) => {
                stashSessionData(sessionData);

                // In-memory cache for user configuration. Has to be set before start of UI rendering as it's a main source of data during initial render.
                setConfiguration(sessionData?.owaUserConfig, getGlobalSettingsAccountMailboxInfo());
                setAccountConfigurationCallback(
                    (mailboxInfo: MailboxInfo, userConfiguration: OwaUserConfiguration) => {
                        // When the user configuration is loaded for an additional account we need to update the owa-userconfiguraiton-cache
                        setConfiguration(userConfiguration, mailboxInfo);
                    }
                );

                initializeManifest();

                // Block Edge Copilot from being able to access the contents of the page
                disableEdgeCopilotLLMExtraction();

                if (isBootFeatureEnabled('dev-bootOfflineFolders')) {
                    delete sessionData?.findFolders;
                }

                const themePromise = initializeTheme();
                const statePromises: PromiseWithKey<any>[] = [
                    {
                        key: 'th',
                        promise: themePromise,
                    },
                ];

                if (!options.disableFeature?.initializeDensity) {
                    statePromises.push({
                        key: 'dnst',
                        promise: initializeDensity(),
                    });
                }

                if (apolloClient && getBootstrapApolloCachePromise) {
                    statePromises.push({
                        key: 'apollo',
                        promise: getBootstrapApolloCachePromise(
                            apolloClient,
                            options,
                            themePromise
                        ),
                    });
                }

                if (getNestedAppAuthExecutorPromise) {
                    statePromises.push({
                        key: 'naa',
                        promise: getNestedAppAuthExecutorPromise(),
                    });
                }

                const originalPromise = options.initializeState(
                    sessionData,
                    false /* isModuleSwitch */
                );
                if (originalPromise) {
                    statePromises.push({ key: 'ias', promise: originalPromise });
                }
                return trackBottleneck('stf', statePromises);
            },
            getWindowTitle: () =>
                isHostAppFeatureEnabled('moduleNameFromWindow')
                    ? getWindowTitle(options.getModuleName)
                    : options.getModuleName(),
            initializeLocalization: initializeOwaLocalization,
            fullErrorComponent: DesktopErrorComponentAsync,
            prerenderPromises,
            onComponentError,
            getTimeZoneOverrides,
            workerOptions: {
                preload: () => bootstrapDataWorker(1, options.disableFeature?.bootstrapDataWorker),
            },
            getStartAccountDataOverride: lazyGetStartAccountDataViaGql.importAndExecute,
        })
            .then(unblockBootResolvers)
            .then(() => bootstrapDataWorker(2, options.disableFeature?.bootstrapDataWorker));
    };
}
