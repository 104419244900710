export enum OcsVersion {
    Beta = 'beta',
    V1 = 'v1',
}

export enum OcsSettingScope {
    Account = 'account',
    Global = 'global',
}

export enum OcsSettingType {
    Bool = 'Bool',
    Int = 'Int',
    Json = 'JSON',
    String = 'String',
}

export function makeOcsBaseUrl(version: OcsVersion, scope: OcsSettingScope) {
    return `ows/${version}/OutlookCloudSettings/settings/${scope}`;
}

export function makeOcsRequestUrl(
    version: OcsVersion,
    scope: OcsSettingScope,
    settings: string[]
): string {
    const ocsBaseUrl = makeOcsBaseUrl(version, scope);
    const settingNameTokens = settings.join(',');

    return `${ocsBaseUrl}?settingname=${settingNameTokens}`;
}

export type OcsRequestOptions = {
    additionalHeaders?: any;
    correlationId?: string;
    returnFullResponse?: boolean;
};
