import type { MailboxInfo } from 'owa-client-types';
import { getIsSearchTableShown } from 'owa-mail-list-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import {
    isFolderInMailboxType,
    areFoldersInSameMailboxType,
    areFoldersInSameAccount,
} from 'owa-folders';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function doesTableSupportUndo(
    sourceFolderId: string,
    mailboxInfo: MailboxInfo,
    destinationFolderId?: string
): boolean {
    return !!(sourceFolderId &&
    !getIsSearchTableShown() &&
    mailboxInfo &&
    (mailboxInfo.type === 'UserMailbox' ||
        (isFeatureEnabled('grp-loadFolders') && mailboxInfo.type === 'GroupMailbox')) &&
    folderIdToName(sourceFolderId) != 'recoverableitemsdeletions' &&
    folderIdToName(sourceFolderId) != 'outbox' &&
    isMailboxTypeSupported(sourceFolderId, destinationFolderId) &&
    destinationFolderId
        ? areFoldersInSameAccount(sourceFolderId, destinationFolderId)
        : true);
}

function isMailboxTypeSupported(sourceFolderId: string, destinationFolderId?: string) {
    // Archive folder items do not support undo
    if (isFolderInMailboxType(sourceFolderId, 'ArchiveMailbox')) {
        return false;
    }

    // Shared folder items do not support undo
    if (isFolderInMailboxType(sourceFolderId, 'SharedMailbox')) {
        return false;
    }

    // Some operations (Move) we need the source and destination folder
    // So we need to check the mailbox types, since Undo operate on the ItemIds/ConversationIds
    // and they are not immutable across mailboxes, then we cant undo them
    if (destinationFolderId) {
        return areFoldersInSameMailboxType(sourceFolderId, destinationFolderId);
    }

    return true;
}
