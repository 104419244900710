import { createStore } from 'satcheljs';
import type CopilotPrioritizeSettings from '../types/CopilotPrioritizeSettings';
import { ObservableMap } from 'mobx';

interface CopilotPrioritizeSettingsStore {
    isInitialized: boolean;
    settings: CopilotPrioritizeSettings;
}

interface CopilotPrioritizeSettingsStoreMap {
    copilotPrioritizeSettingsStoreMap: ObservableMap<string, CopilotPrioritizeSettingsStore>;
}

const store = createStore<CopilotPrioritizeSettingsStoreMap>('copilotPrioritizeSettingsStore', {
    copilotPrioritizeSettingsStoreMap: new ObservableMap(),
})();

const getStore = () => store;
export default getStore;
