import type CategoryActionSource from 'owa-categories/lib/utils/CategoryActionSource';
import { getCategoryMenuProps } from 'owa-categories/lib/utils/getCategoryMenuProps';
import getMasterCategoryList from 'owa-categories/lib/utils/getMasterCategoryList';
import type { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { DirectionalHint } from '@fluentui/react/lib/ContextualMenu';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';
import { lazyAddFavoriteCategory } from 'owa-favorites';
import type { TableView } from 'owa-mail-list-store';
import { getStore as getListViewStore } from 'owa-mail-list-store';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import type { MailboxInfo } from 'owa-client-types';
import type { ActionSource } from 'owa-mail-store';
import {
    lazyAddCategoriesFromTable,
    lazyClearCategoriesFromTable,
    lazyRemoveCategoriesFromTable,
    lazyAddCategoriesFromItem,
    lazyClearCategoriesFromItem,
    lazyRemoveCategoriesFromItem,
} from 'owa-mail-triage-action';
import selectMailStoreItemById from 'owa-mail-store/lib/selectors/selectMailStoreItemById';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { lazyMountAndShowFullOptions } from 'owa-options-view';
import { CATEGORY_MENU_WIDTH } from './constants';
import {
    getAllCategoriesOnRows,
    getCommonCategoriesOnRows,
} from './getCategoryActionDecisionProperties';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Gets the category menu items for context menu
 * @param tableViewId the tableView id
 * @param dismissMenu the callback to be called to dismiss of menu
 * @param actionSource the actionSource
 * @param shouldShowSearch flag indicating whether to show search box or not
 * @param itemId the selected item
 * @param isItemPart flag indicating whether the context menu is for single item in conversation view
 * @return returns the contextual menu items
 */
export function getCategoryMenuPropertiesForContextMenu(
    tableViewId: string,
    dismissMenu: (ev?: any) => void,
    actionSource: ActionSource,
    shouldShowSearch?: boolean,
    itemId?: string,
    isItemPart?: boolean
): IContextualMenuProps {
    const tableView = getListViewStore().tableViews.get(tableViewId);
    return isItemPart
        ? getCategoriesMenuItemProperties(
              getListViewStore().tableViews.get(tableViewId),
              'ContextLV',
              itemId,
              dismissMenu,
              DirectionalHint.rightTopEdge
          )
        : getCategoriesMenuProperties(
              tableView,
              shouldShowSearch ?? false /* showSearchBox */,
              true /* showManageCategories */,
              dismissMenu, // Callback to dismiss the right click context menu
              actionSource,
              'ContextLV',
              DirectionalHint.rightTopEdge
          );
}

/**
 * Gets the category menu items
 * @param tableView the tableView
 * @param showSearchBox flag indicating whether to show search box or not
 * @param showManageCategoriesBox flag indicating whether to the manage categories option or not
 * @param dismissMenu callback to be called to dismiss the category context menu
 * @param actionSource the actionSource
 * @param categoryActionSource the category action source
 * @param directionalHint determines which direction the callout appears, defaults to bottomLeftEdge
 * @return returns the contextual menu items
 */
function getCategoriesMenuProperties(
    tableView: TableView | undefined,
    showSearchBox: boolean,
    showManageCategories: boolean,
    dismissMenu: () => void,
    actionSource: ActionSource,
    categoryActionSource: CategoryActionSource,
    directionalHint?: DirectionalHint
): IContextualMenuProps {
    if (!tableView) {
        return { items: [] };
    }
    const mailboxInfo = getMailboxInfo(tableView);
    const categoryMenuProps = getCategoryMenuProps({
        actionSource: categoryActionSource,
        categoryList: getMasterCategoryList(mailboxInfo),
        mailboxInfo,
        getCategoriesToCheck: () =>
            getCommonCategoriesOnRows(tableView, [...tableView.selectedRowKeys.keys()]),
        dismissMenu,
        shouldShowSearchBox: showSearchBox,
        shouldShowManageCategories: showManageCategories,
        getShouldShowClearCategories: () => {
            return (
                getAllCategoriesOnRows(tableView, [...tableView.selectedRowKeys.keys()]).length > 0
            );
        }, // Show clear categories if any of the selected row has categories
        addFavoriteCategory,
        onAfterCreateNewCategory: (
            category: string,
            createNewCategoryPromise?: Promise<boolean>
        ) => {
            // When a new category is created, we should apply it optimistically to the selected items
            lazyAddCategoriesFromTable.importAndExecute(
                [...tableView.selectedRowKeys.keys()],
                tableView,
                [category],
                actionSource,
                createNewCategoryPromise
            );
        },
        onCheckedCategoryClicked: category => {
            // When a checked category is clicked its a remove action
            lazyRemoveCategoriesFromTable.importAndExecute(
                [...tableView.selectedRowKeys.keys()],
                tableView,
                [category],
                actionSource
            );
        },
        onUncheckedCategoryClicked: category => {
            // When an unchecked category is clicked its an add action
            lazyAddCategoriesFromTable.importAndExecute(
                [...tableView.selectedRowKeys.keys()],
                tableView,
                [category],
                actionSource
            );
        },
        onManageCategoryClicked: () => onManageCategoryClicked(mailboxInfo),
        onClearCategoriesClicked: () => {
            lazyClearCategoriesFromTable.importAndExecute(
                [...tableView.selectedRowKeys.keys()],
                tableView,
                actionSource
            );
        },
        directionalHint,
        width: CATEGORY_MENU_WIDTH,
    });

    return categoryMenuProps;
}

/**
 * Gets the category menu items
 * @param tableView the tableView
 * @param categoryActionSource the category action source
 * @param itemId the selected item
 * @param dismissMenu the callback to be called to dismiss of menu
 * @param directionalHint determines which direction the callout appears, defaults to bottomLeftEdge
 * @return returns the contextual menu items
 */
function getCategoriesMenuItemProperties(
    tableView: TableView | undefined,
    categoryActionSource: CategoryActionSource,
    itemId: string | undefined,
    dismissMenu: () => void,
    directionalHint: DirectionalHint
): IContextualMenuProps {
    if (!tableView || !itemId) {
        return { items: [] };
    }
    const mailboxInfo = getMailboxInfo(tableView);
    const item = selectMailStoreItemById(itemId);
    const categoryMenuProps = getCategoryMenuProps({
        actionSource: categoryActionSource,
        categoryList: getMasterCategoryList(mailboxInfo),
        mailboxInfo,
        getCategoriesToCheck: () => {
            return item.Categories ?? [];
        },
        dismissMenu,
        shouldShowSearchBox: true,
        shouldShowManageCategories: true,
        getShouldShowClearCategories: () => {
            return false;
        },
        addFavoriteCategory,
        onAfterCreateNewCategory: (category: string) => {
            // When a new category is created, we should apply it optimistically to the selected items
            lazyAddCategoriesFromItem.importAndExecute(
                itemId,
                mailboxInfo,
                [category],
                undefined /* createNewCategoryPromise */,
                categoryActionSource
            );
        },
        onCheckedCategoryClicked: (category: string) => {
            // When a checked category is clicked its a remove action
            lazyRemoveCategoriesFromItem.importAndExecute(
                itemId,
                mailboxInfo,
                [category],
                categoryActionSource
            );
        },
        onUncheckedCategoryClicked: (category: string) => {
            // When an unchecked category is clicked its an add action
            lazyAddCategoriesFromItem.importAndExecute(
                itemId,
                mailboxInfo,
                [category],
                undefined /* createNewCategoryPromise */,
                categoryActionSource
            );
        },
        onManageCategoryClicked: () => onManageCategoryClicked(mailboxInfo),
        onClearCategoriesClicked: () => {
            lazyClearCategoriesFromItem.importAndExecute(itemId, mailboxInfo);
        },
        directionalHint,
        width: CATEGORY_MENU_WIDTH,
    });

    return categoryMenuProps;
}

/**
 * Custom callback function when manage category is clicked
 */
function onManageCategoryClicked(mailboxInfo: MailboxInfo) {
    // Navigate to options/categories
    if (isMonarchMultipleAccountsEnabled() || isFeatureEnabled('acct-multiacctcomingsoon')) {
        lazyMountAndShowFullOptions.importAndExecute(
            'accounts-category',
            'categories',
            undefined,
            undefined,
            mailboxInfo
        );
    } else {
        lazyMountAndShowFullOptions.importAndExecute('accounts-category', 'categories');
    }
}

/**
 * Custom callback function when adding a favorite category
 */
function addFavoriteCategory(categoryId: string, actionSource: CategoryActionSource) {
    lazyAddFavoriteCategory
        .import()
        .then(addFavoriteCategoryFunc =>
            addFavoriteCategoryFunc(categoryId, actionSource, getModuleContextMailboxInfo())
        );
}
