import type { Getter } from 'owa-bundling-light';
import { LazyModule, LazyImport } from 'owa-bundling-light';
import type { ResolverContext, GraphQLResolverFn } from 'owa-graph-schema';
import { PerformanceDatapoint, logGreyError } from 'owa-analytics';
import { DatapointStatus } from 'owa-analytics-types';
import { isRunningOnWorker } from 'owa-config/lib/isRunningOnWorker';
import { lazyCheckForInactiveTransaction } from 'owa-inactive-transactions';

export function createLazyResolver<
    TModule,
    TResolver extends GraphQLResolverFn<any, any, any, any>
>(
    resolverName: string,
    importCbOrlazyModule: (() => Promise<TModule>) | LazyModule<TModule>,
    getter: Getter<TResolver, TModule>,
    fallbackBehavior: ResolverContext['fallbackBehavior'] = 'Fallback'
): TResolver {
    const lazyModule =
        typeof importCbOrlazyModule === 'function'
            ? new LazyModule(importCbOrlazyModule)
            : importCbOrlazyModule;
    const lazyImport = new LazyImport(lazyModule, getter);

    const lazyResolver = (parent: any, args: any, context: any, info: any) => {
        const resolverContext = context as ResolverContext;
        const contextDatapoint = resolverContext.perfDatapoint;

        // if the runtime callsite for the query specifies a fallback behavior, use that, otherwise use the
        // configured fallback behavior for the resolver
        if (resolverContext.fallbackBehavior === undefined) {
            context.fallbackBehavior = fallbackBehavior;
        }

        const start = Date.now();

        return lazyImport.import().then(async resolver => {
            if (contextDatapoint) {
                const duration = Date.now() - start;
                if (duration > 1 && !contextDatapoint.getCustomData('importDuration')) {
                    contextDatapoint.addCustomData({ importDuration: duration });
                }
            }

            const perfDatapoint = new PerformanceDatapoint('resolver_perf', {
                ring: 'Dogfood',
            });
            perfDatapoint.addCustomData({ resolverName });

            try {
                const result = await resolver(parent, args, context, info, perfDatapoint);
                if (result instanceof Error) {
                    perfDatapoint.endWithError(DatapointStatus.RequestNotComplete, result);
                } else {
                    perfDatapoint.end();
                }
                return result;
            } catch (error) {
                perfDatapoint.endWithError(DatapointStatus.RequestNotComplete, error);
                // We don't run idb resolvers on the main thread, so need to check transactions there
                if (isRunningOnWorker()) {
                    lazyCheckForInactiveTransaction.import(error);
                }

                // Graphql.js gets confused if we throw plain old objects.
                if (!(error instanceof Error)) {
                    // Use logGreyError since even though it's not an error, it might have diagnostic info like a stack
                    logGreyError('LazyResolverThrowingObject', error, { resolverName });
                }
                throw error;
            }
        });
    };

    return lazyResolver as TResolver;
}
