import getNativeContextMenuStateStore from '../store/store';
import { mutatorAction } from 'satcheljs';
import type { IContextualMenuItem, Point } from '@fluentui/react';

interface ContextMenuEvent extends Event {
    sourceCapabilities: {
        firesTouchEvents: boolean;
    };
}

export const onNativeContextMenuDismiss = mutatorAction(
    'onNativeContextMenuDismiss',
    function onNativeContextMenuDismiss(
        ev?: Event | React.MouseEvent | React.KeyboardEvent,
        dismissAll?: boolean
    ): void {
        if (ev) {
            const contextMenuEvent = ev as ContextMenuEvent;
            /**
             * If the sourceCapabilities property is truthy, it means that the context menu was triggered by an user
             * touch event so it can be dismissed
             **/
            if (contextMenuEvent.sourceCapabilities || dismissAll) {
                getNativeContextMenuStateStore().showContextMenu = false;
            }
        }
    }
);

export const onNativeContextMenuItemClick = mutatorAction(
    'onNativeContextMenuItemClick',
    function onNativeContextMenuItemClick(): boolean | void {
        // Close the context menu when a menu item is clicked
        getNativeContextMenuStateStore().showContextMenu = false;
    }
);

export const setNativeContextMenuProps = mutatorAction(
    'setNativeContextMenuProps',
    function setNativeContextMenuProps(
        mouseEvent: MouseEvent,
        contextualMenuItems: IContextualMenuItem[],
        showContextMenu: boolean = false
    ) {
        const mouseEventPoint: Point = { x: mouseEvent.x, y: mouseEvent.y };
        const store = getNativeContextMenuStateStore();
        store.point = mouseEventPoint;
        store.contextualMenuItems = contextualMenuItems;
        store.showContextMenu = showContextMenu;
    }
);
