import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { getStore as getFavoritesStore, onFavoritesLoaded } from 'owa-favorites';
import type FolderInfoResponseMessage from 'owa-service/lib/contract/FolderInfoResponseMessage';
import type BaseFolderType from 'owa-service/lib/contract/BaseFolderType';
import { isFeatureEnabled } from 'owa-feature-flags';
import getPublicFolders from '../services/getPublicFolders';
import addPublicFolderToStore from '../actions/addPublicFolderToStore';
import publicFolderFavoriteStore from '../store/publicFolderFavoriteStore';
import { mapOWSFolderToGql } from 'owa-folder-gql-mappers';
import getPublicFolderMailboxInfoForSmtpAddress from '../services/utils/getPublicFolderMailboxInfoForSmtpAddress';
import type { MailFolder } from 'owa-graph-schema';
import type { FolderForestNode, FavoritePublicFolderData } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { isSameCoprincipalAccountByMailboxAndAccountKey } from 'owa-client-types';
import { getMailboxInfoForPublicFolder } from 'owa-mailbox-info/lib/getMailboxInfoForPublicFolder';
import compareMailboxInfos from 'owa-mailbox-info/lib/compareMailboxInfos';
import {
    default as isMailboxPackageFlightEnabled,
    isMailboxCompareFlightEnabled,
} from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';
import getGlobalSettingsAccountMailboxInfo from 'owa-account-source-list-store/lib/selectors/getGlobalSettingsAccountMailboxInfo';
import { orchestrator } from 'satcheljs';

orchestrator(onFavoritesLoaded, async actionMessage => {
    const defaultPublicFolderMailbox =
        getUserConfiguration()?.SessionSettings?.DefaultPublicFolderMailbox;
    if (!defaultPublicFolderMailbox) {
        return;
    }
    const mailboxInfo = isMailboxPackageFlightEnabled()
        ? getMailboxInfoForPublicFolder(
              getGlobalSettingsAccountMailboxInfo(),
              defaultPublicFolderMailbox
          )
        : getPublicFolderMailboxInfoForSmtpAddress(defaultPublicFolderMailbox);

    if (isMailboxPackageFlightEnabled() && isMailboxCompareFlightEnabled()) {
        compareMailboxInfos(
            getPublicFolderMailboxInfoForSmtpAddress(defaultPublicFolderMailbox),
            mailboxInfo
        );
    }

    const folderIds: string[] = isFeatureEnabled('tri-favorites-roaming')
        ? getFavoritePublicFolderIdsV2(actionMessage.mailboxKey)
        : getFavoritePublicFolderIdsV1();
    const getFolderResponse = await getPublicFolders(folderIds, mailboxInfo);
    getFolderResponse?.Items?.forEach((item: FolderInfoResponseMessage) => {
        item.Folders?.forEach((folder: BaseFolderType) => {
            if (folder != null) {
                const mailFolder: MailFolder | null = mapOWSFolderToGql(folder, mailboxInfo);
                if (mailFolder) {
                    addPublicFolderToStore(publicFolderFavoriteStore, mailFolder);
                }
            }
        });
    });
});

function getFavoritePublicFolderIdsV1() {
    const favoritesStore = getFavoritesStore();
    const allFavorites = [...favoritesStore.favoritesFolderNodes.values()];
    return allFavorites
        .filter(favorite => {
            return (favorite as FolderForestNode).type == 5;
        })
        .map(favoriteData => {
            return favoriteData.id;
        });
}

function getFavoritePublicFolderIdsV2(accountKey: string) {
    const favoritesStore = getFavoritesStore();
    const allFavorites = [...favoritesStore.outlookFavorites.values()];
    return allFavorites
        .filter(favorite => {
            let isAllowedMailbox = true;
            if (favorite.mailboxInfo) {
                isAllowedMailbox = isSameCoprincipalAccountByMailboxAndAccountKey(
                    favorite.mailboxInfo,
                    accountKey
                );
            }
            return isAllowedMailbox && favorite.type === 'publicFolder';
        })
        .map(favoriteData => {
            return (favoriteData as FavoritePublicFolderData).publicFolderId;
        });
}
