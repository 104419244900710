import { useEffect, useRef } from 'react';
import type { handleNativeContextMenu as HandleNativeContextMenu } from '../utils/handleNativeContextMenu';
import { isFeatureEnabled } from 'owa-feature-flags';
import { lazyHandleNativeContextMenu } from '../lazyFunctions';
import {
    type ContextMenuRequestedArguments,
    getEnhancedHostExtendedHost,
} from 'owa-config/lib/enhancedHost';

let isUseContextMenuEventTriggered = false;

export function useNativeContextMenuEventsInitializer(): void {
    const contextMenuEvent = useRef<MouseEvent | null>(null);
    let handleNativeContextMenu: typeof HandleNativeContextMenu | undefined = undefined;

    const nativeContextMenuEventHandler = (e: MouseEvent) => {
        contextMenuEvent.current = e;
        isUseContextMenuEventTriggered = true;
    };

    useEffect(() => {
        if (isFeatureEnabled('fwk-contextMenu')) {
            /**
             * Creates a global contextmenu event listener on document so that the native context menu can
             * be displayed on every place in monarch when a user right clicks unless other context menu
             * calls event.StopPropagation() to display their own
             */
            document.addEventListener('contextmenu', nativeContextMenuEventHandler);

            const extendedHost = getEnhancedHostExtendedHost();
            if (extendedHost) {
                const loadPromise = lazyHandleNativeContextMenu
                    .import()
                    .then(fn => (handleNativeContextMenu = fn));
                /**
                 * This is the handler for the edge browser native context menu the let us reuse the native
                 * context menu commands
                 */
                extendedHost.onContextMenuRequested(
                    async (contextMenuRequestedArguments: ContextMenuRequestedArguments) => {
                        if (isUseContextMenuEventTriggered) {
                            await loadPromise;
                            handleNativeContextMenu?.(
                                contextMenuRequestedArguments,
                                contextMenuEvent
                            );
                            isUseContextMenuEventTriggered = false;
                        } else {
                            /**
                             * Every invocation of onContextMenuRequested needs to be paired with a call to
                             * selectedCommandId on the parameter, otherwise could create unexpected issues with
                             * the Native Context Menu commands
                             */
                            contextMenuRequestedArguments.selectedCommandId = -1;
                        }
                    }
                );
            }
        }

        return () => {
            if (isFeatureEnabled('fwk-contextMenu')) {
                document.removeEventListener('contextmenu', nativeContextMenuEventHandler);
            }
        };
    }, []);
}
