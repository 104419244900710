import type { FieldPolicy, FieldFunctionOptions } from '@apollo/client/cache';
import { getFoldersToShowFirst } from 'owa-folders-data-utils';
import type { MailFolder } from 'owa-graph-schema';
import { updateChildFolderIdsOnParent } from './updateChildFolderIdOnParent';
import type { MailboxInfo } from 'owa-client-types';

export const folderHierarchyTypePolicy: FieldPolicy = {
    keyArgs: ['baseFolderDistinguishedIds', 'mailboxInfo'],
    merge: (existing, incoming, options) => {
        const resetCache = getResetCache(existing?.CustomSorted, incoming?.CustomSorted);
        const rootFolder = existing?.RootFolder || incoming.RootFolder;
        const mailboxInfo = options.readField<MailboxInfo>('mailboxInfo', rootFolder);
        const allFolders = formParentChildRelationships(
            rootFolder,
            existing?.Folders || [],
            incoming?.Folders || [],
            options,
            mailboxInfo,
            incoming.CustomSorted,
            resetCache
        );

        return {
            RootFolder: rootFolder,
            Folders: allFolders,
            TotalItemsInView: incoming.TotalItemsInView,
            offset: incoming.offset,
            IncludesLastItemInRange: incoming.IncludesLastItemInRange,
            CustomSorted: incoming.CustomSorted,
            __typename: 'FolderHierarchyResult',
        };
    },
};

const getResetCache = (
    existingCustomSort: boolean | undefined,
    incomingCustomSort: boolean | undefined
): boolean => {
    if (existingCustomSort === undefined || incomingCustomSort === undefined) {
        return false;
    }

    return existingCustomSort !== incomingCustomSort;
};

export function formParentChildRelationships(
    rootFolderRef: MailFolder | null,
    existingFolderRefs: MailFolder[],
    incomingFolderRefs: MailFolder[],
    options: FieldFunctionOptions,
    mailboxInfo?: MailboxInfo,
    customSorted?: boolean,
    resetCache?: boolean
): MailFolder[] {
    if (!rootFolderRef) {
        return [];
    }

    const rootFolderId = options.readField<string>('id', rootFolderRef);
    if (!rootFolderId) {
        return [];
    }

    const folderRefMap: {
        [id: string]: MailFolder;
    } = {};
    const parentFoldersToUpdate = new Map<string, string[]>();
    const folderNameIdMap: {
        [name: string]: string;
    } = {};
    const folderIds: string[] = [];

    // Add root folder to map and id array
    folderRefMap[rootFolderId] = rootFolderRef;
    folderIds.push(rootFolderId);

    // Add existing folders to map for lookup
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    existingFolderRefs.forEach(folderRef => {
        const folderId = options.readField<string>('id', folderRef);
        if (folderId) {
            folderRefMap[folderId] = folderRef;
        }
    });

    // Add incoming folders to the map and id array
    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    incomingFolderRefs.forEach(folderRef => {
        const folderId = options.readField<string>('id', folderRef);
        if (folderId) {
            folderRefMap[folderId] = folderRef;
            folderIds.push(folderId);
        }
    });

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    folderIds.forEach(folderId => {
        const folderRef = folderRefMap[folderId];
        const parentFolderId = options.readField<string>('parentFolderId', folderRef);
        const distinguishedFolderType = options.readField<string>(
            'distinguishedFolderType',
            folderRef
        );
        if (distinguishedFolderType) {
            folderNameIdMap[distinguishedFolderType] = folderId;
        }

        // Parent maybe already fetched and did not come as part of this request
        if (parentFolderId) {
            const parentFolderRef = folderRefMap[parentFolderId];

            if (parentFolderRef) {
                if (!parentFoldersToUpdate.get(parentFolderId)) {
                    if (resetCache) {
                        parentFoldersToUpdate.set(parentFolderId, []);
                    } else {
                        const existingChildFolderIds =
                            options.readField<string[]>('childFolderIds', parentFolderRef) || [];
                        parentFoldersToUpdate.set(parentFolderId, [...existingChildFolderIds]);
                    }
                }

                const allChildFolderIds = parentFoldersToUpdate.get(parentFolderId);
                if (allChildFolderIds && allChildFolderIds.indexOf(folderId) == -1) {
                    allChildFolderIds.push(folderId);
                }
            }
        }
    });

    /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
     * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
     *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
    parentFoldersToUpdate.forEach((value, key) => {
        updateChildFolderIdsOnParent(value /*newChildFolderIds*/, key /* parentId */, options);
    });

    // populate childIdsForRootFolder with wellknown folder ids first, if the folder tree is not custom sorted
    const defaultChildIdsForRootFolder: string[] = [];
    if (!customSorted) {
        const foldersToShowFirst = getFoldersToShowFirst(mailboxInfo);
        foldersToShowFirst.forEach(folderToShowFirstWellKnownName => {
            const wellknownFolderId = folderNameIdMap[folderToShowFirstWellKnownName];
            if (wellknownFolderId) {
                defaultChildIdsForRootFolder.push(wellknownFolderId);
            }
        });
    }

    const rootChildFolderIds = options.readField<string[]>('childFolderIds', rootFolderRef) || [];
    for (let i = 0; i < rootChildFolderIds.length; i++) {
        const id = rootChildFolderIds[i] || null;
        if (id && defaultChildIdsForRootFolder.indexOf(id) == -1) {
            defaultChildIdsForRootFolder.push(id);
        }
    }

    updateChildFolderIdsOnParent(defaultChildIdsForRootFolder, rootFolderId, options);

    return [...existingFolderRefs, ...incomingFolderRefs];
}
