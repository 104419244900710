import type { MailboxInfo } from 'owa-client-types';
import copilotSettingsStore from '../store/store';
import { UserType } from '../services/types/serviceSettingTypes';
import { setItem } from 'owa-local-storage';

export default function isAIX00User(mailboxInfo: MailboxInfo) {
    const store = copilotSettingsStore(mailboxInfo);
    const isAIX00UserValue = store?.userTypes ? store.userTypes.includes(UserType.AIX00) : false;

    // Cache in local storage for options page initialization. This is a workaround
    // because our settings framework doesn't allow for dynamic loading of settings.
    if (isAIX00UserValue) {
        setItem(window, 'isAIX00User', isAIX00UserValue.toString());
    }

    return isAIX00UserValue;
}
