// extracted by mini-css-extract-plugin
export var alwaysShowPropertyIcons = "u6qq3";
export var hoverActionIcon = "BXibp";
export var icon = "kElPw";
export var iconBarContainer = "bvdCQ";
export var iconContainerNew = "hnzOS";
export var iconDisplay = "Q_UHS";
export var iconFull = "j87La";
export var iconHide = "W_gAr";
export var iconNoDisplay = "pPZMd";
export var propertyIcon = "gXxPA";