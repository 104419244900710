import { type CopilotThemePrompt } from 'owa-copilot-themes-types';

export default function getDefaultPrompt(): CopilotThemePrompt {
    return {
        type: 'City',
        location: 'SeattleWA',
        style: 'Realistic',
        refreshRate: 'None',
    };
}
