import { observer } from 'owa-mobx-react';
import { useComputed, useComputedValue } from 'owa-react-hooks/lib/useComputed';
import {
    addFavoriteLabel,
    newFavoritePickedText,
    renameFavoriteLabel,
} from './FavoritesList.locstring.json';
import loc, { format } from 'owa-localize';
import FavoriteNode from './FavoriteNode';
import { logUsage } from 'owa-analytics';
import {
    getStore as getSharedFavoritesStore,
    isMultiAccountsCombinedFavoritesEnabled,
} from 'owa-favorites';
import { FavoritesPicker } from 'owa-mail-favorites-picker';
import { FavoriteNodeContextMenu } from '../lazyFunctions';
import { setContextMenuState } from 'owa-mail-favorites-store/lib/actions/favoritesContextMenu';
import { showFindFavoritesPicker, getMailFavoritesViewState } from 'owa-mail-favorites-store';
import folderStore, { getEffectiveFolderDisplayName, getFolderTable } from 'owa-folders';
import { FAVORITE_FOLDERS_TREE_TYPE } from 'owa-folders-constants';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import React from 'react';
import { isFeatureEnabled } from 'owa-feature-flags';
import FavoriteNodeV2 from './roaming/FavoriteNodeV2';
import type { FavoriteFolderData } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { default as viewStateStore } from 'owa-mail-folder-store/lib/store/store';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import publicFolderFavoriteStore from 'owa-public-folder-favorite/lib/store/publicFolderFavoriteStore';
import { getMailboxInfoFromFolderId } from 'owa-mail-mailboxinfo';
import { FolderOperationNode } from 'owa-mail-folder-view';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

import { full, medium, compact, nodeHeight } from 'owa-tree-node/lib/components/NodeHeight.scss';

import classnames from 'owa-classnames';

export interface FavoritesListProps {
    mailboxInfo: MailboxInfo;
}

export default observer(function FavoritesList(props: FavoritesListProps) {
    return isFeatureEnabled('fp-memo-favoritesList') ? (
        <FavoritesListInner {...props} />
    ) : (
        <FavoritesListOld {...props} />
    );
}, 'FavoritesList');

const FavoritesListInner = observer(function FavoritesListInner(props: FavoritesListProps) {
    const favoritesStore = useComputed(() => {
        return getSharedFavoritesStore();
    });

    const isRoamingEnabled = isFeatureEnabled('tri-favorites-roaming');
    const mailboxKey = getIndexerValueForMailboxInfo(props.mailboxInfo);

    const orderedOutlookFavoritesIds = isRoamingEnabled
        ? isMultiAccountsCombinedFavoritesEnabled()
            ? favoritesStore.get().orderedCombinedOutlookFavoritesIds
            : favoritesStore.get().favoriteTreeData.get(mailboxKey)?.orderedOutlookFavoritesIds
        : null;

    if (isRoamingEnabled && !orderedOutlookFavoritesIds) {
        return null;
    }

    return (
        <>
            {isRoamingEnabled &&
                orderedOutlookFavoritesIds?.map(favoriteId => (
                    <FavoriteNodeWrapper key={favoriteId} favoriteId={favoriteId} />
                ))}
            {!isRoamingEnabled &&
                favoritesStore.get().orderedFavoritesNodeIds.map(nodeId => {
                    return <FavoriteNode key={nodeId} favoriteId={nodeId} ellipsesOnHover={true} />;
                })}
            <AddFavoriteWrapper mailboxInfo={props.mailboxInfo} />
            <FavoritesContextMenuWrapper mailboxInfo={props.mailboxInfo} />
        </>
    );
}, 'FavoritesListInner');

const shouldRenderNodeInEditMode = (favoriteId: string): boolean => {
    const folderTextFieldViewState = viewStateStore.folderTextFieldViewState;
    return (
        !!folderTextFieldViewState &&
        folderTextFieldViewState.folderId == favoriteId &&
        folderTextFieldViewState.folderTreeType === FAVORITE_FOLDERS_TREE_TYPE
    );
};

const FavoriteNodeWrapper = observer(function FavoriteNodeWrapper(props: { favoriteId: string }) {
    const { favoriteId } = props;
    const favoritesStore = useComputed(() => {
        return getSharedFavoritesStore();
    });
    const renderInEditMode = useComputedValue((): boolean => {
        return shouldRenderNodeInEditMode(favoriteId);
    }, [favoriteId]);

    if (renderInEditMode) {
        const folderId = (
            favoritesStore.get().outlookFavorites.get(favoriteId) as FavoriteFolderData
        ).folderId;
        const currentFolderName = getEffectiveFolderDisplayName(getFolderTable().get(folderId));
        return (
            <FolderOperationNode
                key="favoriteTextField"
                folderId={folderId}
                treeType={FAVORITE_FOLDERS_TREE_TYPE}
                nestDepth={0}
                operationType={'rename'}
                originalValue={currentFolderName}
                mailboxInfo={getMailboxInfoFromFolderId(folderId)}
                ariaAnnouncementLabel={renameFavoriteLabel}
            />
        );
    }
    return <FavoriteNodeV2 key={favoriteId} favoriteId={favoriteId} ellipsesOnHover={true} />;
}, 'FavoriteNodeWrapper');

const AddFavoriteWrapper = observer(function AddFavoriteWrapper(props: {
    mailboxInfo: MailboxInfo;
}) {
    const mailboxKey = getIndexerValueForMailboxInfo(props.mailboxInfo);
    const [favoriteUpdateText, setFavoriteUpdateText] = React.useState<string>('');

    const onNewFavoriteNodeClicked = React.useCallback(() => {
        showFindFavoritesPicker(mailboxKey, true /* shouldShow */);
        logUsage('FindFavoritesClicked');
    }, [mailboxKey]);

    const onItemSelected = React.useCallback((displayName: string) => {
        setFavoriteUpdateText(format(loc(newFavoritePickedText), displayName));
    }, []);

    const shouldShowFavoritesPicker =
        getMailFavoritesViewState(mailboxKey)?.shouldShowFindFavoritesPicker;
    return (
        <>
            {shouldShowFavoritesPicker && (
                <div
                    className={classnames(
                        getDensityModeCssClass(full, medium, compact),
                        nodeHeight
                    )}
                >
                    <FavoritesPicker
                        mailboxInfo={props.mailboxInfo}
                        onItemSelected={onItemSelected}
                    />
                </div>
            )}
            {!isFeatureEnabled('fp-remove-links') && !shouldShowFavoritesPicker && (
                <TreeNode
                    displayName={loc(addFavoriteLabel)}
                    isCustomActionNode={true}
                    isRootNode={false}
                    key={'newFavorite'}
                    onClick={onNewFavoriteNodeClicked}
                />
            )}
            <span className="screenReaderOnly" aria-live="assertive" aria-atomic="true">
                {favoriteUpdateText}
            </span>
        </>
    );
},
'AddFavoriteWrapper');

const FavoritesContextMenuWrapper = observer(function FavoritesContextMenuWrapper(props: {
    mailboxInfo: MailboxInfo;
}) {
    const mailboxKey = getIndexerValueForMailboxInfo(props.mailboxInfo);
    const contextMenuState = getMailFavoritesViewState(mailboxKey)?.contextMenuState;

    const onContextMenuDismissed = React.useCallback(() => {
        setContextMenuState(mailboxKey, null);
    }, [mailboxKey]);

    // Render the context menu if it is set to be visible
    if (!contextMenuState || contextMenuState.showRootMenu) {
        return null;
    }

    let folder;
    if (contextMenuState.folderId) {
        if (contextMenuState.nodeType == 5) {
            folder = publicFolderFavoriteStore.folderTable.get(contextMenuState.folderId);
        } else {
            folder = folderStore.folderTable.get(contextMenuState.folderId);
        }
    }

    return (
        <FavoriteNodeContextMenu
            anchorPoint={contextMenuState.anchor}
            nodeId={contextMenuState.nodeId}
            nodeType={contextMenuState.nodeType}
            folder={folder}
            onDismiss={onContextMenuDismissed}
            mailboxInfo={props.mailboxInfo}
        />
    );
},
'FavoritesContextMenuWrapper');

const FavoritesListOld = observer(function FavoritesListOld(props: FavoritesListProps) {
    const [favoriteUpdateText, setFavoriteUpdateText] = React.useState<string>('');
    const favoritesStore = useComputed(() => {
        return getSharedFavoritesStore();
    });

    const mailboxKey = getIndexerValueForMailboxInfo(props.mailboxInfo);
    const renderContextMenuIfOpen = (): JSX.Element | null => {
        const contextMenuState = getMailFavoritesViewState(mailboxKey)?.contextMenuState;
        // Render the context menu if it is set to be visible
        if (!contextMenuState || contextMenuState.showRootMenu) {
            return null;
        }

        let folder;
        if (contextMenuState.folderId) {
            if (contextMenuState.nodeType == 5) {
                folder = publicFolderFavoriteStore.folderTable.get(contextMenuState.folderId);
            } else {
                folder = folderStore.folderTable.get(contextMenuState.folderId);
            }
        }

        return (
            <FavoriteNodeContextMenu
                anchorPoint={contextMenuState.anchor}
                nodeId={contextMenuState.nodeId}
                nodeType={contextMenuState.nodeType}
                folder={folder}
                onDismiss={onContextMenuDismissed}
                mailboxInfo={props.mailboxInfo}
            />
        );
    };

    // remove when fp-remove-links is enabled for all users
    const renderAddNewFavoriteNode = (): JSX.Element => {
        // Render a TreeNode with empty text if we're showing the picker.
        // This is because we want to preserve the same space as the original 'New favorite' node
        // so that the UI doesn't jump, and blank out the text so it doesn't temporarily show while the
        // picker animates in.
        return (
            <TreeNode
                displayName={loc(addFavoriteLabel)}
                isCustomActionNode={true}
                isRootNode={false}
                key={'newFavorite'}
                onClick={onNewFavoriteNodeClicked}
            />
        );
    };

    const onContextMenuDismissed = () => {
        setContextMenuState(mailboxKey, null);
    };

    const onNewFavoriteNodeClicked = () => {
        showFindFavoritesPicker(mailboxKey, true /* shouldShow */);
        logUsage('FindFavoritesClicked');
    };

    const renderOutlookFavorites = (): JSX.Element[] | null => {
        let orderedOutlookFavoritesIds: string[] | undefined;

        if (isMultiAccountsCombinedFavoritesEnabled()) {
            orderedOutlookFavoritesIds = favoritesStore.get().orderedCombinedOutlookFavoritesIds;
        } else {
            orderedOutlookFavoritesIds = favoritesStore
                .get()
                .favoriteTreeData.get(mailboxKey)?.orderedOutlookFavoritesIds;
        }

        if (!orderedOutlookFavoritesIds) {
            return null;
        }

        logUsage('TnS_FavoriteFoldersRendered', [
            orderedOutlookFavoritesIds.length,
            getIndexerValueForMailboxInfo(props.mailboxInfo) ===
                getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo()), // is primary account
        ]);

        return orderedOutlookFavoritesIds.map(favoriteId => {
            const folderTextFieldViewState = viewStateStore.folderTextFieldViewState;
            if (
                folderTextFieldViewState &&
                folderTextFieldViewState.folderId == favoriteId &&
                folderTextFieldViewState.folderTreeType === FAVORITE_FOLDERS_TREE_TYPE
            ) {
                const folderId = (
                    favoritesStore.get().outlookFavorites.get(favoriteId) as FavoriteFolderData
                ).folderId;
                const currentFolderName = getEffectiveFolderDisplayName(
                    getFolderTable().get(folderId)
                );
                return (
                    <FolderOperationNode
                        key="favoriteTextField"
                        folderId={folderId}
                        treeType={FAVORITE_FOLDERS_TREE_TYPE}
                        nestDepth={0}
                        operationType={'rename'}
                        originalValue={currentFolderName}
                        mailboxInfo={getMailboxInfoFromFolderId(folderId)}
                        ariaAnnouncementLabel={renameFavoriteLabel}
                    />
                );
            }
            return (
                <FavoriteNodeV2 key={favoriteId} favoriteId={favoriteId} ellipsesOnHover={true} />
            );
        });
    };
    const renderFavoriteNodes = (): JSX.Element[] => {
        return favoritesStore.get().orderedFavoritesNodeIds.map(nodeId => {
            return <FavoriteNode key={nodeId} favoriteId={nodeId} ellipsesOnHover={true} />;
        });
    };

    const renderFindFavoritesComponents = (): JSX.Element | null => {
        if (getMailFavoritesViewState(mailboxKey)?.shouldShowFindFavoritesPicker) {
            return renderFavoritesPicker();
        } else if (!isFeatureEnabled('fp-remove-links')) {
            return renderAddNewFavoriteNode();
        } else {
            return null;
        }
    };
    const renderFavoritesPicker = (): JSX.Element => {
        return (
            <div className={classnames(getDensityModeCssClass(full, medium, compact), nodeHeight)}>
                <FavoritesPicker mailboxInfo={props.mailboxInfo} onItemSelected={onItemSelected} />
            </div>
        );
    };
    const onItemSelected = (displayName: string) => {
        setFavoriteUpdateText(format(loc(newFavoritePickedText), displayName));
    };
    return (
        <>
            {isFeatureEnabled('tri-favorites-roaming')
                ? renderOutlookFavorites()
                : renderFavoriteNodes()}
            {renderContextMenuIfOpen()}
            {renderFindFavoritesComponents()}
            <span className="screenReaderOnly" aria-live="assertive" aria-atomic="true">
                {favoriteUpdateText}
            </span>
        </>
    );
}, 'FavoritesListOld');
