import type {
    ClientCalendarEvent,
    BusyType,
    EditOnSend,
    RequestedAttendanceMode,
    ProviderPropertiesEntry,
} from 'owa-graph-schema';
import {
    IsAppendOnSendExtendedProperty,
    IsPrependOnSendExtendedProperty,
} from 'owa-calendar-types/lib/types/IsEditOnSendExtendedProperty';
import extendedPropertyType from 'owa-service/lib/factory/extendedPropertyType';
import { IsBookedFreeBlocksExtendedProperty } from 'owa-calendar-types/lib/types/IsBookedFreeBlocksExtendedProperty';
import { CollabSpaceExtendedProperty } from 'owa-calendar-types/lib/types/CollabSpaceExtendedProperty';
import {
    ClpLabelExtendedProperty,
    ModificationExtendedProperty,
} from 'owa-calendar-types/lib/types/ClpLabelExtendedProperty';
import type CalendarItem from 'owa-service/lib/contract/CalendarItem';
import { convertEditOnSendToOws } from 'convert-edit-on-send/lib/convertEditOnSendToOws';
import { convertInboxRemindersToOws } from './convertInboxRemindersToOws';
import { convertRecurrenceTypeToOws } from './convertRecurrenceTypeToOws';
import { convertCalendarItemTypeToOws } from './convertCalendarItemTypeToOws';
import { convertDocLinkToOws, convertAttachmentToOws } from 'convert-attachment';
import { getEWSDateStringFromIsoDateString } from 'owa-datetime-formatters';
import { convertBodyContentGqlTypeToOws } from './convertBodyContentGqlTypeToOws';
import { ConflictResolutionExtendedProperty } from 'owa-calendar-types/lib/types/ConflictResolutionExtendedProperty';
import { VirtualAppointmentExtendedProperty } from 'owa-calendar-types/lib/types/VirtualAppointmentExtendedProperty';
import { VirtualEventExtendedProperty } from 'owa-calendar-types/lib/types/VirtualEventExtendedProperty';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getExtendedProperties as getTeamsMeetingProviderExtendedProperties } from 'teams-meeting-provider';
import { MeetingTemplateIdExtendedProperty } from 'owa-calendar-types/lib/types/MeetingTemplateIdExtendedProperty';

/**
 * Converts a CalendarEvent instance to CalendarItem. This function for the most part will be called
 * only within functions that deal directly with making service requests. Other code should only use
 * CalendarEvent instances.
 * @param calendarEvent The CalendarEvent object
 * @returns The converted CalendarItem instance
 */
export function mapGqlCalendarEventToOWSCalendarItem(
    calendarEvent: ClientCalendarEvent
): CalendarItem {
    // Additional (Extended) Properties
    const extendedProperties = [];
    if (calendarEvent.AppendOnSend && calendarEvent.AppendOnSend.length > 0) {
        extendedProperties.push(
            extendedPropertyType({
                ExtendedFieldURI: IsAppendOnSendExtendedProperty,
                Value: JSON.stringify(
                    calendarEvent.AppendOnSend?.map(appendData =>
                        appendData ? convertEditOnSendToOws(appendData as EditOnSend) : appendData
                    )
                ),
            })
        );
    }

    if (calendarEvent.PrependOnSend && calendarEvent.PrependOnSend.length > 0) {
        extendedProperties.push(
            extendedPropertyType({
                ExtendedFieldURI: IsPrependOnSendExtendedProperty,
                Value: JSON.stringify(
                    calendarEvent.PrependOnSend?.map(prependData =>
                        prependData
                            ? convertEditOnSendToOws(prependData as EditOnSend)
                            : prependData
                    )
                ),
            })
        );
    }

    if (calendarEvent.IsBookedFreeBlocks) {
        extendedProperties.push(
            extendedPropertyType({
                ExtendedFieldURI: IsBookedFreeBlocksExtendedProperty,
                Value: calendarEvent.IsBookedFreeBlocks.toString(), // need to coerce boolean to string to satisfy server requirements
            })
        );
    }

    if (calendarEvent.ConflictResolution) {
        extendedProperties.push(
            extendedPropertyType({
                ExtendedFieldURI: ConflictResolutionExtendedProperty,
                Value: calendarEvent.ConflictResolution,
            })
        );
    }

    if (calendarEvent.VirtualAppointment) {
        extendedProperties.push(
            extendedPropertyType({
                ExtendedFieldURI: VirtualAppointmentExtendedProperty,
                Value: JSON.stringify(calendarEvent.VirtualAppointment),
            })
        );
    }

    if (calendarEvent.VirtualEvent) {
        extendedProperties.push(
            extendedPropertyType({
                ExtendedFieldURI: VirtualEventExtendedProperty,
                Value: JSON.stringify(calendarEvent.VirtualEvent),
            })
        );
    }

    if (calendarEvent.MeetingTemplateId) {
        extendedProperties.push(
            extendedPropertyType({
                ExtendedFieldURI: MeetingTemplateIdExtendedProperty,
                Value: calendarEvent.MeetingTemplateId,
            })
        );
    }

    if (calendarEvent.CollabSpace) {
        extendedProperties.push(
            extendedPropertyType({
                ExtendedFieldURI: CollabSpaceExtendedProperty,
                Value: calendarEvent.CollabSpace,
            })
        );
    }
    if (calendarEvent.ClpLabelProperty) {
        extendedProperties.push(
            extendedPropertyType({
                ExtendedFieldURI: ClpLabelExtendedProperty,
                Value: calendarEvent.ClpLabelProperty,
            })
        );
    }
    if (calendarEvent.ClpLabelModificationHeader) {
        extendedProperties.push(
            extendedPropertyType({
                ExtendedFieldURI: ModificationExtendedProperty,
                Value: calendarEvent.ClpLabelModificationHeader,
            })
        );
    }
    if (
        isFeatureEnabled('cal-onlineMeetingService') &&
        calendarEvent.OnlineMeeting &&
        calendarEvent.IsOnlineMeeting
    ) {
        const providerProperties = calendarEvent.OnlineMeeting
            ?.providerProperties as ProviderPropertiesEntry[];
        extendedProperties.push(...getTeamsMeetingProviderExtendedProperties(providerProperties));
    }

    let requestedAttendanceMode = undefined;
    if (
        isFeatureEnabled(
            'cal-meeting-inPerson',
            undefined /* mailboxInfo */,
            true /* dontThrowErrorIfNotInitialized */
        )
    ) {
        requestedAttendanceMode = {
            RequestedAttendanceMode:
                calendarEvent.RequestedAttendanceMode as RequestedAttendanceMode,
        };
    }

    return {
        __type: 'CalendarItem:#Exchange',
        ...calendarEvent,
        Start: calendarEvent.Start
            ? getEWSDateStringFromIsoDateString(calendarEvent.Start)
            : undefined,
        End: calendarEvent.End ? getEWSDateStringFromIsoDateString(calendarEvent.End) : undefined,
        LastModifiedTime: calendarEvent.LastModifiedTime
            ? getEWSDateStringFromIsoDateString(calendarEvent.LastModifiedTime)
            : undefined,
        AppointmentReplyTime: calendarEvent.AppointmentReplyTime
            ? getEWSDateStringFromIsoDateString(calendarEvent.AppointmentReplyTime)
            : undefined,
        FreeBusyType: calendarEvent.FreeBusyType as BusyType,
        CharmId: calendarEvent.CharmId ?? undefined, // Defaulting charmId to undefined in case the current value is null. null is not a valid value for charmId and should never be sent to OWS.
        IsDraft: calendarEvent.IsDraft != null ? calendarEvent.IsDraft : undefined,
        IsCoOrganizer:
            calendarEvent.IsCoOrganizer != null ? calendarEvent.IsCoOrganizer : undefined,
        IsRoomRequested: calendarEvent?.IsRoomRequested
            ? calendarEvent?.IsRoomRequested
            : undefined,
        ExtendedProperty: extendedProperties,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (110,59): Argument of type '(inboxReminder: InboxReminder) => InboxReminderType' is not assignable to parameter of type '(value: Maybe<InboxReminder>, index: number, array: Maybe<InboxReminder>[]) => InboxReminderType'.
        // @ts-expect-error
        InboxReminders: calendarEvent.InboxReminders?.map(convertInboxRemindersToOws),
        DocLinks: calendarEvent.DocLinks?.map(convertDocLinkToOws),
        Recurrence: calendarEvent.Recurrence
            ? convertRecurrenceTypeToOws(calendarEvent.Recurrence)
            : undefined,
        Location: calendarEvent.Location
            ? {
                  DisplayName: calendarEvent.Location,
              }
            : undefined,
        Attachments: calendarEvent.Attachments?.map(attachment =>
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2345 (127,36): Argument of type 'Maybe<AttachmentTypeUnion>' is not assignable to parameter of type 'BasicAttachment | ReferenceAttachment'.
            // @ts-expect-error
            convertAttachmentToOws(attachment)
        ),
        CalendarItemType: calendarEvent.CalendarItemType
            ? convertCalendarItemTypeToOws(calendarEvent.CalendarItemType)
            : undefined,
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (136,9): Type '{ __type: string; Id: string; ChangeKey: Maybe<string> | undefined; } | undefined' is not assignable to type 'ItemId | undefined'.
        // @ts-expect-error
        ItemId: calendarEvent.ItemId?.Id
            ? {
                  __type: 'ItemId:#Exchange',
                  Id: calendarEvent.ItemId.Id,
                  ChangeKey: calendarEvent.ItemId.ChangeKey,
              }
            : undefined,
        ParentFolderId: calendarEvent.ParentFolderId?.Id
            ? {
                  Id: calendarEvent.ParentFolderId.Id,
                  ChangeKey: calendarEvent.ParentFolderId?.ChangeKey
                      ? calendarEvent.ParentFolderId.ChangeKey
                      : undefined,
              }
            : undefined,
        SeriesMasterItemId: calendarEvent.SeriesMasterItemId?.Id
            ? {
                  Id: calendarEvent.SeriesMasterItemId.Id,
                  ChangeKey: calendarEvent.SeriesMasterItemId?.ChangeKey
                      ? calendarEvent.SeriesMasterItemId.ChangeKey
                      : undefined,
              }
            : undefined,
        SkypeTeamsProperties: calendarEvent.SkypeTeamsProperties
            ? JSON.stringify(calendarEvent.SkypeTeamsProperties)
            : undefined,
        SeriesId: calendarEvent.SeriesId ?? undefined,
        Body: calendarEvent.Body ? convertBodyContentGqlTypeToOws(calendarEvent.Body) : undefined,
        ...requestedAttendanceMode,
        IsFollowableMeeting: calendarEvent.IsFollowableMeeting ?? false,
    };
}
