import type {
    ClientCalendarEvent as GqlCalendarEvent,
    EditOnSend as GqlEditOnSend,
    Attendee,
    BusyType as GqlBusyType,
    ResponseType,
    ConflictResolutionType,
    RequestedAttendanceMode,
    VirtualAppointmentType,
    VirtualEventType,
    OnlineMeeting,
} from 'owa-graph-schema';
import type CalendarItem from 'owa-service/lib/contract/CalendarItem';
import getDisplayPreviewText from './utils/getDisplayPreviewText';
import type { MailboxInfo } from 'owa-client-types';
import { cleanTimeZoneId } from 'owa-datetime-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getConfig } from 'owa-service/lib/config';
import { getDisplayDateFromEwsDate, getISOString, parse, type OwaDate } from 'owa-datetime';
import {
    IsAppendOnSendExtendedProperty,
    IsPrependOnSendExtendedProperty,
} from 'owa-calendar-types/lib/types/IsEditOnSendExtendedProperty';
import { IsBookedFreeBlocksExtendedProperty } from 'owa-calendar-types/lib/types/IsBookedFreeBlocksExtendedProperty';
import { CollabSpaceExtendedProperty } from 'owa-calendar-types/lib/types/CollabSpaceExtendedProperty';
import { ConflictResolutionExtendedProperty } from 'owa-calendar-types/lib/types/ConflictResolutionExtendedProperty';
import { VirtualAppointmentExtendedProperty } from 'owa-calendar-types/lib/types/VirtualAppointmentExtendedProperty';
import {
    ClpLabelExtendedProperty,
    ModificationExtendedProperty,
} from 'owa-calendar-types/lib/types/ClpLabelExtendedProperty';
import { convertEditOnSendToGql } from 'convert-edit-on-send/lib/convertEditOnSendToGql';
import { convertInboxRemindersToGql } from './convertInboxRemindersToGql';
import { convertRecurrenceTypeToGql } from './convertRecurrenceTypeToGql';
import { convertDocLinkToGql, convertAttachmentToGql } from 'convert-attachment';
import type AttendeeType from 'owa-service/lib/contract/AttendeeType';
import { getLocationDisplayWithAddressText } from 'owa-location-display-text';
import { VirtualEventExtendedProperty } from 'owa-calendar-types/lib/types/VirtualEventExtendedProperty';
import {
    SchedulingServiceMeetingOptionsUrlExtendedProperty,
    SchedulingServiceUpdateUrlExtendedProperty,
} from 'teams-meeting-provider';
import { MeetingTemplateIdExtendedProperty } from 'owa-calendar-types/lib/types/MeetingTemplateIdExtendedProperty';

/**
 * Converts a CalendarItem instance to CalendarEvent. This function will be used mostly from service
 * functions to convert the returned CalendarItem service objects to CalendarEvent instances for
 * consumption by the application.
 * @param calendarItem The calendar item (service object) to convert
 * @param mailboxInfo The mailbox info for the calendar event
 * @returns The converted CalendarEvent object
 */
export function mapOWSCalendarItemToGqlCalendarEvent(
    calendarItem: CalendarItem,
    mailboxInfo?: MailboxInfo
): GqlCalendarEvent {
    // Additional (Extended) Properties
    let appendOnSend: GqlEditOnSend[] = [];
    let prependOnSend: GqlEditOnSend[] = [];
    let isBookedFreeBlocks: boolean = false;
    let collabSpace: string | null = null;
    let clpLabelProperty: string | null = null;
    let clpLabelModificationHeader: string | null = null;
    let conflictResolution: ConflictResolutionType | null = null;
    let virtualAppointment: VirtualAppointmentType | null = null;
    let virtualEvent: VirtualEventType | null = null;
    let meetingTemplateId: string | null = null;
    let onlineMeeting: OnlineMeeting = {};

    if (calendarItem?.ExtendedProperty) {
        let matchingProperty = calendarItem.ExtendedProperty.filter(
            item =>
                item.ExtendedFieldURI?.PropertyName === IsAppendOnSendExtendedProperty.PropertyName
        )[0];

        if (matchingProperty?.Value) {
            try {
                appendOnSend = JSON.parse(matchingProperty.Value)?.map(convertEditOnSendToGql);
            } catch {
                appendOnSend = [];
            }
        }

        matchingProperty = calendarItem.ExtendedProperty.filter(
            item =>
                item.ExtendedFieldURI?.PropertyName === IsPrependOnSendExtendedProperty.PropertyName
        )[0];

        if (matchingProperty?.Value) {
            try {
                prependOnSend = JSON.parse(matchingProperty.Value)?.map(convertEditOnSendToGql);
            } catch {
                prependOnSend = [];
            }
        }

        matchingProperty = calendarItem.ExtendedProperty.filter(
            item =>
                item.ExtendedFieldURI?.PropertyName ===
                ConflictResolutionExtendedProperty.PropertyName
        )[0];
        if (matchingProperty?.Value) {
            try {
                conflictResolution = JSON.parse(matchingProperty.Value);
            } catch {
                conflictResolution = null;
            }
        }

        matchingProperty = calendarItem.ExtendedProperty.filter(
            item =>
                item.ExtendedFieldURI?.PropertyName ===
                VirtualAppointmentExtendedProperty.PropertyName
        )[0];
        if (matchingProperty?.Value) {
            try {
                virtualAppointment = JSON.parse(matchingProperty.Value);
            } catch {
                virtualAppointment = null;
            }
        }

        matchingProperty = calendarItem.ExtendedProperty.filter(
            item =>
                item.ExtendedFieldURI?.PropertyName === VirtualEventExtendedProperty.PropertyName
        )[0];
        if (matchingProperty?.Value) {
            try {
                virtualEvent = JSON.parse(matchingProperty.Value);
            } catch {
                virtualEvent = null;
            }
        }

        matchingProperty = calendarItem.ExtendedProperty.filter(
            item =>
                item.ExtendedFieldURI?.PropertyName ===
                MeetingTemplateIdExtendedProperty.PropertyName
        )[0];
        if (matchingProperty?.Value) {
            meetingTemplateId = matchingProperty.Value;
        }

        if (
            calendarItem.ExtendedProperty.some(
                item =>
                    item.ExtendedFieldURI?.PropertyName ===
                        IsBookedFreeBlocksExtendedProperty.PropertyName && !!item.Value
            )
        ) {
            isBookedFreeBlocks = true;
        }

        matchingProperty = calendarItem.ExtendedProperty.filter(
            item => item.ExtendedFieldURI?.PropertyName === CollabSpaceExtendedProperty.PropertyName
        )[0];
        if (matchingProperty?.Value) {
            collabSpace = matchingProperty.Value;
        }

        matchingProperty = calendarItem.ExtendedProperty.filter(
            item => item.ExtendedFieldURI?.PropertyName === ClpLabelExtendedProperty.PropertyName
        )[0];
        if (matchingProperty?.Value) {
            clpLabelProperty = matchingProperty.Value;
        }

        matchingProperty = calendarItem.ExtendedProperty.filter(
            item =>
                item.ExtendedFieldURI?.PropertyName === ModificationExtendedProperty.PropertyName
        )[0];
        if (matchingProperty?.Value) {
            clpLabelModificationHeader = matchingProperty.Value;
        }

        // Extended Properties for Meeting Options URL
        if (isFeatureEnabled('cal-onlineMeetingService')) {
            matchingProperty = calendarItem.ExtendedProperty.filter(
                item =>
                    item.ExtendedFieldURI?.PropertyName ===
                    SchedulingServiceMeetingOptionsUrlExtendedProperty?.PropertyName
            )[0];
            const providerProperties = [];
            if (matchingProperty?.Value) {
                providerProperties.push({
                    key: 'links.options',
                    value: matchingProperty.Value,
                });
            }
            matchingProperty = calendarItem.ExtendedProperty.filter(
                item =>
                    item.ExtendedFieldURI?.PropertyName ===
                    SchedulingServiceUpdateUrlExtendedProperty?.PropertyName
            )[0];
            if (matchingProperty?.Value) {
                providerProperties.push({
                    key: 'links.update',
                    value: matchingProperty.Value,
                });
            }
            onlineMeeting = {
                providerProperties,
            };
        }
    }
    let eventStart: OwaDate | null = null;
    let eventEnd: OwaDate | null = null;

    if (calendarItem?.Start) {
        eventStart = parseCalendarItemDate(calendarItem.Start);
    }
    if (calendarItem?.End) {
        eventEnd = parseCalendarItemDate(calendarItem.End);
    }

    let recurrence = null;
    // calendarItem passed in from different path is different. It can be a partial graphQL type or pure service calendarItem
    // will clean it up when all OWS types are replaced and tracisiton to app grateway is done
    // https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/122681
    if (calendarItem.Recurrence) {
        if ('__typename' in calendarItem.Recurrence) {
            recurrence = calendarItem.Recurrence;
        } else {
            recurrence = convertRecurrenceTypeToGql(calendarItem.Recurrence);
        }
    }

    let lastModifiedTime: OwaDate | null = null;
    let appointmentReplyTime: OwaDate | null = null;

    if (calendarItem.LastModifiedTime) {
        lastModifiedTime = parseCalendarItemDate(calendarItem.LastModifiedTime);
    }
    if (calendarItem.AppointmentReplyTime) {
        appointmentReplyTime = parseCalendarItemDate(calendarItem.AppointmentReplyTime);
    }

    let requestedAttendanceMode = undefined;
    if (
        isFeatureEnabled(
            'cal-meeting-inPerson',
            undefined /* mailboxInfo */,
            true /* dontThrowErrorIfNotInitialized */
        )
    ) {
        requestedAttendanceMode = {
            RequestedAttendanceMode:
                calendarItem.RequestedAttendanceMode as RequestedAttendanceMode,
        };
    }

    // Copy then overwrite with proper conversions
    const ev = {
        ...{
            id: calendarItem.ItemId?.Id ?? '',
            ItemId: calendarItem.ItemId
                ? {
                      Id: calendarItem.ItemId.Id,
                      ChangeKey: calendarItem.ItemId.ChangeKey,
                      mailboxInfo,
                  }
                : undefined,
            ParentFolderId: calendarItem.ParentFolderId
                ? {
                      Id: calendarItem.ParentFolderId.Id,
                      ChangeKey: calendarItem.ParentFolderId.ChangeKey,
                      mailboxInfo,
                  }
                : undefined,
            Start: eventStart ? getISOString(eventStart) : undefined,
            End: eventEnd ? getISOString(eventEnd) : undefined,
            StartTimeZoneId: cleanTimeZoneId(calendarItem.StartTimeZoneId),
            EndTimeZoneId: cleanTimeZoneId(calendarItem.EndTimeZoneId),
            LastModifiedTime: lastModifiedTime ? getISOString(lastModifiedTime) : undefined,
            FreeBusyType: calendarItem.FreeBusyType as GqlBusyType,
            ResponseType: calendarItem.ResponseType as ResponseType,
            AppointmentReplyTime: appointmentReplyTime
                ? getISOString(appointmentReplyTime)
                : undefined,
            SeriesMasterItemId: calendarItem.SeriesMasterItemId
                ? {
                      Id: calendarItem.SeriesMasterItemId.Id,
                      ChangeKey: calendarItem.SeriesMasterItemId.ChangeKey,
                      mailboxInfo,
                  }
                : null,

            // https://outlookweb.visualstudio.com/Outlook%20Web/_workitems/edit/43633
            // Treat any "private" group calendar events as if it was a normal event.
            // The only drawback I see with this is that IF one creates such a "private" event, say,
            // using Desktop Outlook, then it will show the lock icon and we won't. I don't think this
            // is a big deal, but if we decide that it is, then we will need to modify any other code that
            // depends on this property, beginning with owa-calendar-event-capabilities canEdit/canModify.
            Sensitivity:
                mailboxInfo?.type == 'GroupMailbox' && calendarItem.Sensitivity == 'Private'
                    ? 'Normal'
                    : calendarItem.Sensitivity,
            Preview: calendarItem.Preview ? getDisplayPreviewText(calendarItem.Preview) : undefined,
            RequiredAttendees: calendarItem.RequiredAttendees?.map(getGqlAttendee),
            Resources: calendarItem.Resources?.map(getGqlAttendee),
            OptionalAttendees: calendarItem.OptionalAttendees?.map(getGqlAttendee),

            // Additional (Extended) Properties
            IsBookedFreeBlocks: isBookedFreeBlocks,
            CollabSpace: collabSpace,
            ClpLabelProperty: clpLabelProperty,
            ClpLabelModificationHeader: clpLabelModificationHeader,
            InboxReminders: convertInboxRemindersToGql(calendarItem.InboxReminders),
            AppendOnSend: appendOnSend,
            PrependOnSend: prependOnSend,
            DocLinks: calendarItem.DocLinks?.map(convertDocLinkToGql),
            Recurrence: recurrence,
            Attachments: calendarItem.Attachments?.map(convertAttachmentToGql),
            SkypeTeamsProperties:
                calendarItem.SkypeTeamsProperties &&
                parseSkypeTeamsProperties(calendarItem.SkypeTeamsProperties),

            // 1:1 mappings
            Body: calendarItem.Body,
            Categories: calendarItem.Categories,
            ConversationId: calendarItem.ConversationId,
            DateTimeCreated: calendarItem.DateTimeCreated,
            EffectiveRights: calendarItem.EffectiveRights,
            EntityNamesMap: calendarItem.EntityNamesMap,
            HasAttachments: calendarItem.HasAttachments,
            HasBlockedImages: calendarItem.HasBlockedImages,
            InReplyTo: calendarItem.InReplyTo,
            InstanceKey: calendarItem.InstanceKey,
            IsDraft: calendarItem.IsDraft,
            ReminderIsSet: calendarItem.ReminderIsSet,
            ReminderMinutesBeforeStart: calendarItem.ReminderMinutesBeforeStart,
            Subject: calendarItem.Subject,
            TailoredXpEntities: calendarItem.TailoredXpEntities,
            AppointmentReplyName: calendarItem.AppointmentReplyName,
            CalendarItemType: calendarItem.CalendarItemType,
            CalendarEventClassifications: calendarItem.CalendarEventClassifications,
            CharmId: calendarItem.CharmId,
            ClientSeriesId: calendarItem.ClientSeriesId,
            DoNotForwardMeeting: calendarItem.DoNotForwardMeeting,
            ExtractionSourceId: calendarItem.ExtractionSourceId,
            HideAttendees: calendarItem.HideAttendees,
            IntendedFreeBusyStatus: calendarItem.IntendedFreeBusyStatus,
            IsAllDayEvent: calendarItem.IsAllDayEvent,
            IsMeeting: calendarItem.IsMeeting,
            IsCancelled: calendarItem.IsCancelled,
            IsOnlineMeeting: calendarItem.IsOnlineMeeting,
            IsOrganizer: calendarItem.IsOrganizer,
            IsCoOrganizer: calendarItem.IsCoOrganizer,
            IsRecurring: calendarItem.IsRecurring,
            IsResponseRequested: calendarItem.IsResponseRequested,
            IsRoomRequested: calendarItem.IsRoomRequested,
            IsSeriesCancelled: calendarItem.IsSeriesCancelled,
            Location: calendarItem?.Location
                ? getLocationDisplayWithAddressText(
                      calendarItem.Location?.DisplayName || '',
                      calendarItem.Location?.PostalAddress
                  )
                : undefined,
            Locations: calendarItem.Locations,
            MeetingRequestWasSent: calendarItem.MeetingRequestWasSent,
            OnlineMeetingProvider: calendarItem.OnlineMeetingProvider,
            OnlineMeetingJoinUrl: calendarItem.OnlineMeetingJoinUrl,
            OnlineMeetingConferenceId: calendarItem.OnlineMeetingConferenceId,
            OnlineMeetingTollNumber: calendarItem.OnlineMeetingTollNumber,
            OnlineMeetingTollFreeNumbers: calendarItem.OnlineMeetingTollFreeNumbers,
            Organizer: calendarItem.Organizer,
            SeriesId: calendarItem.SeriesId,
            UID: calendarItem.UID,
            AssociatedTasks: calendarItem.AssociatedTasks,
            ResponseMode: calendarItem.ResponseMode,
            Attendance: calendarItem.Attendance,
            MuteNotifications: calendarItem.MuteNotifications,
            RightsManagementLicenseData: calendarItem.RightsManagementLicenseData,
            AllowNewTimeProposal: calendarItem.AllowNewTimeProposal,
            ConflictResolution: conflictResolution,
            VirtualAppointment: virtualAppointment,
            VirtualEvent: virtualEvent,
            MeetingTemplateId: meetingTemplateId,
            ...requestedAttendanceMode,
            OnlineMeeting: onlineMeeting,
            AttendanceSource: calendarItem.AttendanceSource,
            IsFollowableMeeting: calendarItem.IsFollowableMeeting,
        },
    } as GqlCalendarEvent;

    return ev;
}

function parseSkypeTeamsProperties(skypeTeamsProperties: string) {
    try {
        return JSON.parse(skypeTeamsProperties);
    } catch (e) {
        return undefined;
    }
}

export function parseCalendarItemDate(date: string) {
    // All production calls to calendarEvent() are done in response to
    // an EWS call or notification, which returns data in the user's time zone.
    // We can parse making this assumption, to handle dates outside the known DST
    // ranges without losing the offset returned by the server.
    if (
        isFeatureEnabled(
            'cal-assumeTzOffsetFromCalendarItem',
            undefined /* mailboxInfo */,
            true /* dontThrowErrorIfNotInitialized */
        )
    ) {
        const assumeDatesAreInThisTz = getConfig().timezone as string;
        const assumeOffsetFromStringIsCorrect = !!assumeDatesAreInThisTz;
        return parse(assumeDatesAreInThisTz, date, assumeOffsetFromStringIsCorrect);
    }
    return getDisplayDateFromEwsDate(date);
}

function getGqlAttendee(attendee: AttendeeType): Attendee {
    return {
        Mailbox: attendee.Mailbox ?? undefined,
        ResponseType: attendee.ResponseType ?? undefined,
        LastResponseTime: attendee.LastResponseTime ?? undefined,
        ResponseComment: attendee.ResponseComment ?? undefined,
        ProposedStart: attendee.ProposedStart ?? undefined,
        ProposedEnd: attendee.ProposedEnd ?? undefined,
        IsCoauthor: attendee.IsCoauthor ?? false,
        ResponseMode: attendee.ResponseMode ?? undefined,
        Attendance: attendee.Attendance ?? undefined,
        AttendanceSource: attendee.AttendanceSource ?? undefined,
    };
}
