import { identify } from 'owa-apollo';

/**
 * Return the Id to lookup the folder fragment in the cache
 * @param folderId the id of the folder
 */
export function getFolderTypeId(folderId: string) {
    const folder = {
        __typename: 'MailFolder',
        id: folderId,
    };

    return identify(folder);
}
