import isSameMailboxInfo from 'owa-account-source-list-store/lib/utils/isSameMailboxInfo';
import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';
import type { MailboxInfo } from 'owa-client-types';

export default function compareMailboxInfos(original: MailboxInfo, fromPackage: MailboxInfo) {
    if (!isSameMailboxInfo(original, fromPackage, true /*skipGuidComparison*/)) {
        debugErrorThatWillShowErrorPopupOnly(
            'MailboxInfo from package and original MailboxInfo are not equal'
        );
    }
}
