import { isSuccessStatusCode } from 'owa-http-status-codes';
import { makeGetRequest } from 'owa-ows-gateway';
import type { MailboxInfo } from 'owa-client-types';
import type CopilotPrioritizeSettings from '../types/CopilotPrioritizeSettings';
import { SETTINGS_URL } from './constants';

export default async function fetchCopilotPrioritizeSettingsFromService(
    mailboxInfo: MailboxInfo
): Promise<CopilotPrioritizeSettings | undefined> {
    let response: Response;
    try {
        response = await makeGetRequest(
            SETTINGS_URL,
            undefined,
            true /* returnFullResponse */,
            undefined,
            undefined,
            true /* includeCredentials */,
            'CopilotPrioritizeSettings',
            mailboxInfo,
            undefined /* isHttpCachingExpected */,
            undefined /* retryCount */,
            true /* preferIdpToken */
        );
    } catch (error) {
        return undefined;
    }

    if (!!response && isSuccessStatusCode(response.status)) {
        const responseObj: any = await response.json();
        const priortizationSettings: CopilotPrioritizeSettings =
            responseObj?.inboxPrioritizationPreferences;
        return priortizationSettings ?? undefined;
    } else {
        return undefined;
    }
}
