import { lazySetupMailListItemContainerKeys } from './lazy/lazyListViewModule';
import type { MailListItemDataProps, MailListTableProps } from 'owa-mail-list-item-shared';
import {
    onMailListItemClickHandler,
    MailListItemContainerContextProvider,
} from 'owa-mail-list-item-shared';
import { isFeatureEnabled } from 'owa-feature-flags';
import { observer } from 'owa-mobx-react';
import type { AriaProperties } from 'owa-accessibility';
import { AriaRoles, generateDomPropertiesForAria } from 'owa-accessibility';
import Draggable from 'owa-dnd/lib/components/Draggable';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { getIsMsHighContrast } from 'owa-high-contrast';
import { useKeydownHandler, useLazyKeydownHandler } from 'owa-hotkeys';
import loc from 'owa-localize';
import { noSubject } from 'owa-locstrings/lib/strings/nosubject.locstring.json';
import { type FocusComponent, setFocusToSynchronous, tabIndex } from 'owa-mail-focus-manager';
import { isReadingPanePositionOff as getIsReadingPanePositionOff } from 'owa-mail-layout';
import PropertyIcons from 'owa-mail-list-actions/lib/utils/conversationProperty/PropertyIcons';
import { showMailItemContextMenu } from 'owa-mail-list-actions/lib/actions/itemContextMenuActions';
import MailListContextMenuSource from 'owa-mail-list-store/lib/store/schema/MailListContextMenuSource';
import MailListContextMenuType from 'owa-mail-list-store/lib/store/schema/MailListContextMenuType';
import { MailListItemExpansion } from '../lazyFunctions';
import mailStore from 'owa-mail-store/lib/store/Store';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { getAnchorForContextMenu } from 'owa-positioning';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getBigHoverAction } from 'owa-surface-actions/lib/utils/getBigHoverAction';
import type { ITouchHandlerParams } from 'owa-touch-handler';
import { touchHandler } from 'owa-touch-handler';
import React from 'react';
import type { TableView } from 'owa-mail-list-store';
import {
    type TableQueryType,
    getStore as getListViewStore,
    getListViewState,
} from 'owa-mail-list-store';
import {
    getMailListDragPreview,
    MAIL_ITEM_DRAG_XOFFSET,
    MAIL_ITEM_DRAG_YOFFSET,
} from '../utils/mailListDragUtil';
import {
    getHoverSurfaceAction,
    getStore as getHoverSurfaceActionStore,
} from 'owa-surface-actions-option';
import {
    isFirstLevelExpanded,
    isSecondLevelExpanded,
    isLoadingSecondLevelExpansion,
} from 'owa-mail-list-store/lib/selectors/isConversationExpanded';
import {
    isRowKeyInRowInsertionAnimation,
    getShouldAnimate,
} from 'owa-mail-list-item-animation-store';
import { setLastAutoFocusedRowKey } from 'owa-mail-list-selection-actions-v2';
import useMailListItemDragEvents from '../hooks/useMailListItemDragEvents';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import {
    animationContainer,
    contentContainer,
} from 'owa-expansion-animation/lib/styles/expansionAnimationStyles.scss';
import { transitionStyles } from 'owa-expansion-animation';
import { CSSTransition } from 'react-transition-group';
import dragToDownloadEmail from 'owa-attachment-drag-and-drop/lib/utils/dragToDownloadEmail';
import { useIntersectionObserver } from 'owa-react-hooks/lib/useIntersectionObserver';
import {
    animationStyles,
    hideAnimation,
    hcBorder,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';
import { mailListItemContainer } from 'owa-mail-listitem-styles/lib/scss/ListViewItemCommonStyles.scss';
import { logUsage } from 'owa-analytics';
import { MailListItemWithTouchBehavior, MailListItemWrapper } from './MailListItemWrapper';
import getAriaLabelForMailListItemRow from '../utils/getAriaLabelForMailListItemRow';
import classnames from 'owa-classnames';
import { addEventTimeToSourceMapping, safeRequestAnimationFrame } from 'owa-performance';

export interface MailListItemContainerProps {
    mailListTableProps: MailListTableProps;
    mailListItemDataProps: MailListItemDataProps;
    resetListViewContentTabIndex: () => void;
    getIsFocusInMailList: () => boolean;
    styleSelectorAsPerUserSettings: string;
}

function useClearFocusOnUnmount(listViewItem: React.RefObject<HTMLDivElement>) {
    React.useEffect(() => {
        const listViewItemElement = listViewItem.current;

        // Bug 180055 React.useEffect cleanup can't reliably be used to conditionally move focus away from an "about to unmount" focused element
        return () => {
            // If item about to be unmounted had focus, set focus to the mail list component immediately so we do not
            // lose focus to body.
            if (listViewItemElement?.contains(document.activeElement)) {
                setFocusToSynchronous('MailList');
            }
        };
    }, []);
}

function useUpdateFocusOnFocusChange(
    listViewItem: React.RefObject<HTMLDivElement>,
    shouldRowBeFocused: boolean,
    shouldRowBeAutoFocused: boolean,
    getIsFocusInMailList: () => boolean,
    resetListViewContentTabIndex: () => void,
    tableView: TableView | undefined,
    rowKey: string
) {
    const resetListViewContentTabIndexRef = React.useRef(resetListViewContentTabIndex);
    resetListViewContentTabIndexRef.current = resetListViewContentTabIndex;

    const isFocusInMailList = getIsFocusInMailList();
    const currentItem = listViewItem.current;
    const effectFunc = () => {
        if (tableView && currentItem) {
            if (shouldRowBeFocused) {
                // Check whether focus is in the listview before setting focus on item. There are cases in which focused row
                // might change when the focus is in a different component like compose, in which we do NOT want to focus to
                // go to a mail list item. Example: when in the unread filter, if user clicks on an item and replies, the item
                // disappears from list view and the next item gets selected. If this check wasn't here, then focus
                // would jump from compose to that newly selected mail list item.
                if (isFocusInMailList) {
                    if (shouldRowBeAutoFocused) {
                        // Focus on self if ref exists and the item is focused and focus is in mail list already
                        // We need state checks here because this is being called async on requestAnimationFrame.
                        currentItem.focus();
                        setLastAutoFocusedRowKey(tableView, rowKey);
                    }

                    currentItem.setAttribute('tabindex', tabIndex.sequentialIndex);
                }

                // Reset listview content tabindex such that listview is
                // not in a sequential keyboard navigation anymore once an item selected
                resetListViewContentTabIndexRef.current();
            } else {
                // Clear tab index on item when it's no longer focused
                // We need state checks here because this is being called async on requestAnimationFrame
                currentItem.setAttribute('tabindex', tabIndex.nonSequentialIndex.toString());
            }
        }
    };

    React.useEffect(
        isFeatureEnabled('fwk-mlfocus-timeout')
            ? () => {
                  safeRequestAnimationFrame(effectFunc);
              }
            : effectFunc,
        [shouldRowBeFocused, shouldRowBeAutoFocused, isFocusInMailList, tableView?.id, rowKey]
    );

    const intersectionCallback = React.useCallback(
        (entry: IntersectionObserverEntry) => {
            if (
                tableView &&
                listViewItem.current &&
                shouldRowBeFocused &&
                !isFocusInMailList &&
                shouldRowBeAutoFocused &&
                !entry.isIntersecting
            ) {
                setLastAutoFocusedRowKey(tableView, rowKey);
                logUsage('MailListItemContainer_AutoFocus', { intersection: true });
            }
        },
        [shouldRowBeFocused, shouldRowBeAutoFocused, isFocusInMailList, tableView]
    );

    useIntersectionObserver(MailListComponentName, listViewItem, intersectionCallback);
}

function useLazyEvaluatedRowAriaInfo(
    shouldRowBeFocused: boolean,
    tableView: TableView | undefined,
    props: Pick<MailListItemContainerProps, 'mailListItemDataProps'>
) {
    const numRows = React.useRef<number | undefined>();
    const rowPos = React.useRef<number | undefined>();

    return useComputedValue(() => {
        // ONLY update the num rows / row position for aria if the row is focused, otherwise all rows will be rerendered
        // which is a HUGE perf impact!! The num rows or row position can change any time user does a row removal (delete, archive, move),
        // pin, receives a new email, does a bulk delete, etc.
        if (shouldRowBeFocused) {
            numRows.current = tableView
                ? tableView.tableQuery.type === 1
                    ? tableView.rowKeys.length
                    : tableView.totalRowsInView
                : 0;

            const rowKeyIndex =
                tableView?.rowKeys?.indexOf(props.mailListItemDataProps.rowKey) ?? -1;
            rowPos.current = rowKeyIndex + 1;
        }

        return { numRows: numRows.current, rowPos: rowPos.current };
    }, [tableView, props.mailListItemDataProps.rowKey]);
}

const MailListComponentName = 'MailListItemContainer';
export default observer(function MailListItemContainer(props: MailListItemContainerProps) {
    const listViewItem = React.useRef<HTMLDivElement | null>(null);
    const { mailListItemDataProps, mailListTableProps } = props;
    const { tableViewId } = mailListTableProps;
    const tableView = getListViewStore().tableViews.get(tableViewId);
    const mailboxInfo = getMailboxInfo(tableView);
    const isMlSwipeEnabled = isFeatureEnabled('tri-mlTriageSwipe', mailboxInfo);
    const {
        conditionalFormattingModifications,
        effectiveMentioned,
        hasAttachment,
        importance,
        isFlagged,
        isPinned,
        itemClassIcon,
        lastDeliveryTimestamp,
        returnTime,
        rowKey,
        sendersOrRecipients,
        showDraftsIndicator,
        subject,
        thirdLineText,
        unreadCount,
        meetingStartTime,
        copilotInboxScore,
    } = mailListItemDataProps;

    const nodeRef = React.useRef(null);
    const [onDragStart, onDragEnd] = useMailListItemDragEvents();

    const onDragStartFunc = React.useCallback(
        (dataTransfer: DataTransfer): void => {
            onDragStart();
            const itemId = mailListItemDataProps.latestItemId;
            if (itemId) {
                dragToDownloadEmail(dataTransfer, itemId, mailListItemDataProps.subject);
            }
        },
        [mailListItemDataProps.latestItemId]
    );

    const mailListItemContainerContextValue = React.useMemo(
        () => ({
            conditionalFormattingModifications,
        }),
        [conditionalFormattingModifications]
    );

    /**
     * Programmatically reset hover if pinned state AND row key position have
     * changed (because we can assume that the row is no longer hovered).
     */
    const lastKnownRowKeyIndex = React.useRef(tableView?.rowKeys.indexOf(rowKey));
    const currentRowKeyIndex = useComputedValue(
        () => tableView?.rowKeys.indexOf(rowKey),
        [tableViewId, rowKey]
    );
    React.useLayoutEffect(() => {
        if (currentRowKeyIndex !== lastKnownRowKeyIndex.current) {
            lastKnownRowKeyIndex.current = currentRowKeyIndex;
        }
    }, [isPinned]);

    const shouldRowBeFocused = useComputedValue(
        () => tableView?.focusedRowKey === rowKey,
        [tableViewId, rowKey]
    );
    const shouldRowBeAutoFocused = useComputedValue(
        () =>
            tableView?.focusedRowKey === rowKey &&
            tableView?.lastAutoFocusedRowKey !== tableView.focusedRowKey,
        [tableViewId, rowKey]
    );

    const isFirstLevelExpansion = useComputedValue(
        () =>
            tableView?.tableQuery.listViewType == 0 &&
            isFirstLevelExpanded(rowKey) &&
            (getListViewState().expandedConversationViewState.forks?.length ?? 0) > 1,
        [tableViewId, rowKey]
    );

    const isLoadingSecondLevel = useComputedValue(
        () => tableView?.tableQuery.listViewType == 0 && isLoadingSecondLevelExpansion(rowKey),
        [tableViewId, rowKey]
    );

    const isSecondLevelExpansion = useComputedValue(
        () => tableView?.tableQuery.listViewType == 0 && isSecondLevelExpanded(rowKey),
        [tableViewId, rowKey]
    );

    /**
     * The onContextMenu event handler which opens the context menu
     * @param evt the mouse event
     */
    const onItemContextMenu = React.useCallback(
        (evt: React.MouseEvent<HTMLElement> | KeyboardEvent) => {
            addEventTimeToSourceMapping(
                'nativeEvent' in evt ? evt.nativeEvent : evt,
                MailListComponentName
            );
            evt.stopPropagation();
            evt.preventDefault();

            const selectedRows = tableView?.selectedRowKeys;

            // Select the row upon right click if
            // - user is not in selectAll mode
            // - and the row that clicked on is not selected yet
            if (
                tableView &&
                !tableView.isInVirtualSelectAllMode &&
                (!selectedRows || !selectedRows.has(rowKey))
            ) {
                onMailListItemClickHandler(
                    evt,
                    MailListItemSelectionSource.MailListItemContextMenu,
                    rowKey,
                    tableViewId
                );
            }

            showMailItemContextMenu(
                getAnchorForContextMenu(evt),
                MailListContextMenuType.All,
                MailListContextMenuSource.MailListItem
            );
        },
        [tableViewId, rowKey, tableView]
    );

    useClearFocusOnUnmount(listViewItem);

    const { getIsFocusInMailList, resetListViewContentTabIndex } = props;
    useUpdateFocusOnFocusChange(
        listViewItem,
        shouldRowBeFocused,
        shouldRowBeAutoFocused,
        getIsFocusInMailList,
        resetListViewContentTabIndex,
        tableView,
        rowKey
    );

    const shouldShowTwisty = props.mailListItemDataProps.shouldShowTwisty;
    const lazyKeyProps = React.useMemo(
        () => ({
            tableViewId,
            rowKey,
            shouldShowTwisty,
        }),
        [tableViewId, rowKey, shouldShowTwisty]
    );
    useLazyKeydownHandler(
        listViewItem,
        lazySetupMailListItemContainerKeys.importAndExecute,
        lazyKeyProps
    );
    useKeydownHandler(listViewItem, 'shift+f10', onItemContextMenu);

    const touchHandlerProps = React.useMemo(() => {
        const touchHandlerParams: ITouchHandlerParams = {
            onLongPress: onItemContextMenu,
            onClick: evt => {
                // Rather than directly calling the onClick handler, call click
                // on the ref div to also dismiss any context menus
                listViewItem.current?.click();

                if (isFeatureEnabled('tri-reselect-checkbox-behavior')) {
                    evt.stopPropagation();
                    evt.preventDefault();
                }
            },
        };
        return touchHandler(touchHandlerParams);
    }, [onItemContextMenu]);

    const onKeyDown = React.useCallback(
        (ev: React.KeyboardEvent<HTMLDivElement>) => {
            addEventTimeToSourceMapping(ev.nativeEvent, MailListComponentName);

            // Detect Space and prevent default to avoid scrolling
            if (ev.key === ' ') {
                ev.preventDefault();
            }
        },
        [rowKey, tableViewId]
    );

    const onMailListItemExpansionUnmount = React.useCallback(() => {
        // Bug 180055 React.useEffect cleanup can't reliably be used to conditionally move focus away from an "about to unmount" focused element
        // This callback is invoked by useEffect cleanup in MailListItemExpansion, which will run asynchronously _after_ the component has
        // been unmounted. Since below logic is checking for focus of listViewItem.current, a ref associated with a div rendered by this
        // component (MailListItemContainer), this should technically be okay. But, it is ill advised since this is performing DOM mutations
        // which should only be done during useLayoutEffect, not useEffect.

        // Recapture focus when mail list item expansion collapses if selection stays on current conversation (up arrow collapse). If you're on the
        // bottom most item part and hit the down arrow, we collapse the expansion and shift focus/selection to the next conversation.
        if (listViewItem.current) {
            if (!tableView) {
                return;
            }
            const selectedRowKey = [...tableView.selectedRowKeys.keys()][0];
            if (shouldRowBeFocused && selectedRowKey === rowKey) {
                listViewItem.current.focus();
            }
        }
    }, [shouldRowBeFocused]);

    const onClick = React.useCallback(
        (evt: React.MouseEvent<unknown, MouseEvent>) => {
            addEventTimeToSourceMapping(evt.nativeEvent, MailListComponentName);
            onMailListItemClickHandler(
                evt,
                MailListItemSelectionSource.MailListItemBody,
                rowKey,
                tableViewId
            );
        },
        [rowKey, tableViewId]
    );

    const isReadingPanePositionOff = useComputedValue(() => {
        return getIsReadingPanePositionOff();
    }, []);
    const onDoubleClick = React.useCallback(
        (evt: React.MouseEvent<unknown, MouseEvent>) => {
            addEventTimeToSourceMapping(evt.nativeEvent, MailListComponentName);
            evt.stopPropagation();
            if (mailListTableProps.supportsDoubleClick) {
                // Double-click is supported in single line view by debouncing
                // the click handler (no need to rely on the double-click event)
                if (isReadingPanePositionOff) {
                    return;
                }

                onMailListItemClickHandler(
                    evt,
                    MailListItemSelectionSource.MailListItemBodyDoubleClick,
                    rowKey,
                    tableViewId
                );
            }
        },
        [mailListTableProps.supportsDoubleClick, rowKey, tableViewId, isReadingPanePositionOff]
    );

    const isSelected = useComputedValue((): boolean => {
        return mailListItemDataProps.isSelected;
    }, [mailListItemDataProps.isSelected]);

    // In checked mode we have the parent container be draggable
    // Disable dragging if folderId or parentFolderId is of Notes Folder
    const canDrag = React.useCallback(() => {
        return (
            !mailListItemDataProps.isSelected &&
            mailListTableProps.canDragFromTable &&
            !mailListItemDataProps.isInNotesFolder
        );
    }, [
        mailListItemDataProps.isSelected,
        mailListTableProps.canDragFromTable,
        mailListItemDataProps.isInNotesFolder,
    ]);

    const isDraggable = React.useCallback(() => {
        return !mailListItemDataProps.isInGroup;
    }, [mailListItemDataProps.isInGroup]);

    const getItemSize = React.useCallback((itemId: string) => {
        const item = mailStore.items.get(itemId);
        return item?.Size;
    }, []);

    const getMailboxInfoForItem = React.useCallback((itemId: string) => {
        const item = mailStore.items.get(itemId);
        return item?.MailboxInfo;
    }, []);

    const getHasIRM = React.useCallback((itemId: string) => {
        const item = mailStore.items.get(itemId);
        return (
            !!item?.RightsManagementLicenseData ||
            mailListItemDataProps.itemClassIcon === PropertyIcons.MailListViewIRM ||
            mailListItemDataProps.itemClassIcon === PropertyIcons.MailListViewIRMReplied ||
            mailListItemDataProps.itemClassIcon === PropertyIcons.MailListViewIRMForwarded
        );
    }, []);

    const getDragData = React.useCallback(() => {
        return {
            itemType: DraggableItemTypes.MailListRow,
            rowKeys: [rowKey],
            tableViewId,
            tableListViewType: tableView?.tableQuery?.listViewType || 1,
            subjects: [
                mailListItemDataProps.subject ? mailListItemDataProps.subject : loc(noSubject),
            ],
            haveIRM: [getHasIRM(mailListItemDataProps.latestItemId)],
            sizes: [getItemSize(mailListItemDataProps.latestItemId)],
            mailboxInfos: [
                getMailboxInfoForItem(mailListItemDataProps.latestItemId) ??
                    getModuleContextMailboxInfo(),
            ],
            latestItemIds: [mailListItemDataProps.latestItemId],
        };
    }, [mailListItemDataProps, tableView, rowKey, tableViewId]);

    const { numRows = 0, rowPos = 0 } = useLazyEvaluatedRowAriaInfo(
        shouldRowBeFocused,
        tableView,
        props
    );
    const isRowExpanded = isFirstLevelExpansion || isSecondLevelExpansion;

    const isSingleLine = mailListTableProps.isSingleLine;

    const bigHoverAction = getBigHoverAction(
        getHoverSurfaceActionStore().hoverSurfaceActions ?? getHoverSurfaceAction()
    );

    const labelForMailItem = useComputedValue(() => {
        return getAriaLabelForMailListItemRow(
            tableView,
            tableViewId,
            shouldRowBeFocused,
            shouldShowTwisty /* canRowBeExpanded */,
            isFirstLevelExpansion || isSecondLevelExpansion /* isRowExpanded */,
            unreadCount,
            importance,
            hasAttachment,
            isFlagged,
            isPinned,
            subject,
            sendersOrRecipients,
            lastDeliveryTimestamp,
            thirdLineText,
            showDraftsIndicator,
            itemClassIcon,
            effectiveMentioned,
            returnTime,
            meetingStartTime,
            copilotInboxScore,
            mailboxInfo
        );
    }, [
        tableViewId,
        shouldRowBeFocused,
        shouldShowTwisty,
        isFirstLevelExpansion,
        isSecondLevelExpansion,
        unreadCount,
        importance,
        hasAttachment,
        isFlagged,
        isPinned,
        subject,
        sendersOrRecipients,
        lastDeliveryTimestamp,
        thirdLineText,
        showDraftsIndicator,
        itemClassIcon,
        effectiveMentioned,
        returnTime,
        meetingStartTime,
        copilotInboxScore,
        mailboxInfo,
    ]);

    const itemAriaProps = React.useMemo(
        (): AriaProperties => ({
            role: AriaRoles.option,
            selected: isSelected,
            label: labelForMailItem || undefined, // When no important information, do not add label
        }),
        [labelForMailItem, isSelected]
    );

    const hasAnimationsEnabled = getShouldAnimate();
    const shouldHideAnimation = useComputedValue(
        () => isRowKeyInRowInsertionAnimation(rowKey),
        [rowKey]
    );
    const expansionWrapperId = `${rowKey}_expansionAnimationWrapper`;

    return (
        <MailListItemContainerContextProvider value={mailListItemContainerContextValue}>
            <div
                {...touchHandlerProps}
                id={rowKey}
                data-convid={mailListItemDataProps.conversationId}
                ref={listViewItem}
                tabIndex={-1}
                className={classnames(
                    mailListItemContainer,
                    hasAnimationsEnabled ? animationStyles : undefined,
                    hasAnimationsEnabled && shouldHideAnimation ? hideAnimation : undefined,
                    isSelected && getIsMsHighContrast() && hcBorder
                )}
                onClick={onClick}
                onDoubleClick={onDoubleClick}
                aria-selected={isSelected}
                aria-setsize={numRows}
                aria-posinset={rowPos}
                onContextMenu={onItemContextMenu}
                onKeyDown={onKeyDown}
                {...generateDomPropertiesForAria(itemAriaProps)}
                data-focusable-row={true}
            >
                <Draggable
                    canDrag={canDrag}
                    isDraggable={isDraggable}
                    getDragData={getDragData}
                    getDragPreview={getMailListDragPreview}
                    xOffset={MAIL_ITEM_DRAG_XOFFSET}
                    yOffset={MAIL_ITEM_DRAG_YOFFSET}
                    onDragStart={onDragStartFunc}
                    onDragEnd={onDragEnd}
                >
                    {isMlSwipeEnabled ? (
                        <MailListItemWithTouchBehavior
                            mailListTableProps={mailListTableProps}
                            mailListItemDataProps={mailListItemDataProps}
                            styleSelectorAsPerUserSettings={props.styleSelectorAsPerUserSettings}
                            isFirstLevelExpanded={isFirstLevelExpansion}
                            isSecondLevelExpanded={isSecondLevelExpansion}
                            isLoadingSecondLevelExpansion={isLoadingSecondLevel}
                        />
                    ) : (
                        <MailListItemWrapper
                            mailListTableProps={mailListTableProps}
                            mailListItemDataProps={mailListItemDataProps}
                            styleSelectorAsPerUserSettings={props.styleSelectorAsPerUserSettings}
                            isFirstLevelExpanded={isFirstLevelExpansion}
                            isSecondLevelExpanded={isSecondLevelExpansion}
                            isLoadingSecondLevelExpansion={isLoadingSecondLevel}
                        />
                    )}
                </Draggable>
            </div>
            <CSSTransition
                id={expansionWrapperId}
                in={isRowExpanded}
                timeout={200}
                unmountOnExit={true}
                classNames={transitionStyles}
                nodeRef={nodeRef}
            >
                <div className={animationContainer} ref={nodeRef}>
                    <div className={contentContainer}>
                        <MailListItemExpansion
                            mailboxInfo={mailboxInfo}
                            wrapperId={expansionWrapperId}
                            onUnmount={onMailListItemExpansionUnmount}
                            conversationId={mailListItemDataProps.rowId}
                            tableViewId={tableViewId}
                            isPinned={mailListItemDataProps.isPinned}
                            isSingleLine={isSingleLine}
                            tableViewSupportsFlagging={mailListTableProps.supportsFlagging}
                            getIsFocusInMailList={props.getIsFocusInMailList}
                            isFirstLevelExpansion={isFirstLevelExpansion}
                            isSecondLevelExpansion={isSecondLevelExpansion}
                            bigHoverAction={bigHoverAction}
                            rowKey={rowKey}
                            supportsPinning={mailListTableProps.supportsPinning}
                        />
                    </div>
                </div>
            </CSSTransition>
        </MailListItemContainerContextProvider>
    );
}, MailListComponentName);
