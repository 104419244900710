import { newGuid } from 'owa-config';
import type { QueuedActionContext, QueuedActionOptions, SubmitProcessor } from 'owa-queued-actions';
import type { StrictMutationOptions } from '../types/StrictMutationOptions';
import type { FeatureName } from 'owa-feature-flags';

// make sure all properties of the action context are copied over.
// flight and submitProcessor are allowed to be undefined, localResult isn't computed until the idb resolver runs so opt those out
type ExplicitActionContext = Omit<
    Required<QueuedActionContext['queuedAction']>,
    'localResult' | 'flight' | 'submitProcessor'
> & {
    flight?: FeatureName;
    submitProcessor?: SubmitProcessor;
};

export function stampQueuedActionOptionsOnContext(
    fullOptions: StrictMutationOptions<any, any, any>
) {
    const options: QueuedActionOptions & StrictMutationOptions<any, any, any> = fullOptions || {};

    // stamp queued action information from the options into the context so they're available to links
    // (options isn't passed to links, so they need to be drilled via context)
    if (options?.queuedAction) {
        const queuedAction: ExplicitActionContext = {
            blockingKeys: options.queuedAction.blockingKeys,
            resultProcessor: options.queuedAction.resultProcessor,
            flight: options.queuedAction.flight,
            state: 'OfflineExecution',
            cachePolicy: options.fetchPolicy === 'no-cache' ? 'no-cache' : 'cache',
            uuid: newGuid(),
            submitProcessor: options.queuedAction.submitProcessor,
        };

        const queuedContext: QueuedActionContext = {
            ...(options.context ?? {}),
            queuedAction,
        };

        options.context = queuedContext;
    }

    return options;
}
