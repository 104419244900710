// These are the possible sources of selection.
enum MailListItemSelectionSource {
    Auto = 0, // Auto-selection of mail list item
    CommandBarArrows = 1, // Up / down arrows in command bar when in immersive RP
    KeyboardUpDown = 2, // Keyboard up / down arrows
    KeyboardEnter = 3, // Keyboard hotkey for opening an email in reading pane
    KeyboardShiftEnter = 4, // Keyboard hotkey for opening an email in popout
    KeyboardCtrlSpace = 5, // Keyboard hotkey for toggling selection on mail item
    MailListItemBody = 6, // Clicking on the mail list item (but not checkbox or twisty)
    MailListItemBodyDoubleClick = 7, // Double clicking on the mail list item (but not checkbox or twisty)
    MailListItemCheckbox = 8, // Checkbox
    MailListItemContextMenu = 9, // Right click on mail list item context menu
    MailListItemExpansion = 10, // Click on mail list item expansion item
    MailListItemRichPreview = 11, // Click on mail item attachment preview (used to check if ctrl/shift key pressed)
    MailListItemTwisty = 12, // Twisty button to trigger expansion of item parts
    NewConversation = 13, // Selection of newly arrived conversations
    Reset = 14, // Reset of selection
    RowRemoval = 15, // Row removal
    ImmersiveReadingPaneDelete = 16, // Row removal while the reading pane is open in single line view
    RouteHandler = 17, // Route handler
    ToggleCheckAll = 18, // Select all
    MessageAd = 19, // Select Message Ad
    SearchSuggestionClick = 20, // Click on a search suggestion
    SingleLineClearSelection = 21, // Clear selection in mail list header
    KeyboardHome = 22, // Keyboard hotkey for selecting first row
    KeyboardEnd = 23, // Keyboard hotkey for selecting last loaded row
    KeyboardShiftHome = 24, // Keyboard hotkey for selecting the range of items from the currently selected one to the first row
    CopilotCatchUp = 25, // Copilot Catch Up (unused, but remains for logging fidelity)
    MailListItemSimulatedDoubleClick = 26,
}
export default MailListItemSelectionSource;
