import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PersonSourcesUnlinkMutation" */ './lazyIndex')
);

export const lazyPersonSourcesUnlinkMutationIdb = createLazyResolver(
    'PERSON_SOURCES_UNLINK_MUTATION_IDB',
    lazyModule,
    m => m.unlinkPersonSourcesIdbResolver,
    'NoFallback' /* fallbackBehavior */
);

export const lazyPersonSourcesUnlinkMutationWeb = createLazyResolver(
    'PERSON_SOURCES_UNLINK_MUTATION_WEB',
    lazyModule,
    m => m.unlinkPersonSourcesWebResolver
);
