import type { MailboxInfo } from 'owa-client-types';
import type {
    M365UserMailboxAccountSource,
    M365SharedMailboxAccountSource,
} from 'owa-account-source-list-store';
import { isRemovedAccount } from 'owa-account-source-list-types';
import { getAccountBySourceId, setSharedSources } from 'owa-account-source-list-store';
import { buildM365SharedMailboxAccountSource } from 'owa-account-source-list/lib/utils/accountSourceBuilder';
import getUserAccount from './utils/getUserAccount';
import { MailboxInfoError } from './MailboxInfoError';

/**
 * Adds the shared mailbox to the account source
 * @param account Specifies the account to which the shared mailbox is to be added
 * @param smtpAddress SMTP address of the shared mailbox
 * @param displayName Optionally specifies if the display name of the shared mailbox
 * @param isAutomapped Optionally specifies if the shared mailbox is auto mapped
 */
function addShardMailboxToAccount(
    account: M365UserMailboxAccountSource,
    sharedMailbox: M365SharedMailboxAccountSource
) {
    setSharedSources(account.sourceId, {
        loadState: account.sharedSources.loadState,
        sources: [...account.sharedSources.sources, sharedMailbox],
        coprincipalSourceId: account.sourceId,
    });
}

/**
 * Returns the MailboxInfo for the shared mailbox
 * @param mailboxInfoInAccount Used to determine the coprincipal account, see getIndexerForMailboxInfo for more details
 * @param smtpAddress SMTP address of the shared mailbox
 * @param displayName Optionally specifies the display name of the shared mailbox
 * @param isAutomapped Optionally specifies if the shared mailbox is auto mapped
 * @returns MailboxInfo for the shared mailbox
 */
export function getMailboxInfoForShared(
    mailboxInfoInAccount: MailboxInfo,
    smtpAddress: string,
    displayName?: string,
    isAutomapped?: boolean
): MailboxInfo {
    const account = getUserAccount(mailboxInfoInAccount);

    // We build the shared mailbox account source that would need to be added, this will get us the sourceId
    // for the shared mailbox which we can use to find if it already exists or needs to be added
    const sharedMailbox = buildM365SharedMailboxAccountSource(
        displayName || smtpAddress,
        smtpAddress,
        account.mailboxInfo,
        isAutomapped || false,
        account.persistenceId,
        {}
    );

    if (isRemovedAccount(account)) {
        // Mark the MailboxInfo as coming from a removed account
        return { ...sharedMailbox.mailboxInfo, isRemoved: true, mailboxRank: 'Resource' };
    }

    let existing = getAccountBySourceId(sharedMailbox.sourceId);
    if (!existing) {
        addShardMailboxToAccount(account, sharedMailbox);
        existing = getAccountBySourceId(sharedMailbox.sourceId);
        if (!existing) {
            throw new MailboxInfoError('Failed to find the added shared mailbox');
        }
    }

    return existing.mailboxInfo;
}
