import { observer } from 'owa-mobx-react';
import loc from 'owa-localize';
import type { IPoint } from '@fluentui/react/lib/Utilities';
import React from 'react';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { FluentMenu } from 'owa-fluent-v9-shims';
import { DirectionalHint } from '@fluentui/react/lib/ContextualMenu';
import { addFavoriteLabel, hideFavoritesLabel } from './FavoritesList.locstring.json';
import type { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import {
    showFindFavoritesPicker,
    lazyToggleFavoritesTreeExpansion,
} from 'owa-mail-favorites-store';
import { logUsage } from 'owa-analytics';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { setHideFavoritesList } from 'owa-favorites';
import { Menu, MenuPopover, MenuList, MenuItem } from '@fluentui/react-components';
import { isFeatureEnabled } from 'owa-feature-flags';
import { usePositioning } from 'owa-folders-common';
import { StarAddRegular, EyeOffRegular } from '@fluentui/react-icons';

const AddIcon = <StarAddRegular />;
const RemoveIcon = <EyeOffRegular />;

export interface FavoritesRootContextMenuProps {
    anchorPoint: IPoint;
    onDismiss: (ev?: any) => void;
    mailboxInfo: MailboxInfo;
}

const toggleHideFavorites = () => {
    setHideFavoritesList(true);
    logUsage('FP_HideFavorites_LeftNav');
};

export default observer(function FavoritesRootContextMenu(props: FavoritesRootContextMenuProps) {
    const { anchorPoint, onDismiss, mailboxInfo } = props;

    const positioning = usePositioning(anchorPoint);

    const addFavoriteWrapper = React.useCallback(
        (
            _ev?:
                | React.KeyboardEvent<HTMLElement>
                | React.MouseEvent<HTMLElement, MouseEvent>
                | undefined,
            _item?: IContextualMenuItem | undefined
        ): any => {
            logUsage('FP_AddFavoritesClicked');
            // if the favorites node is collapsed, open it so users can use the add favorite UI
            if (getUserConfiguration()?.UserOptions?.IsFavoritesFolderTreeCollapsed) {
                lazyToggleFavoritesTreeExpansion.importAndExecute();
            }
            const mailboxKey = getIndexerValueForMailboxInfo(mailboxInfo);
            showFindFavoritesPicker(mailboxKey, true /* shouldShow */);
        },
        [mailboxInfo]
    );

    const menuItems = React.useMemo((): IContextualMenuItem[] => {
        const items = [
            {
                key: 'addFavorite',
                name: loc(addFavoriteLabel),
                disabled: false,
                onClick: addFavoriteWrapper,
            },
            {
                key: 'hideFavorite',
                name: loc(hideFavoritesLabel),
                disabled: false,
                onClick: toggleHideFavorites,
            },
        ];

        return items;
    }, [addFavoriteWrapper, toggleHideFavorites]);

    if (isFeatureEnabled('fp-contextmenu-v9')) {
        return (
            <Menu
                positioning={positioning}
                open={true}
                onOpenChange={onDismiss}
                closeOnScroll={true}
            >
                <MenuPopover>
                    <MenuList>
                        <MenuItem onClick={addFavoriteWrapper} icon={AddIcon}>
                            {loc(addFavoriteLabel)}
                        </MenuItem>
                        <MenuItem onClick={toggleHideFavorites} icon={RemoveIcon}>
                            {loc(hideFavoritesLabel)}
                        </MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        );
    } else {
        return (
            <FluentMenu
                directionalHint={DirectionalHint.bottomLeftEdge}
                items={menuItems}
                onDismiss={onDismiss}
                target={anchorPoint}
            />
        );
    }
}, 'FavoritesRootContextMenu');
