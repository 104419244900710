import { isPersistenceIdIndexerEnabled } from 'owa-client-types/lib/isPersistenceIdIndexerEnabled';
import type { IndexerAndMatchInfo } from '../store/schema/AccountSourceList';
import getAccountSourceListStore from '../store/accountSourceListStore';

/**
 * DEPRECATED: Use getAccountKeyByUserIdentity() instead
 *
 * Returns the indexer associated with the user identity, if there is one stored in the store data
 * @returns Indexer value if found or undefined if not found
 */
export default function getIndexerAndMatchInfoByUserIdentity(
    userIdentity: string
): IndexerAndMatchInfo | undefined {
    if (isPersistenceIdIndexerEnabled()) {
        // This map is not used when the persistence id indexer is enabled so we just return undefined
        return undefined;
    }

    return getAccountSourceListStore().indexerByUserIdentity.get(userIdentity);
}
