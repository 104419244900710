import { isFeatureEnabled } from 'owa-feature-flags';
import type { SearchScope } from 'owa-search-service/lib/data/schema/SearchScope';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import { SearchProvider } from 'owa-search-service/lib/data/schema/SearchProvider';
import is3SServiceAvailable from 'owa-search/lib/utils/is3SServiceAvailable';
import { getUserConfiguration } from 'owa-session-store';
import { isOffline } from 'owa-offline/lib/isOffline';
import { isOfflineSyncEnabled } from 'owa-offline-sync-feature-flags';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { logUsage } from 'owa-analytics';
import shouldForceOfflineSearchBasedOnTimestamp from './shouldForceOfflineSearchBasedOnTimestamp';
import { getScenarioStore, SearchScenarioId } from 'owa-search-store';

// Cache to store search providers for each search session
const searchProviderCache: Map<string, SearchProvider> = new Map();

export default function getSearchProvider(searchScope: SearchScope): SearchProvider {
    const scopeKind = searchScope?.kind;
    const searchSessionGuid = getScenarioStore(SearchScenarioId.Mail).searchSessionGuid;

    // If the user has opted into a timeframe of offline search, return offline provider
    const shouldForceOfflineSearch = shouldForceOfflineSearchBasedOnTimestamp();
    if (shouldForceOfflineSearch && scopeKind !== SearchScopeKind.PstFile) {
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, SearchProvider.Offline);
        }
        return SearchProvider.Offline;
    }

    if (searchProviderCache.has(searchSessionGuid)) {
        const searchProviderFromCache = searchProviderCache.get(searchSessionGuid);
        if (searchProviderFromCache) {
            return searchProviderFromCache;
        }
    }

    if (scopeKind === SearchScopeKind.PstFile) {
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, SearchProvider.PST);
        }
        return SearchProvider.PST;
    }

    if (isOffline() || isFeatureEnabled('sea-localSearchOverride')) {
        const isOfflineSyncEnabledValue = isOfflineSyncEnabled(
            getGlobalSettingsAccountMailboxInfo()
        );

        if (!isFeatureEnabled('sea-localSearchOverride')) {
            logUsage('GetSearchProvider_Offline', {
                isOfflineSyncEnabled: isOfflineSyncEnabledValue,
                searchSessionGuid,
            });
        }

        if (isOfflineSyncEnabledValue || isFeatureEnabled('sea-localSearchOverride')) {
            const searchProvider = SearchProvider.Offline;
            if (searchSessionGuid) {
                searchProviderCache.set(searchSessionGuid, searchProvider);
            }
            return searchProvider;
        }
    }

    if (scopeKind === SearchScopeKind.SharedFolders) {
        const searchProvider = is3SServiceAvailable()
            ? SearchProvider.SubstrateV2
            : SearchProvider.FindItem;
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    if (scopeKind === SearchScopeKind.PublicFolder) {
        const searchProvider = SearchProvider.FindItem;
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    if (getUserConfiguration().SessionSettings?.IsExplicitLogon) {
        const searchProvider = searchProviderForExplicitLogon(scopeKind);
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    /**
     * Key off of the staticSearchScope to determine which search provider to use.
     * This is determined by whether or not 3S supports the given scenario.
     */
    if (
        scopeKind === SearchScopeKind.PrimaryMailbox ||
        scopeKind === SearchScopeKind.ArchiveMailbox
    ) {
        const searchProvider = is3SServiceAvailable()
            ? SearchProvider.SubstrateV2
            : SearchProvider.ExecuteSearch;
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    } else if (scopeKind === SearchScopeKind.Group) {
        const searchProvider =
            is3SServiceAvailable() && isFeatureEnabled('grp-3sSearch')
                ? SearchProvider.SubstrateV2
                : SearchProvider.ExecuteSearch;
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    // Default to 3Sv2
    if (searchSessionGuid) {
        searchProviderCache.set(searchSessionGuid, SearchProvider.SubstrateV2);
    }
    return SearchProvider.SubstrateV2;
}

function searchProviderForExplicitLogon(scopeKind?: SearchScopeKind): SearchProvider {
    if (scopeKind === SearchScopeKind.PrimaryMailbox) {
        return is3SServiceAvailable() ? SearchProvider.SubstrateV2 : SearchProvider.ExecuteSearch;
    } else if (scopeKind === SearchScopeKind.ArchiveMailbox) {
        return is3SServiceAvailable() ? SearchProvider.SubstrateV2 : SearchProvider.FindItem;
    } else if (scopeKind === SearchScopeKind.Group) {
        return is3SServiceAvailable() && isFeatureEnabled('grp-3sSearch')
            ? SearchProvider.SubstrateV2
            : SearchProvider.ExecuteSearch;
    }

    return SearchProvider.SubstrateV2; // Default to SubstrateV2 for explicit logon
}
