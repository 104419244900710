import { observer } from 'owa-mobx-react';
import type { FavoriteNodeCommonProps } from './FavoriteNode';
import DragAndDroppable from 'owa-dnd/lib/components/DragAndDroppable';
import type { DragData } from 'owa-dnd/lib/utils/dragDataUtil';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import type { FavoriteNodeDragData } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import folderStore, { getEffectiveFolderDisplayName } from 'owa-folders';
import type { MailFolder } from 'owa-graph-schema';

import { showFavoritesContextMenu } from 'owa-mail-favorites-store/lib/actions/favoritesContextMenu';
import getCustomIcon from 'owa-folders-common/lib/util/getCustomIcon';
import { getCustomBundledIcon } from 'owa-folders-common/lib/util/getCustomIconComponent';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { isSameCoprincipalAccountMailboxInfos } from 'owa-client-types';
import getFolderViewStateFromId, {
    initializeFolderViewStateIfNeeded,
} from 'owa-mail-folder-store/lib/selectors/getFolderViewStateFromId';
import { getCustomFolderColorHexValue } from 'owa-mail-folder-store/lib/selectors/getCustomFolderColor';

import { DRAG_X_OFFSET, DRAG_Y_OFFSET, FolderNode } from 'owa-mail-folder-view';
import {
    getStore as getListViewStore,
    getSelectedTableView,
    isConversationView,
} from 'owa-mail-list-store';
import {
    lazyMoveItemsBasedOnNodeIds,
    lazyCopyMailListRows,
    lazyMoveMailListRows,
} from 'owa-mail-triage-action';
import type { MailListItemPartDragData } from 'owa-mail-types/lib/types/MailListItemPartDragData';
import type { MailListRowDragData } from 'owa-mail-types/lib/types/MailListRowDragData';
import { getAnchorForContextMenu } from 'owa-positioning';
import publicFolderFavoriteStore from 'owa-public-folder-favorite/lib/store/publicFolderFavoriteStore';
import { logUsage } from 'owa-analytics';
import React from 'react';
import { dragPreview } from './FavoriteNode.scss';
import getFavoriteFolderDisplayNameForMultiAccount from '../util/getFavoriteFolderDisplayNameForMultiAccount';
import {
    canDropFavorite,
    canDropFolder,
    onDropFolder,
    onDropFavorite,
} from '../util/dragDropUtils';
import type DropEffect from 'owa-dnd/lib/store/schema/DropEffect';
import { SEARCH_FOLDER_TYPE_NAME } from 'owa-folders-constants';
import FolderSearchRegularV8 from 'owa-fluent-icons-svg/lib/icons/FolderSearchRegular';
import { FolderSearchRegular, FolderSearchFilled, bundleIcon } from '@fluentui/react-icons';

const FolderSearch = bundleIcon(FolderSearchFilled, FolderSearchRegular);

export interface FavoriteFolderNodeProps extends FavoriteNodeCommonProps {
    folderId: string;
    isPublicFolder?: boolean;
    ellipsesOnHover?: boolean;
}

export default observer(function FavoriteFolderNode(props: FavoriteFolderNodeProps) {
    const { isPublicFolder, folderId, favoriteId } = props;

    // Besides the isDragOver property in store, we also add this property here to distinguish from the dropping on MailFolderNode
    const isDragOver = React.useRef<boolean>();

    const getFolder = (): MailFolder | undefined => {
        return isPublicFolder
            ? publicFolderFavoriteStore.folderTable.get(folderId)
            : folderStore.folderTable.get(folderId);
    };

    const folder = getFolder();

    const customIconName =
        folder?.type == SEARCH_FOLDER_TYPE_NAME
            ? FolderSearchRegularV8
            : getCustomIcon(folderId, true);

    const customIconComponent =
        folder?.type == SEARCH_FOLDER_TYPE_NAME
            ? FolderSearch
            : getCustomBundledIcon(folderId, true);

    React.useEffect(() => {
        initializeFolderViewStateIfNeeded(favoriteId);
    }, []);

    /**
     * A callback function to check if current drag data is able to drop on current element
     * @param dragData the drag data which will be dropped
     * @param dragEvent the drag event to get the ctrlKey property value
     *
     * @returns the desired drop effect that will be used to update the cursor style
     */
    const canDrop = React.useCallback(
        (dragData: DragData, dragEvent?: React.DragEvent<HTMLElement>): DropEffect => {
            if (!folder) {
                return 'none';
            }
            const itemType = dragData.itemType;

            if (folder.distinguishedFolderType === 'notes') {
                return 'none';
            }

            switch (itemType) {
                // Conversations can't be moved to PF
                case DraggableItemTypes.MailListItemPart:
                case DraggableItemTypes.MailListRow:
                case DraggableItemTypes.MultiMailListMessageRows:
                case DraggableItemTypes.MultiMailListConversationRows: {
                    const tableView = getSelectedTableView();
                    const draggedItemsMailboxInfo = getMailboxInfo(tableView);

                    const isConversation = isConversationView(tableView);
                    return !(isPublicFolder && isConversation) &&
                        isSameCoprincipalAccountMailboxInfos(
                            draggedItemsMailboxInfo,
                            folder.mailboxInfo
                        )
                        ? dragEvent?.ctrlKey
                            ? 'copy'
                            : 'move'
                        : 'none';
                }

                case DraggableItemTypes.FavoriteNode: {
                    return canDropFavorite(dragData, folder.mailboxInfo) ? 'move' : 'none';
                }
                case DraggableItemTypes.MailFolderNode: {
                    return canDropFolder(dragData, folder.mailboxInfo) ? 'move' : 'none';
                }
                default:
                    return 'none';
            }
        },
        [folder]
    );

    const onDrop = React.useCallback(
        async (
            dragData: DragData,
            _pageX: number,
            _pageY: number,
            _currentTarget?: HTMLElement,
            ctrlKey?: boolean
        ) => {
            const itemType = dragData.itemType;
            logUsage('FP_FavoritesDragAndDrop');
            switch (itemType) {
                case DraggableItemTypes.MailListItemPart:
                    {
                        const mailListItemPartDragData = dragData as MailListItemPartDragData;
                        const tableView = getListViewStore().tableViews.get(
                            mailListItemPartDragData.tableViewId
                        );
                        if (!tableView) {
                            break;
                        }
                        const moveItemsBasedOnNodeIds = await lazyMoveItemsBasedOnNodeIds.import();
                        moveItemsBasedOnNodeIds(
                            mailListItemPartDragData.nodeIds,
                            folderId,
                            tableView.id,
                            'Drag'
                        );
                    }
                    break;
                case DraggableItemTypes.MailListRow:
                case DraggableItemTypes.MultiMailListConversationRows:
                case DraggableItemTypes.MultiMailListMessageRows:
                    const mailListRowDragData = dragData as MailListRowDragData;
                    const tableViewId = mailListRowDragData.tableViewId;
                    const rowKeys = mailListRowDragData.rowKeys;
                    if (ctrlKey) {
                        const tableView = getListViewStore().tableViews.get(tableViewId);
                        if (tableView) {
                            lazyCopyMailListRows.importAndExecute(
                                rowKeys,
                                tableView,
                                folderId,
                                'Drag'
                            );
                        }
                    } else {
                        lazyMoveMailListRows.importAndExecute(
                            rowKeys,
                            folderId,
                            tableViewId,
                            'Drag'
                        );
                    }
                    break;
                case DraggableItemTypes.MailFolderNode:
                    {
                        if (!folder) {
                            break;
                        }
                        await onDropFolder(dragData, folder.mailboxInfo, favoriteId);
                    }
                    break;
                case DraggableItemTypes.FavoriteNode:
                    {
                        await onDropFavorite(dragData, favoriteId);
                    }
                    break;
            }
        },
        [folder, favoriteId]
    );

    const onContextMenu = React.useCallback(
        (evt: React.MouseEvent<HTMLElement>) => {
            evt.stopPropagation();
            evt.preventDefault();
            showFavoritesContextMenu(
                favoriteId,
                isPublicFolder ? 5 : 0,
                getAnchorForContextMenu(evt),
                folderId
            );
        },
        [favoriteId, isPublicFolder, folderId]
    );

    const onDragOver = React.useCallback(() => {
        isDragOver.current = true;
    }, []);

    const onDragLeave = React.useCallback(() => {
        isDragOver.current = false;
    }, []);

    const getDragData = React.useCallback(() => {
        const displayName = getEffectiveFolderDisplayName(folder);
        const folderNodeDragData: FavoriteNodeDragData = {
            itemType: DraggableItemTypes.FavoriteNode,
            favoriteId,
            favoriteType: isPublicFolder ? 5 : 0,
            displayName,
            itemData: {
                mailboxInfo: folder?.mailboxInfo,
            },
        };
        return folderNodeDragData;
    }, [folder, favoriteId, isPublicFolder]);

    const folderViewState = getFolderViewStateFromId(favoriteId);

    const itemType = folderViewState.drop?.draggableItemType;
    const ellipsesOnHover = props.ellipsesOnHover;

    if (!folder || !folderViewState || !folderViewState.drag || !folderViewState.drop) {
        return null;
    }

    const folderColorId = getFolderViewStateFromId(folderId).color;
    const customFolderColor = getCustomFolderColorHexValue(folderColorId);

    return (
        <DragAndDroppable
            dragViewState={folderViewState.drag}
            getDragData={getDragData}
            getDragPreview={getDragPreview}
            xOffset={DRAG_X_OFFSET}
            yOffset={DRAG_Y_OFFSET}
            dropViewState={folderViewState.drop}
            onDrop={onDrop}
            canDrop={canDrop}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
        >
            <FolderNode
                customIcon={customIconName}
                customIconComponent={customIconComponent}
                displayName={getFavoriteFolderDisplayNameForMultiAccount(folderId)}
                folderId={folderId}
                isDroppedOver={folderViewState.drop.isDragOver && isDragOver.current}
                isBeingDragged={folderViewState.drag.isBeingDragged}
                key={folderId}
                onContextMenu={onContextMenu}
                showHoverStateOnDroppedOver={
                    itemType === DraggableItemTypes.MailListRow ||
                    itemType === DraggableItemTypes.MultiMailListConversationRows ||
                    itemType === DraggableItemTypes.MultiMailListMessageRows ||
                    itemType === DraggableItemTypes.MailListItemPart
                }
                treeType={'favorites'}
                isPublicFolder={isPublicFolder}
                unreadCount={folder?.UnreadCount ?? 0}
                totalCount={folder?.totalMessageCount ?? 0}
                distinguishedFolderId={folder?.distinguishedFolderType ?? ''}
                ellipsesOnHover={ellipsesOnHover}
                customIconColor={customFolderColor}
            />
        </DragAndDroppable>
    );
}, 'FavoriteFolderNode');

function getDragPreview(dragData: DragData) {
    const elem = document.createElement('div');
    elem.className = dragPreview;
    elem.innerText = (dragData as FavoriteNodeDragData).displayName;
    return elem;
}
