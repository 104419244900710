import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PersonaQuery" */ './lazyIndex')
);

export const lazyPersonaQueryIdb = createLazyResolver(
    'PERSONA_QUERY_IDB',
    lazyModule,
    m => m.personaQueryIdbResolver,
    'NoFallback' /* fallbackBehavior */
);

export const lazyPersonaQueryWeb = createLazyResolver(
    'PERSONA_QUERY_WEB',
    lazyModule,
    m => m.personaQueryWebResolver
);
