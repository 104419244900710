import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PersonSourceUpdateMutation" */ './lazyIndex')
);

export const lazyPersonSourceUpdateMutationIdb = createLazyResolver(
    'PERSON_SOURCE_UPDATE_MUTATION_IDB',
    lazyModule,
    m => m.updatePersonSourceIdbResolver,
    'NoFallback' /* fallbackBehavior */
);

export const lazyPersonSourceUpdateMutationWeb = createLazyResolver(
    'PERSON_SOURCE_UPDATE_MUTATION_WEB',
    lazyModule,
    m => m.updatePersonSourceWebResolver
);
