import { LazyModule } from 'owa-bundling-light';
import { createLazyResolver } from 'owa-lazy-resolver';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MeetingMutationWeb" */ './lazyIndex')
);

export const lazyRespondToMeetingRequestWeb = createLazyResolver(
    'RESPONDTOMEETINGREQUEST_MUTATION_WEB',
    lazyModule,
    m => m.respondToMeetingRequestWeb
);

export const lazyRemoveEventOnCancellationWeb = createLazyResolver(
    'REMOVEEVENTONCANCELLATION_MUTATION_WEB',
    lazyModule,
    m => m.removeEventOnCancellationWeb
);
