import { isFeatureEnabled } from 'owa-feature-flags';
import type MailListColumnHeaderType from '../types/MailListColumnHeaderType';
import type { MailboxInfo } from 'owa-client-types';

export default function getColumnMinWidth(
    headerType: MailListColumnHeaderType,
    mailboxInfo?: MailboxInfo
): number {
    const isRelocateHoverActionsFlightEnabled = isFeatureEnabled(
        'tri-mlRelocateHoverActions-dev',
        mailboxInfo
    );
    switch (headerType) {
        case 0:
            return isRelocateHoverActionsFlightEnabled ? 236 : 128;
        case 1:
            return 92;
        case 2:
            return isRelocateHoverActionsFlightEnabled ? 80 : 150;
        default:
            return 0;
    }
}
