import isCategoryInMasterList from 'owa-categories/lib/utils/isCategoryInMasterList';
import { lazySubscribeToCategoryNotifications } from 'owa-categories';
import { logUsage } from 'owa-analytics';
import type {
    FavoriteCategoryData,
    FavoriteFolderData,
    FavoriteGroupData,
    OutlookFavoriteKind,
    OutlookFavoriteServiceDataType,
} from 'owa-favorites-types';
import { FolderTreeLoadStateEnum } from 'owa-folders';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import getFavorites from 'owa-session-store/lib/utils/getFavorites';
import {
    type FavoritesBitFlagsMasks,
    getIsBitEnabled,
} from '../actions/helpers/favoritesBitFlagsActions';
import { setFavoritesLoaded } from '../actions/setFavoritesLoaded';
import onFavoritesLoaded from '../actions/onFavoritesLoaded';
import { addFavoriteToStoreInitial } from '../actions/v2/addFavoriteActions';
import { addToOrderedFavoriteIdList } from '../actions/v2/addToOrderedFavoriteIdList';
import migrateOutlookFavorites from '../actions/v2/migrateOutlookFavorites';
import { isGroupInFavorites } from '../selectors/isInFavorites';
import isMultiAccountsCombinedFavoritesEnabled from './isMultiAccountsCombinedFavoritesEnabled';
import {
    lazyAddNewMailboxDefaultFavorites,
    lazySetOutlookFavoritesBitFlag,
} from '../lazyFunctions';
import getOutlookFavoritesService from '../services/v2/getOutlookFavoritesService';
import favoritesStore from '../store/store';
import { convertServiceResponseToFavoriteData } from '../utils/favoriteServiceDataUtils';
import type { MailboxInfo } from 'owa-client-types';
import { initializeFavoriteTreeData } from './initializeFavoriteTreeData';
import {
    getIndexerValueForMailboxInfo,
    isSameCoprincipalAccountMailboxAndIndexer,
} from 'owa-client-types';
import getCombinedFavoritesFromCache from '../utils/getCombinedFavoritesFromCache';
import setCombinedFavoritesInCache from '../utils/setCombinedFavoritesInCache';
import { isCapabilityEnabled } from 'owa-capabilities';
import { favoritesServiceCapability } from 'owa-capabilities-definitions/lib/favoritesServiceCapability';
import { setFolderFavoritesDataCollectionInformation } from './registerDataCollector';
import { setLoadingStateForFavorites } from '../actions/setLoadingStateForFavorites';
import { setCombinedFavoriteIdsInStore } from '../mutators/setCombinedFavoriteIdsInStore';
import type { CombinedFavorite } from '../store/schema/CombinedFavorite';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getUniqueDataForFavoriteType } from './getUniqueDataForFavoriteType';
import { getMailboxInfoForUserIdentity } from 'owa-account-source-list';

const HOUR_TIMESPAN_IN_MS = 1000 * 60 * 60;

/**
 * Load outlook favorites
 */
export default async function loadOutlookFavorites(mailboxInfo: MailboxInfo) {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);

    initializeFavoriteTreeData(key, mailboxInfo);

    const loadingState = favoritesStore?.favoriteTreeData.get(key)?.loadingState;
    if (
        loadingState === FolderTreeLoadStateEnum.Loading ||
        loadingState == FolderTreeLoadStateEnum.Loaded
    ) {
        return;
    }

    setLoadingStateForFavorites(key, FolderTreeLoadStateEnum.Loading);

    // Load UserConfiguration.Favorites if there is any
    // We want to load the Favorites to store first and then migrate, because migrateOutlookFavorites
    // will send multiple create service call and await for all of them succeeds, and we don't want to delay boot
    await loadOutlookFavoritesToStore(mailboxInfo);

    const firstRunCompleted = getIsBitEnabled(2);
    const roamingFavoritesMigrationCompleted = getIsBitEnabled(16);

    if (!roamingFavoritesMigrationCompleted) {
        // Migrate favorites if it hasn't completed
        migrateOutlookFavorites(mailboxInfo);
    }
    // Add default favorites for new users
    if (!firstRunCompleted) {
        // Only add default folders for new outlook users
        if (
            favoritesStore?.favoriteTreeData.get(key)?.orderedOutlookFavoritesIds.length == 0 &&
            isNewUser()
        ) {
            lazyAddNewMailboxDefaultFavorites.importAndExecute(mailboxInfo);
        }
        lazySetOutlookFavoritesBitFlag.importAndExecute(2);
    }
    logLoadOutlookFavoritesDatapoints(key);

    setFavoritesLoaded(key);
    onFavoritesLoaded(key);
}

async function loadOutlookFavoritesToStore(mailboxInfo: MailboxInfo) {
    const key = getIndexerValueForMailboxInfo(mailboxInfo);

    // Use session data for logged in email address even when reload is true.
    // This is to avoid a service call when the data is available in the session store.
    let outlookFavorites = getFavorites(mailboxInfo)?.value;
    if (!outlookFavorites && isCapabilityEnabled(favoritesServiceCapability, mailboxInfo)) {
        // If there are no favorites in session data, retry using OWS' call
        let outlookFavoritesResponse;

        try {
            outlookFavoritesResponse = await getOutlookFavoritesService(mailboxInfo);
        } catch (error) {
            logUsage('GetOutlookFavoritesRetryFailed', {
                errorMessage: error.toString(),
            });
            setFolderFavoritesDataCollectionInformation(
                key + ':GetOutlookFavoritesRetryFailed',
                error.toString()
            );
            return;
        }

        outlookFavorites = outlookFavoritesResponse?.value;

        if (!outlookFavorites) {
            return;
        }

        logUsage('FavoritesRetrySuccess');
    }
    setFolderFavoritesDataCollectionInformation(
        key + ':FavoritesFromService',
        JSON.stringify(outlookFavorites)
    );

    const favoriteIds: string[] = [];
    const combinedFavorites: CombinedFavorite[] = [];
    let currentFavoriteId = undefined;
    // Populate favorites based on the OutlookFavorites
    (outlookFavorites as OutlookFavoriteServiceDataType[])?.forEach(
        /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
         * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
         *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
        (serviceData: OutlookFavoriteServiceDataType) => {
            currentFavoriteId = serviceData.Id;
            const favoriteType = serviceData.Type?.toLocaleLowerCase() as OutlookFavoriteKind;
            try {
                const favoriteData = convertServiceResponseToFavoriteData(
                    serviceData,
                    favoriteType,
                    mailboxInfo
                );
                if (!favoriteData) {
                    logUsage('FavoriteDataConversionFailed');
                    setFolderFavoritesDataCollectionInformation(
                        key + ':FavoriteDataConversionFailed: ' + currentFavoriteId,
                        'FavoriteDataConversionFailed'
                    );
                    return;
                }
                favoriteIds.push(favoriteData.favoriteId);
                if (isFeatureEnabled('fp-favorites-cache-order')) {
                    combinedFavorites.push({
                        favoriteId: favoriteData.favoriteId,
                        mailboxInfo,
                        type: favoriteType,
                        uniqueId: getUniqueDataForFavoriteType(favoriteData),
                    });
                }
                switch (favoriteData.type) {
                    case 'folder':
                        const favoriteFolderData = favoriteData as FavoriteFolderData;
                        if (favoriteFolderData.isSearchFolder) {
                            addFavoriteToStoreInitial(favoriteData);
                        } else {
                            tryAddSingleFavoriteFolderToStore(
                                favoriteData as FavoriteFolderData,
                                serviceData.SingleValueSettings?.[0].Value ?? ''
                            );
                        }

                        break;

                    case 'persona':
                        addFavoriteToStoreInitial(favoriteData);
                        break;

                    case 'publicFolder':
                        addFavoriteToStoreInitial(favoriteData);
                        break;

                    case 'group':
                        tryAddSingleFavoriteGroupToStore(favoriteData as FavoriteGroupData);
                        break;

                    case 'category':
                        tryAddSingleFavoriteCategoryToStore(
                            favoriteData as FavoriteCategoryData,
                            mailboxInfo
                        );
                        break;

                    default:
                        // VSO 26085: Optimize updating the favorite position data
                        // For unknown type, we have to update orderedOutlookFavoritesIds in order to
                        // record the index, which is used to locally update the favorite store
                        addToOrderedFavoriteIdList(favoriteData);
                        logUsage('AddingUnknownFavoriteType', { type: favoriteData.type });
                        setFolderFavoritesDataCollectionInformation(
                            key + ':AddingUnknownFavoriteType: ',
                            favoriteData.type
                        );
                        break;
                }
            } catch (error) {
                // continue execution if a favorite could not be loaded
                logUsage('AddFavoriteToStoreInitialFailed', {
                    favoriteType,
                    errorMessage: error.toString(),
                });
                setFolderFavoritesDataCollectionInformation(
                    key + ':AddFavoriteToStoreInitialFailed: ' + currentFavoriteId,
                    error.toString()
                );
            }
        }
    );

    // remove any deleted favorites from cache, if combined favorites flight is on
    if (isMultiAccountsCombinedFavoritesEnabled()) {
        if (isFeatureEnabled('fp-favorites-cache-order')) {
            runCacheUpgradeForFlight(combinedFavorites);
        }
        const cachedFavoritesList = getCombinedFavoritesFromCache();

        setFolderFavoritesDataCollectionInformation(
            key + ':cachedFavoritesList',
            JSON.stringify(cachedFavoritesList)
        );

        if (cachedFavoritesList) {
            const curMailboxInfoIndexValue = getIndexerValueForMailboxInfo(mailboxInfo);
            const validFavorites = cachedFavoritesList.combinedFavorites.filter(
                favoriteFromCache => {
                    const isSameAccount = isSameCoprincipalAccountMailboxAndIndexer(
                        favoriteFromCache.mailboxInfo,
                        curMailboxInfoIndexValue
                    );

                    return (
                        !isSameAccount ||
                        (isSameAccount && favoriteIds.indexOf(favoriteFromCache.favoriteId) >= 0)
                    );
                }
            );

            if (validFavorites) {
                setCombinedFavoriteIdsInStore(validFavorites.map(favorite => favorite.favoriteId));
                setCombinedFavoritesInCache({ combinedFavorites: validFavorites });
                setFolderFavoritesDataCollectionInformation(
                    key + ':validFavorites',
                    JSON.stringify(validFavorites)
                );
            }
        }
    }
}

/**
 * Add single favorite folder
 * @param favoriteData the favorite folder data to be added
 */
function tryAddSingleFavoriteFolderToStore(favoriteData: FavoriteFolderData, restFolderId: string) {
    if (!favoritesStore.outlookFavorites.has(restFolderId)) {
        // Add the folders if the folder is not in favorites yet. This could happen during firstRun, if user already has the default favorite folder.
        // We don't check to see if the folder exists here, because it could just not be loaded yet. We try to fetch unloaded folders in onOutlookFavoritesV2Loaded
        addFavoriteToStoreInitial(favoriteData);
    } else {
        setFolderFavoritesDataCollectionInformation(
            favoriteData.favoriteId,
            'tryAddSingleFavoriteFolderToStore_Failed'
        );
    }
}

/**
 * Add single favorite category
 * @param favoriteData the favorite category data to be added
 */
function tryAddSingleFavoriteCategoryToStore(
    favoriteData: FavoriteCategoryData,
    mailboxInfo: MailboxInfo
) {
    if (shouldAddFavoriteCategory(favoriteData, mailboxInfo)) {
        addFavoriteToStoreInitial(favoriteData);
        lazySubscribeToCategoryNotifications.importAndExecute(mailboxInfo); // This call makes sure that we only subscribe once, still need it here because this could be the first fav category added.
    } else {
        setFolderFavoritesDataCollectionInformation(
            favoriteData.favoriteId,
            'tryAddSingleFavoriteCategoryToStore_Failed '
        );
    }
}

/**
 * Should add folder to favorite list
 * @param favoriteData the favorite category data
 * @return a boolean which indicates whether we should add a favorite category
 */
function shouldAddFavoriteCategory(
    favoriteData: FavoriteCategoryData,
    mailboxInfo: MailboxInfo
): boolean {
    // Add the category to favorites if it is still valid and it is not favorited
    // Otherwise skip adding it, e.g the category has been deleted by the user from other client
    return (
        isCategoryInMasterList(favoriteData.categoryId, mailboxInfo) &&
        !favoritesStore.outlookFavorites.has(favoriteData.favoriteId)
    );
}

/**
 * Add single favorite group
 * @param favoriteData the favorite group data to be added
 * @param favoritesStore which contains all favorite data
 */
function tryAddSingleFavoriteGroupToStore(favoriteData: FavoriteGroupData) {
    if (!isGroupInFavorites(favoriteData.groupId)) {
        addFavoriteToStoreInitial(favoriteData);
    } else {
        setFolderFavoritesDataCollectionInformation(
            favoriteData.favoriteId,
            'tryAddSingleFavoriteGroupToStore_Failed '
        );
    }
}

/**
 * Run one time upgrade of cache per mailbox check if mailbox info and uniqueId are already in cache
 * If not, add it to cache.  Remove with removal of fp-favorites-cache-order flight
 * @param cachedFavoritesWithUniqueId Cached favorites with uniqueId already added to them
 */
function runCacheUpgradeForFlight(cachedFavoritesWithUniqueId: CombinedFavorite[]) {
    const oldCachedFavoritesList = getCombinedFavoritesFromCache();

    // Check if mailbox info is already added to cache, if not add that first
    if (
        oldCachedFavoritesList &&
        (oldCachedFavoritesList.combinedFavorites.some(fav => fav.mailboxInfo === undefined) ||
            oldCachedFavoritesList.combinedFavorites.some(fav => fav.uniqueId === undefined))
    ) {
        for (let i = 0; i < oldCachedFavoritesList.combinedFavorites.length; i++) {
            let favoriteFromCache = oldCachedFavoritesList.combinedFavorites[i];
            if (
                !favoriteFromCache.mailboxInfo &&
                favoriteFromCache.userIdentity &&
                getMailboxInfoForUserIdentity(favoriteFromCache.userIdentity) !== undefined
            ) {
                const favoriteMailboxInfo = getMailboxInfoForUserIdentity(
                    favoriteFromCache.userIdentity
                );
                if (favoriteMailboxInfo) {
                    const combinedFavoriteWithMailboxInfo: CombinedFavorite = {
                        favoriteId: favoriteFromCache.favoriteId,
                        mailboxInfo: favoriteMailboxInfo,
                    };

                    oldCachedFavoritesList.combinedFavorites.splice(
                        i,
                        1,
                        combinedFavoriteWithMailboxInfo
                    );
                }
            }
            favoriteFromCache = oldCachedFavoritesList.combinedFavorites[i];
            /* eslint-disable-next-line owa-custom-rules/forbid-foreach-with-variables-outside-of-function-scope -- (https://aka.ms/OWALintWiki)
             * https://dev.azure.com/outlookweb/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9650/Use-for-const-loop-of-instead-of-forEach
             *	> When using a forEach function call, avoid using variables outside of the scope of the function, use for (const item of array) instead */
            cachedFavoritesWithUniqueId.forEach(cachedFavWithUniqueId => {
                if (
                    oldCachedFavoritesList.combinedFavorites[i].uniqueId === undefined &&
                    favoriteFromCache.favoriteId === cachedFavWithUniqueId.favoriteId &&
                    favoriteFromCache.mailboxInfo &&
                    cachedFavWithUniqueId.mailboxInfo &&
                    favoriteFromCache.mailboxInfo.mailboxSmtpAddress ===
                        cachedFavWithUniqueId.mailboxInfo.mailboxSmtpAddress
                ) {
                    oldCachedFavoritesList.combinedFavorites.splice(i, 1, cachedFavWithUniqueId);
                }
            });
        }
        // Merge old cache with new cache
        setCombinedFavoritesInCache({
            combinedFavorites: oldCachedFavoritesList?.combinedFavorites ?? [],
        });
    }
}

function logLoadOutlookFavoritesDatapoints(key: string) {
    const allFavorites = [...favoritesStore.outlookFavorites.values()];

    const outlookFavoriteFolders = allFavorites.filter(favorite => favorite.type === 'folder');

    const migratedFolders = outlookFavoriteFolders.filter(
        favorite => favorite.client === 'Migration'
    );

    const outlookFavoriteCategories = allFavorites.filter(favorite => favorite.type === 'category');

    const migratedCategories = outlookFavoriteCategories.filter(
        favorite => favorite.client == 'Migration'
    );

    const outlookFavoritePersonas = allFavorites.filter(favorite => favorite.type === 'persona');

    const migratedPersonas = outlookFavoritePersonas.filter(
        favorite => favorite.client == 'Migration'
    );

    const outlookFavoriteGroups = allFavorites.filter(favorite => favorite.type === 'group');

    logUsage('LoadOutlookFavorites', [
        outlookFavoriteFolders.length,
        outlookFavoriteGroups.length,
        outlookFavoriteCategories.length,
        outlookFavoritePersonas.length,
        migratedFolders.length,
        migratedCategories.length,
        migratedPersonas.length,
    ]);

    // Add the favorites id list to the data collector
    const orderedOutlookFavoritesIds = isMultiAccountsCombinedFavoritesEnabled()
        ? favoritesStore.orderedCombinedOutlookFavoritesIds
        : favoritesStore.favoriteTreeData.get(key)?.orderedOutlookFavoritesIds;
    setFolderFavoritesDataCollectionInformation(
        key + ':orderedFavoriteIdList',
        JSON.stringify(orderedOutlookFavoritesIds)
    );
}

// Returns true if the user's mailbox has been created within the last hour
function isNewUser() {
    const createDate = new Date(getUserConfiguration()?.MailboxCreateDate ?? Date.now());
    return Date.now() - createDate.getTime() < HOUR_TIMESPAN_IN_MS;
}
