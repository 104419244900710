import { draftSenderPersonaPlaceholder } from 'owa-locstrings/lib/strings/draftsenderpersonaplaceholder.locstring.json';
import { flaggedFilter } from 'owa-locstrings/lib/strings/flaggedfilter.locstring.json';
import { formatRelativeDate, getRelativeDateMessage } from 'owa-observable-datetime';
import { getItemsOrConversationsSelectedText } from 'owa-mail-list-store';
import { pinnedGroupHeader } from 'owa-locstrings/lib/strings/pinnedgroupheader.locstring.json';
import { unreadFilter } from 'owa-locstrings/lib/strings/unreadfilter.locstring.json';
import loc, { format } from 'owa-localize';
import PropertyIcons from 'owa-mail-list-actions/lib/utils/conversationProperty/PropertyIcons';
import type { TableView } from 'owa-mail-list-store';
import {
    repliedLabel,
    mentionedLabel,
    forwardedLabel,
} from 'owa-locstrings/lib/strings/propertyiconlabels.locstring.json';
import {
    meetingTentative,
    meetingDeclined,
    meetingCanceled,
    meetingAccepted,
    meeting,
    meetingStartsAt,
    important,
    hasAttachments,
    conversationExpanded,
    conversationCollapsed,
    copilotHighPriority,
    copilotLowPriority,
} from '../components/MailListItemContainer.locstring.json';
import { getCopilotPrioritizeSettings } from 'owa-mail-copilot-prioritize-settings';
import type { MailboxInfo } from 'owa-client-types';

export default function getAriaLabelForMailListItemRow(
    tableView: TableView | undefined,
    tableViewId: string,
    shouldRowBeFocused: boolean,
    canRowBeExpanded: boolean,
    isRowExpanded: boolean,
    unreadCount: number,
    importance: string | undefined,
    hasAttachment: boolean,
    isFlagged: boolean,
    isPinned: boolean,
    subject: string | undefined,
    sendersOrRecipients: string | undefined,
    lastDeliveryTimestamp: string | undefined,
    thirdLineText: string,
    showDraftsIndicator: boolean,
    itemClassIcon: PropertyIcons | undefined,
    effectiveMentioned: boolean,
    returnTime: string | null,
    meetingStartTime: string | undefined,
    copilotInboxScore: number | undefined,
    mailboxInfo: MailboxInfo | undefined
): string {
    const labelArray = [];

    if (unreadCount > 0) {
        labelArray.push(loc(unreadFilter));
    }

    if (canRowBeExpanded) {
        if (isRowExpanded) {
            labelArray.push(loc(conversationExpanded));
        } else {
            labelArray.push(loc(conversationCollapsed));
        }
    }

    if (importance === 'High') {
        labelArray.push(loc(important));
    }

    if (hasAttachment) {
        labelArray.push(loc(hasAttachments));
    }

    if (isFlagged) {
        labelArray.push(loc(flaggedFilter));
    }

    if (isPinned) {
        labelArray.push(loc(pinnedGroupHeader));
    }

    if (itemClassIcon === PropertyIcons.MailListViewReplied) {
        labelArray.push(loc(repliedLabel));
    }

    if (itemClassIcon === PropertyIcons.MailListViewForwarded) {
        labelArray.push(loc(forwardedLabel));
    }

    if (effectiveMentioned) {
        labelArray.push(loc(mentionedLabel));
    }

    if (copilotInboxScore && copilotInboxScore > 0 && mailboxInfo) {
        const { prioritizationEnabled, applyLowPriorityLabel } =
            getCopilotPrioritizeSettings(mailboxInfo);

        if (prioritizationEnabled) {
            const isLowPriority = applyLowPriorityLabel && copilotInboxScore < 3;
            const isHighPriority = copilotInboxScore > 3;

            if (isLowPriority) {
                labelArray.push(loc(copilotLowPriority));
            } else if (isHighPriority) {
                labelArray.push(loc(copilotHighPriority));
            }
        }
    }

    // Add a label for item class icon (meetings)
    const itemClassIconAriaLabel = itemClassIcon && getItemClassIconAriaLabel(itemClassIcon);
    if (itemClassIconAriaLabel) {
        labelArray.push(itemClassIconAriaLabel);
    }

    // if this is a meeting add start time, if available
    if (meetingStartTime) {
        try {
            labelArray.push(format(loc(meetingStartsAt), getRelativeDateMessage(meetingStartTime)));
        } catch (_error) {
            // If there is an error, we don't want to crash the app.
            // We can safely ignore the error and not add the date to the label.
        }
    }

    labelArray.push(sendersOrRecipients);

    if (showDraftsIndicator) {
        labelArray.push(loc(draftSenderPersonaPlaceholder));
    }

    labelArray.push(subject);

    // to avoid confusion - if meeting start timestamp is available we shouldn't add last delivery timestamp
    if (!returnTime && !meetingStartTime && lastDeliveryTimestamp) {
        try {
            const formattedLastDeliveryTimestamp = formatRelativeDate(lastDeliveryTimestamp);
            labelArray.push(formattedLastDeliveryTimestamp);
        } catch (_error) {
            // If there is an error, we don't want to crash the app.
            // We can safely ignore the error and not add the date to the label.
        }
    }

    // Only push preview text if it is not null (null when preview text off setting is on)
    if (thirdLineText) {
        labelArray.push(thirdLineText);
    }

    if (shouldRowBeFocused && tableView?.selectedRowKeys.size !== 1) {
        labelArray.push(getItemsOrConversationsSelectedText(tableViewId));
    }

    return labelArray.join(' '); // convert into string
}

const getItemClassIconAriaLabel = (icon: PropertyIcons): string | null => {
    switch (icon) {
        case PropertyIcons.MailListViewAppointmentItem:
            return loc(meeting);
        case PropertyIcons.MailListViewAppointmentAccepted:
            return loc(meetingAccepted);
        case PropertyIcons.MailListViewAppointmentDeclined:
            return loc(meetingDeclined);
        case PropertyIcons.MailListViewAppointmentTentative:
            return loc(meetingTentative);
        case PropertyIcons.MailListViewAppointmentCanceled:
            return loc(meetingCanceled);
        default:
            return null;
    }
};
