import type { Resolvers } from 'owa-graph-schema';
import { lazyPersonaQueryWeb } from 'persona-query';
import { lazyPersonaPhotoByEmailQueryWeb } from 'persona-photo-by-email-query-web';
import { lazyPersonSourcesDeleteMutationWeb } from 'person-sources-delete-mutation';
import { lazyPersonSourcesUnlinkMutationWeb } from 'person-sources-unlink-mutation';
import { lazyPersonSourceUpdateMutationWeb } from 'person-source-update-mutation';

export const webResolvers: Resolvers = {
    /* ======================== */
    /* Resolvers for root types */
    /* ======================== */
    OneGQL_Persona: {
        __resolveType(_persona, _context, _info) {
            // @kamcheung - Hardcoded all Persona type to be Person for now
            return 'OneGQL_Person';
        },
    },

    /**
     * The root query type. All queries that fetch data start at the Query type.
     * Resolvers under Query should not have effects
     *
     * See https://graphql.org/learn/schema/#the-query-and-mutation-types
     */
    Query: {
        persona: lazyPersonaQueryWeb,
        personaPhotoByEmail: lazyPersonaPhotoByEmailQueryWeb,
    },

    /**
     * The root mutation type. All queries that alter data start at the Mutation type.
     * Mutations typically return the mutated data.
     *
     * See https://graphql.org/learn/schema/#the-query-and-mutation-types
     */
    Mutation: {
        // New composite person mutations here.
        deletePersonSources: lazyPersonSourcesDeleteMutationWeb,
        unlinkPersonSources: lazyPersonSourcesUnlinkMutationWeb,
        updatePersonSource: lazyPersonSourceUpdateMutationWeb,
    },

    /**
     * The root subscription type. Resolvers under subscriptions return an event stream
     * that the client responds to.
     *
     * For for definition and rationale, see https://graphql.org/blog/subscriptions-in-graphql-and-relay/#event-based-subscriptions
     * For resolver implementation, see https://www.apollographql.com/docs/apollo-server/data/subscriptions
     * For client consumption, see https://www.apollographql.com/docs/react/data/subscriptions
     */
    Subscription: {},
};
