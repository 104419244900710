import type {
    ComposeRibbonControlDefProps,
    ReadOnlyRibbonControlDefProps,
    RibbonControlDefinitionProps,
} from './RibbonControlDefinitionProps';
import { type MailRibbonControlId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import type { RibbonControlId, RibbonTabId } from 'owa-ribbon-ids';
import { getAccountScopeUserSettings, getUserConfiguration, isConsumer } from 'owa-session-store';
import {
    getMailMenuItemShouldHideForMailbox,
    getMailMenuItemShouldShow,
} from 'owa-mail-filterable-menu-behavior';

import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type { MailboxInfo } from 'owa-client-types';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import { addinsCapability } from 'owa-capabilities-definitions/lib/addinsCapability';
import { owaComputedFn } from 'owa-computed-fn';
import { getCmdIdFromRibbonId } from 'owa-mail-ribbon-store/lib/util/mailRibbonIdToCmdIdMap';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { getRibbonMailboxInfo } from './getRibbonMailboxInfo';
import { hasEnterpriseAccount } from 'owa-mail-ribbon-store/lib/util/hasEnterpriseAccount';
import { isBrowserChrome, isBrowserEDGECHROMIUM, isBrowserFirefox } from 'owa-user-agent';
import { isCapabilityEnabled } from 'owa-capabilities';
import { isCopilotFeatureEnabled, isComposeCopilotEnabled } from 'owa-copilot-settings-utils';
import isEncryptionEnabled from 'owa-encryption-common/lib/utils/isEncryptionEnabled';
import isEncryptionTemplateAvailable from 'owa-mail-protection/lib/utils/isEncryptionTemplateAvailable';
import isFeatureEnabled from 'owa-feature-flags/lib/utils/isFeatureEnabled';
import { LoopType, isFluidEnabledForSource } from 'owa-fluid-validations';
import isGroupComposeViewState from 'owa-mail-compose-store/lib/utils/isGroupComposeViewState';
import { isMOS3AppServiceAvailable } from 'owa-m365-acquisitions/lib/utils/isMOS3AppServiceAvailable';
import { isMessageExtensionsFlyoutEnabled } from 'owa-m365-acquisitions/lib/utils/isMessageExtensionsFlyoutEnabled';
import { isNativeReportingEnabled } from 'owa-reporting';
import isPublicFolderComposeViewState from 'owa-mail-compose-store/lib/utils/isPublicFolderComposeViewState';
import { learningToolsCapability } from 'owa-capabilities-definitions/lib/learningToolsCapability';
import { populateAddinsCapability } from 'owa-capabilities-definitions/lib/populateAddinsCapability';
import { shouldHideRibbonControlBasedOnHostApp } from './hostAppConfig';
import { isTextPenSupported } from 'owa-text-pen-utils/lib/utils/isPenSupported';
import getSelectedTableView from 'owa-mail-list-store/lib/utils/getSelectedTableView';
import isConversationView from 'owa-mail-list-store/lib/utils/isConversationView';
import { getItem } from './getItem';
import { isItemInFolder } from './isItemInFolder';
import { getComposeViewState } from 'owa-mail-reading-pane-store-conversation/lib/utils/getComposeViewState';
import isMessageRecallEnabled from 'owa-cloud-message-recall/lib/utils/isMessageRecallEnabled';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';
import isSharedMailboxExplicitLogon from 'owa-mail-store/lib/utils/isSharedMailboxExplicitLogon';
import { isSpamAddinInstalled } from 'owa-addins-store/lib/utils/isSpamAddinInstalled';
import isFileActivation from 'owa-mail-file-support/lib/utils/isFileActivation';
import getTabById from 'owa-tab-store/lib/utils/getTabById';
import { TabType } from 'owa-tab-store';
import { isTeamsChatEnabled } from 'owa-teams-integration/lib/utils/isTeamsChatEnabled';
import { areMessageExtensionsEnabledOnReadRibbon } from 'owa-feature-flags/lib/utils/areMessageExtensionsEnabledOnReadRibbon';
import { isLocalDataBackedMailboxInfo } from 'owa-account-source-list/lib/utils/isLocalDataBackedAccount';

const sentItemsFolderId = 'sentitems';

/**
 * This function decides whether a control should show or not.
 * For any logic on show/hiding controls, please put them here.
 *
 * @controlId the current control being tested to see whether it should show
 * @props Optional parameter, used for certain buttons to test whether they should show.
 *        Note: Props is only undefined in Customizer cases. Certain buttons use this logic truth
 *        when determining whether they should show.
 * @isCustomizer set this to true if we are in customizer view
 */
export const shouldShowRibbonControl = owaComputedFn(function shouldShowRibbonControl(
    tabId: RibbonTabId,
    controlId: RibbonControlId,
    props: RibbonControlDefinitionProps,
    isCustomizer: boolean
): boolean {
    const mailboxInfo = getRibbonMailboxInfo(props);
    if (
        getMailMenuItemShouldHideForMailbox(
            getCmdIdFromRibbonId(controlId, mailboxInfo),
            mailboxInfo
        )
    ) {
        return false;
    }

    if (shouldHideRibbonControlBasedOnHostApp(controlId)) {
        return false;
    }

    const composeProps = props as ComposeRibbonControlDefProps;

    const isHTML: boolean = composeProps.composeViewState?.bodyType === 'HTML';

    const useContentModelEditor: boolean = !!composeProps.composeViewState?.useContentModelEditor;

    const isFileProjection: boolean = isFileActivationProjection(props);

    switch (tabId) {
        case 1:
            switch (controlId) {
                case 508:
                    return !isFeatureEnabled('grp-inboxExp-ribbon');

                case 663:
                    return isFeatureEnabled('grp-inboxExp-ribbon') && hasEnterpriseAccount();

                case 529:
                    return shouldShowGetAddins(getIsConsumer(mailboxInfo), 1, mailboxInfo);
                case 7022:
                    return (
                        shouldShowGetM365Apps(mailboxInfo) &&
                        !shouldShowGetAddins(getIsConsumer(mailboxInfo), 1, mailboxInfo)
                    );
                case 7016:
                    return shouldShowGetReadMessageExtensions(mailboxInfo);

                case 657:
                    // Hide the native reporting button if the user has the spam report addin
                    return (
                        !hasSpamReportAddin(mailboxInfo) && isNativeReportingEnabled(mailboxInfo)
                    );

                // Only show these controls if the user has at least one enterprise account
                case 556:
                case 557:
                case 555:
                case 651:
                    return hasEnterpriseAccount();

                case 553:
                    return hasEnterpriseAccount();

                case 698:
                case 699:
                case 700:
                case 696:
                    return (
                        hasEnterpriseAccount() &&
                        !!isCopilotFeatureEnabled('ReplyByMeeting', mailboxInfo)
                    );

                case 678:
                    return !hasEnterpriseAccount();

                // MenuItemType Ribbon_Restore controls whether the restore buttons should be show in the ribbon
                // there are Restore and RestoreAll types as well, which are used to determine if the buttons are enabled
                case 683:
                    return getMailMenuItemShouldShow(81, mailboxInfo);
                case 684:
                    return getMailMenuItemShouldShow(81, mailboxInfo);
                case 558:
                    return !(
                        isFeatureEnabled('mon-ribbon-customization-multiAccount') &&
                        mailboxInfo.type === 'GroupMailbox'
                    );
                case 686:
                    // Show recall message button in Home Tab when the feature is enabled and:
                    // 1. List View is not in Conversation Mode
                    // 2. List View is in Conversation mode and Reading Pane Conversation is disabled
                    return (
                        (!isConversationView(getSelectedTableView()) ||
                            shouldShowUnstackedReadingPane()) &&
                        getMailMenuItemShouldShow(83, mailboxInfo)
                    );
                case 697:
                    return (
                        (!isConversationView(getSelectedTableView()) ||
                            shouldShowUnstackedReadingPane()) &&
                        getMailMenuItemShouldShow(86, mailboxInfo)
                    );

                case 702:
                case 703:
                    return (
                        isFeatureEnabled('mon-teams-ChatAroundEmail') &&
                        isTeamsChatEnabled(mailboxInfo)
                    );

                case 709:
                    return shouldShowShareToTeamsChat(mailboxInfo);
                case 712:
                    return (
                        (!isConversationView(getSelectedTableView()) ||
                            shouldShowUnstackedReadingPane()) &&
                        getMailMenuItemShouldShow(92, mailboxInfo)
                    );

                default:
                    return true;
            }

        case 5:
            switch (controlId) {
                case 620:
                    return shouldShowAttachFile(props, isCustomizer);
                case 529:
                    return shouldShowGetAddins(getIsConsumer(mailboxInfo), 5, mailboxInfo);
                case 7016:
                    return shouldShowGetMessageExtensions(mailboxInfo);
                case 7021:
                    return shouldShowPolls(mailboxInfo);
                case 7000:
                    return showShouldLoopComponent(mailboxInfo);
                case 7011:
                    return shouldShowFindTimeControl(getIsConsumer(mailboxInfo));
                case 7026:
                    return (
                        isFeatureEnabled('cmp-fluid-videoLoopNpmCodeLoader') &&
                        isFluidEnabledForSource('MailCompose', mailboxInfo, LoopType.VideoRecord)
                    );

                default:
                    return true;
            }

        case 4:
            switch (controlId) {
                case 6011:
                case 6012:
                    return !isBrowserFirefox();
                case 6075:
                    return useContentModelEditor;
                case 6110:
                    return useContentModelEditor;
                default:
                    return true;
            }

        case 3:
            switch (controlId) {
                case 532:
                    return isCapabilityEnabled(learningToolsCapability, mailboxInfo);
                case 558:
                    return !(
                        isFeatureEnabled('mon-ribbon-customization-multiAccount') &&
                        mailboxInfo.type === 'GroupMailbox'
                    );
                case 687:
                    const composeViewState = getComposeViewState();
                    return !composeViewState && isConversationView(getSelectedTableView());
                case 701:
                    return !isLocalDataBackedMailboxInfo(mailboxInfo);
                default:
                    return true;
            }

        case 6:
            switch (controlId) {
                default:
                    return true;
            }

        case 7:
            switch (controlId) {
                case 6097:
                case 6098:
                case 6100:
                    return useContentModelEditor;
                default:
                    return true;
            }

        case 8:
            switch (controlId) {
                case 519:
                case 505:
                case 527:
                case 509:
                case 548:
                case 535:
                case 547:
                case 658:
                case 559:
                case 616:
                case 552:
                    return !isFileProjection;
                case 657:
                    // Hide the native reporting button if the user has the spam report addin
                    return (
                        !hasSpamReportAddin(mailboxInfo) &&
                        isNativeReportingEnabled(mailboxInfo) &&
                        !isFileProjection
                    );

                case 678:
                    return !hasEnterpriseAccount();

                case 651:
                case 556:
                case 557:
                case 555:
                    return hasEnterpriseAccount();

                case 553:
                    return (
                        hasEnterpriseAccount() &&
                        !isCopilotFeatureEnabled('ReplyByMeeting', mailboxInfo)
                    );
                case 696:
                case 699:
                case 700:
                    return (
                        hasEnterpriseAccount() &&
                        !!isCopilotFeatureEnabled('ReplyByMeeting', mailboxInfo)
                    );

                case 686:
                    return (
                        isMessageRecallEnabled(mailboxInfo) &&
                        shouldShowTrackingControlsOnReadTab(props) &&
                        !isFileProjection
                    );

                case 697:
                    return shouldShowTrackingControlsOnReadTab(props) && !isFileProjection;

                case 702:
                case 703:
                    return (
                        isFeatureEnabled('mon-teams-ChatAroundEmail') &&
                        isTeamsChatEnabled(mailboxInfo)
                    );

                case 709:
                    return shouldShowShareToTeamsChat(mailboxInfo);

                case 705:
                    return isFileProjection;

                case 712:
                    return (
                        shouldShowTrackingControlsOnReadTab(props) &&
                        isFeatureEnabled('cmp-message-resend', mailboxInfo)
                    );

                case 7016:
                    return shouldShowGetReadMessageExtensions(mailboxInfo);

                default:
                    return true;
            }

        case 10:
            switch (controlId) {
                case 529:
                    return shouldShowGetAddins(getIsConsumer(mailboxInfo), 10, mailboxInfo);
                case 7016:
                    return shouldShowGetMessageExtensions(mailboxInfo);
                case 7021:
                    return shouldShowPolls(mailboxInfo);
                case 7023:
                    return !!isComposeCopilotEnabled(mailboxInfo, 'Any');
                case 7000:
                    return showShouldLoopComponent(mailboxInfo);
                case 7011:
                    return shouldShowFindTimeControl(getIsConsumer(mailboxInfo));
                case 564:
                    return shouldShowSensitivity(props);
                case 6012:
                    return !isBrowserFirefox();
                case 7010:
                    return !getIsConsumer(mailboxInfo);
                case 7003:
                    return getIsDarkTheme();
                case 6075:
                    return useContentModelEditor;
                case 7026:
                    return (
                        isFeatureEnabled('cmp-fluid-videoLoopNpmCodeLoader') &&
                        isFluidEnabledForSource('MailCompose', mailboxInfo, LoopType.VideoRecord)
                    );
                case 7029:
                    return (
                        isFeatureEnabled('cmp-dictation') &&
                        (isBrowserChrome() || isBrowserEDGECHROMIUM() || isBrowserFirefox())
                    );
                default:
                    return true;
            }

        case 11:
            switch (controlId) {
                case 7012:
                    return shouldShowEncrypt(props);
                case 564:
                    return shouldShowSensitivity(props);
                case 7010:
                    return !getIsConsumer(mailboxInfo);
                case 7013:
                    return shouldShowSendLater(props);
                case 7015:
                    return sholdShowDeliveryReceipt(props);
                case 7034:
                    return isFeatureEnabled('cmp-mail-merge', mailboxInfo);
                default:
                    return true;
            }

        case 12:
            switch (controlId) {
                case 12012:
                    return isTextPenSupported() && isHTML;
                default:
                    return true;
            }

        case 13:
            switch (controlId) {
                case 6079:
                case 6080:
                case 6084:
                    return useContentModelEditor;
                default:
                    return true;
            }

        default:
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            throw new Error('getShouldShowControlsRules.ts: Unknown tab id: ' + tabId);
    }
});

function hasSpamReportAddin(mailboxInfo: MailboxInfo): boolean {
    // Check if the spam addin is installed from extensibility state
    return isFeatureEnabled('addin-enableIntegratedSpam') && isSpamAddinInstalled(mailboxInfo);
}

function shouldShowFindTimeControl(isConsumerAcct: boolean): boolean {
    return isFeatureEnabled('cal-native-findtime') && !isConsumerAcct;
}

function shouldShowGetAddins(
    isConsumerAcct: boolean,
    ribbonTab: MailRibbonTabId,
    mailboxInfo: MailboxInfo
): boolean {
    const shouldShowAddInButton =
        !isConsumerAcct &&
        isCapabilityEnabled(populateAddinsCapability, mailboxInfo) &&
        isCapabilityEnabled(addinsCapability, mailboxInfo) &&
        !(isMOS3AppServiceAvailable(mailboxInfo) || isSharedMailboxExplicitLogon());

    if (ribbonTab === 1 || ribbonTab === 10 || ribbonTab === 5) {
        return shouldShowAddInButton && !shouldShowGetM365Apps(mailboxInfo);
    }
    return shouldShowAddInButton;
}

function shouldShowGetM365Apps(mailboxInfo?: MailboxInfo) {
    return (
        isMOS3AppServiceAvailable(mailboxInfo) &&
        isFeatureEnabled('mos-inContextStore', mailboxInfo)
    );
}

function shouldShowGetMessageExtensions(mailboxInfo?: MailboxInfo): boolean {
    return isMessageExtensionsFlyoutEnabled(mailboxInfo);
}

function shouldShowGetReadMessageExtensions(mailboxInfo?: MailboxInfo): boolean {
    return (
        areMessageExtensionsEnabledOnReadRibbon() && isMessageExtensionsFlyoutEnabled(mailboxInfo)
    );
}

function shouldShowPolls(mailboxInfo: MailboxInfo): boolean {
    return (
        isFeatureEnabled('cmp-fluid-chapterThree') &&
        isFluidEnabledForSource('MailCompose', mailboxInfo, LoopType.Poll)
    );
}

function showShouldLoopComponent(mailboxInfo: MailboxInfo): boolean {
    return isFluidEnabledForSource('MailCompose', mailboxInfo, LoopType.Fluid);
}

const shouldShowEncrypt = owaComputedFn(function shouldShowEncrypt(
    props: RibbonControlDefinitionProps
): boolean {
    const mailboxInfo = getRibbonMailboxInfo(props);
    const { RmsV2UIEnabled } = getUserConfiguration(); // indicates whether SimplifiedClientAccessEnabled is true
    return !!(
        RmsV2UIEnabled &&
        isEncryptionEnabled(mailboxInfo) &&
        isEncryptionTemplateAvailable(mailboxInfo)
    );
});

/**
 * Display sensitivity label dropdown only if user is not a consumer. In case of consumer, we hide the sensitivity label button.
 * Future TO-DO: Add license check for mail workload
 *                  and modify return statement to display dropdown list on the basis of license check as well.
 */
const shouldShowSensitivity = owaComputedFn(function shouldShowSensitivity(
    props: RibbonControlDefinitionProps
): boolean {
    return !isConsumer(undefined /* smtpAddress */, getRibbonMailboxInfo(props));
});

function shouldShowAttachFile(props: RibbonControlDefinitionProps, isCustomizer: boolean) {
    props = props as ComposeRibbonControlDefProps;
    return (
        isCustomizer ||
        (props.attachmentPickers != undefined && props.composeViewState != undefined)
    );
}

function shouldShowSendLater(props: RibbonControlDefinitionProps): boolean {
    props = props as ComposeRibbonControlDefProps;
    const viewState = props.composeViewState;
    if (viewState) {
        return (
            !!getUserConfiguration().AsyncSendEnabled &&
            !isGroupComposeViewState(viewState) &&
            !isPublicFolderComposeViewState(viewState) &&
            /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
             * Adding IsShadowMailbox to restricted properties/methods.
             *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
            !getAccountScopeUserSettings(viewState.mailboxInfo).SessionSettings?.IsShadowMailbox
        );
    }
    return false;
}

function sholdShowDeliveryReceipt(props: RibbonControlDefinitionProps): boolean {
    props = props as ComposeRibbonControlDefProps;
    const viewState = props.composeViewState;
    if (viewState) {
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Adding IsShadowMailbox to restricted properties/methods.
         *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
        return !getAccountScopeUserSettings(viewState.mailboxInfo).SessionSettings?.IsShadowMailbox;
    }
    return false;
}

function getIsConsumer(mailboxInfo: MailboxInfo): boolean {
    return isConsumer(undefined /* smtpAddress */, mailboxInfo);
}

function shouldShowTrackingControlsOnReadTab(props: RibbonControlDefinitionProps) {
    const clientItemId = (props as ReadOnlyRibbonControlDefProps).itemId;
    const item = getItem(clientItemId);
    if (item) {
        // Do not show if the item in not in sent items folder or if the item class is a Meeting
        return (
            isItemInFolder(item, [sentItemsFolderId]) &&
            item.ItemClass?.indexOf('IPM.Schedule.Meeting') == -1
        );
    }
    return false;
}

export function shouldShowShareToTeamsChat(mailboxInfo: MailboxInfo) {
    return (
        isFeatureEnabled('mon-teams-ChatAroundEmail') &&
        isFeatureEnabled('mon-teams-ShareToTeamsChat') &&
        isTeamsChatEnabled(mailboxInfo)
    );
}

function isFileActivationProjection(props: RibbonControlDefinitionProps): boolean {
    if (!isFeatureEnabled('mon-file-eml-msg')) {
        return false;
    }

    const ribbonProps = props as ReadOnlyRibbonControlDefProps;
    const tab = getTabById(ribbonProps.projectionTabId);

    if (tab?.type === TabType.SecondaryReadingPane) {
        // check if file projection
        return !!(isFeatureEnabled('mon-file-projection') && tab.data?.isFileActivation);
    } else {
        // check if file deeplink
        return isFileActivation();
    }
}
