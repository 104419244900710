import { type Capabilities } from 'owa-graph-schema';

// Keep in sync with server-outlook-gateway/src/Microsoft.OutlookService.Copilot/Models/Api/Settings/CopilotLicense.cs
export enum LicenseType {
    M365Copilot = 0,
    SalesCopilot = 1,
    TeamsCopilot = 2,
    BizChat = 3,
    M365ConsumerCredits = 4,
}

export type AddinCapabilityNames = 'emailSummary' | 'emailDraft' | 'emailReplySuggestions';

export interface ExtensibilitySettingsResponse {
    metaOSApps: MetaOSApps;
    supportedScenarios: Scenario[];
}

interface MetaOSApps {
    tenantDefault?: string;
}

// Keep in sync with server-outlook-gateway/src/Microsoft.OutlookService.Copilot/Models/Api/Settings/Scenario.cs
export enum Scenario {
    SuggestedDrafts = 0,
    Elaborate = 1,
    Coach = 2,
    ThreadSummarization = 3,
    InboxCatchup = 4,
    Chat = 5,
    ThemeVisualization = 6,
    ReadingVisualization = 7,
    MeetingPrepSummary = 8,
    FindMeetingTimesWithPeopleSearch = 9,
    PlaceSuggestions = 10,
    ReplyByMeeting = 11,
    ExplainPlacesReport = 12,
    Rewrite = 13,
    CoachApply = 16,
    TimeCopilotChat = 18,
}

// Keep in sync with server-outlook-gateway/src/Microsoft.OutlookService.Copilot/Models/Api/Settings/UserType.cs
export enum UserType {
    Beta = 1,
    AIX00 = 2,
}

export interface AddinCopilotSettings {
    displayName?: string;
    capabilities?: Capabilities;
}

export const ELABORATE_FILTER_SCENARIOS = ['New', 'Reply'] as const;
export type ElaborateFilterScenarios = typeof ELABORATE_FILTER_SCENARIOS[number];

export type ElaborateFilterSettings = {
    [key in ElaborateFilterTypes]: string;
};

export const ELABORATE_FILTER_TYPES = ['tone', 'verbosity'] as const;
export type ElaborateFilterTypes = typeof ELABORATE_FILTER_TYPES[number];

export type ElaborateSettingsServiceBlobFilters = {
    [key in ElaborateFilterScenarios]: ElaborateFilterSettings;
};

export interface ElaborateSettingsServiceBlob extends ElaborateSettingsServiceBlobFilters {
    TonePersonalization: 'seen' | 'unseen';
}
