import { type CopilotThemeOptions } from 'owa-copilot-themes-types';
import { ThemeConstants } from 'owa-theme-shared';
import getDefaultPrompt from './getDefaultPrompt';

export default function getDefaultThemeOptions(): CopilotThemeOptions {
    return {
        themeId: ThemeConstants.COPILOT_CUSTOM_THEME_ID,
        isSelected: false,
        ...getDefaultPrompt(),
        lastRefreshDate: '',
        lightThemeResources: {} as any,
        darkThemeResources: {} as any,
    };
}
